import React from 'react';
import Nav from '../../component/Navbar/nav';
// import Navbar from '../../component/Navbar/navbar';

import config from '../../data/config';
import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import updates from '../../data/updates';
import toast from 'react-hot-toast';


import hero from '../../assets/images/torogram/hero.png';
import robot from '../../assets/images/torogram/robot.png';
import robot2 from '../../assets/images/torogram/robot2.png';
import robot3 from '../../assets/images/torogram/robot3.png';
import robot4 from '../../assets/images/torogram/robot4.png';
import jordy from '../../assets/images/torogram/jordy_transparent.png';
import build from '../../assets/images/torogram/build.png';
import pano from '../../assets/images/torogram/pano.png';
import astrox from '../../assets/images/torogram/astrox.png';
import fbg from '../../assets/images/torogram/fbg.png';
import azee from '../../assets/images/torogram/azee.jpg';
import snt from '../../assets/images/torogram/snt.png';
import gfx1 from '../../assets/images/torogram/gfx_1.png';
import Loader from '../../component/Loader/loader';
import img_wallpaper from '../../assets/web3scape/img_wallpaper.jpg';
import ModalContent_CreatePost from '../../component/Modal/ModalContent_CreatePost';

import NavLanding from '../../component/Navbar/navLanding';

import logo from '../../assets/web3scape/web3scape_x1000.png';
import logo_s from '../../assets/web3scape/web3scape_icon.png';
import playnow from '../../assets/web3scape/playnow.png';
import about from '../../assets/web3scape/about.png';
import stones from '../../assets/web3scape/stones.png';
import { Link } from 'react-router-dom';
import Footer from '../../component/Footer/footer';
import ConnectWallet from '../../component/Decentralized/ConnectWallet';
import APIPostNewWallet from '../../api/XHunt/APIPostNewWallet';
import { ethers } from 'ethers';
import VerifyAndSaveWallet from '../../component/Decentralized/VerifyAndSaveWallet';

class Landing extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        // this.lastLoaded = 0;

        this.lastScroll = 0;

        this.assetsToLoad = 0;

        const urlParams = new URLSearchParams(window.location.search);

        this.jwt = urlParams.get('jwt');
        this.userData = constants.parseJwt(this.jwt);

        console.log(this.userData)

        this.state = {

            loading: true,

            accountData: config.accountData,

            home_tab: constants.isEmpty(localStorage.getItem('home_tab')) || isNaN(parseInt(localStorage.getItem('home_tab'))) ? config.home_tab_global : parseInt(localStorage.getItem('home_tab')),
            home_sort: constants.isEmpty(localStorage.getItem('home_sort')) || isNaN(parseInt(localStorage.getItem('home_sort'))) ? config.home_sort_recent : parseInt(localStorage.getItem('home_sort')),

            postText: '',

            // postPage: 0,

            wallet: null,

        };

        this.handleScroll_function = this.handleScroll.bind(this);

        // this.handleScroll_function = this.handleScroll.bind(this);

    }

    async componentDidMount() {

        window.addEventListener('scroll', this.handleScroll_function);

        this.setState({ loading: false });

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll_function);
    }

    loadedAsset() {
        this.assetsToLoad--;
        if (this.assetsToLoad <= 0) {
            this.setState({ loading: false }, () => {

                window.scrollTo({ top: 0, behavior: "instant" });

                this.handleScroll(null);

                const animatedDivsAlways = document.getElementsByClassName('w3s_always');

                for (const d of animatedDivsAlways) {

                    if (!d || d.classList.contains('show')) {
                        continue;
                    }
                    d.classList.add('show');

                }

            });
        }
    }

    changeTab(tab) {

        if (this.state.home_tab === tab) {
            return;
        }

        if (tab !== config.home_tab_updates) {
            localStorage.setItem('home_tab', tab);
        }

        this.setState({ home_tab: tab });

    }

    changeSortTab(tab) {

        if (this.state.home_sort === tab) {
            return;
        }

        localStorage.setItem('home_sort', tab);

        this.setState({ home_sort: tab });

    }

    setWallet(wallet) {
        this.setState({ wallet: wallet });
    }

    async saveWallet() {

        if (constants.isEmpty(this.state.wallet)) {
            console.log('hi')
            return;
        }

        this.setState({ loading: true });

        await APIPostNewWallet.Request(this.jwt, this.state.wallet);

        console.log('hi2')

        toast.success(`Successfully added wallet. Please return to the app.`, {
            position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
            duration: 10000,
        });

        this.setState({ loading: false });

    }
 
    handleScroll(e) {

        const currentTime = new Date().getTime();

        if (currentTime - this.lastScroll < 10) {
            return;
        }

        this.lastScroll = currentTime;

        const offset = 200;

        const animatedDivs1 = document.getElementsByClassName('animateLeft');
        const animatedDivs2 = document.getElementsByClassName('animateRight');
        const animatedDivs3 = document.getElementsByClassName('animateIn');

        for (const d of animatedDivs1) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

        for (const d of animatedDivs2) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

        for (const d of animatedDivs3) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

    }

    render() {

        return (
            <div className='bg-gray-50 dark:bg-slate-950' onMouseDown={(e) => constants.closeAllDropdowns(e)}>

                {/* LOADER */}
                {this.state.loading ?
                    <Loader loading={this.state.loading} />
                : null}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
                    
                    <div className='dapp_box'>
                        <div className='text-xl font-semibold'>X-Hunt wallet connect</div>
                        <div>Welcome {this.userData.username}</div>
                        <ConnectWallet setWallet={this.setWallet.bind(this)} wallet={this.state.wallet} />

                        {constants.isEmpty(this.state.wallet) === false ?
                            <>
                                <div className='w3s_break_word mt-5'>Connected wallet: {this.state.wallet}</div>
                                <VerifyAndSaveWallet userData={this.userData} />
                            </>
                        : null}
                    </div>

                </div>

            </div>
        );
    }
  
}

export default withRouter(Landing);
import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';

import config from '../../data/config';

class ImageViewer extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.parent = this.props.parent;
        this.content = this.props.content;
        this.nft = this.props.nft;

        this.state = {

            loading: true,

        };

    }

    async componentDidMount() {

        this.setState({ loading: false });

    }

    shouldComponentUpdate(nextProps, nextState) {

        if (constants.isEmpty(this.content) && constants.isEmpty(nextProps.content) === false) {
            this.content = nextProps.content;
            this.forceUpdate();
            return true;
        } else if (constants.isEmpty(this.content) === false && constants.isEmpty(nextProps.content) === false && this.content !== nextProps.content) {
            this.content = nextProps.content;
            this.forceUpdate();
            return true;
        }

        if (constants.isEmpty(this.nft) && constants.isEmpty(nextProps.nft) === false) {
            this.nft = nextProps.nft;
            this.forceUpdate();
            return true;
        } else if (constants.isEmpty(this.nft) === false && constants.isEmpty(nextProps.nft) === false && (this.nft.address !== nextProps.nft.address || this.nft.id !== nextProps.nft.id)) {
            this.nft = nextProps.nft;
            this.forceUpdate();
            return true;
        }

        if (constants.isEmpty(nextProps.data?.post_id) === false) {
            this.post = nextProps.data;
            this.reply = null;
            this.account = null;
        } else if (constants.isEmpty(nextProps.data?.reply_id) === false) {
            this.reply = nextProps.data;
            this.post = null;
            this.account = null;
        } else if (constants.isEmpty(nextProps.data?.info_id) === false) {        
            this.account = nextProps.data;
            this.reply = null;
            this.post = null;
        }

        if (constants.isEmpty(this.post) === false && constants.isEmpty(nextProps.data) === false && this.post.post_id !== nextProps.data.post_id) {
            this.post = nextProps.data;
            this.forceUpdate();
            return true;
        }

        if (constants.isEmpty(this.reply) === false && constants.isEmpty(nextProps.data) === false && this.reply.reply_id !== nextProps.data.reply_id) {
            this.reply = nextProps.data;
            this.forceUpdate();
            return true;
        }

        if (constants.isEmpty(this.account) === false && constants.isEmpty(nextProps.data) === false && this.account.account_id !== nextProps.data.account_id) {
            this.account = nextProps.data;
            this.forceUpdate();
            return true;
        }

        return false;

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.content) && constants.isEmpty(this.props.content) === false) {
            this.content = this.props.content;
            this.forceUpdate();
        } else if (constants.isEmpty(this.content) === false && constants.isEmpty(this.props.content) === false && this.content !== this.props.content) {
            this.content = this.props.content;
            this.forceUpdate();
        }

        if (constants.isEmpty(this.nft) && constants.isEmpty(this.props.nft) === false) {
            this.nft = this.props.nft;
            this.forceUpdate();
        } else if (constants.isEmpty(this.nft) === false && constants.isEmpty(this.props.nft) === false && (this.nft.address !== this.props.nft.address || this.nft.id !== this.props.nft.id)) {
            this.nft = this.props.nft;
            this.forceUpdate();
        }

        if (constants.isEmpty(this.props.data?.post_id) === false) {
            this.post = this.props.data;
            this.reply = null;
            this.account = null;
        } else if (constants.isEmpty(this.props.data?.reply_id) === false) {
            this.reply = this.props.data;
            this.post = null;
            this.account = null;
        } else if (constants.isEmpty(this.props.data?.info_id) === false) {        
            this.account = this.props.data;
            this.reply = null;
            this.post = null;
        }

        if (constants.isEmpty(this.post) === false && constants.isEmpty(this.props.data) === false && this.post.post_id !== this.props.data.post_id) {
            this.post = this.props.data;
            this.forceUpdate();
        }

        if (constants.isEmpty(this.reply) === false && constants.isEmpty(this.props.data) === false && this.reply.reply_id !== this.props.data.reply_id) {
            this.reply = this.props.data;
            this.forceUpdate();
        }

        if (constants.isEmpty(this.account) === false && constants.isEmpty(this.props.data) === false && this.account.account_id !== this.props.data.account_id) {
            this.account = this.props.data;
            this.forceUpdate();
        }

    }

    close() {

        this.content = null;
        this.nft = null;
        this.post = null;
        this.reply = null;
        this.account = null;
        config.contentToShow = null;
        config.contentToShow_data = null;
        config.contentToShow_nft = null;

        const imageViewerContainer = document.getElementById('imageViewerContainer');
        imageViewerContainer.classList.remove('show');
        document.body.classList.remove('modal-open');

        this.forceUpdate();
        this.parent.forceUpdate();

    }

    render() {

        return (
            <div id='imageViewerContainer' className='imageViewer_container'>

                {/* LOADER */}
                {this.state.loading === true ?
                    <div className='w3s_loader_full bg-transparent'>
                        <div className="w3s_spinner">
                            <div className="w3s_double-bounce1"></div>
                            <div className="w3s_double-bounce2"></div>
                        </div>
                    </div>
                : null}

                <div className='imageViewer_close' onClick={() => this.close()}>
                    <i className='fas fa-xmark' />
                </div>

                <div className='imageViewer_content'>
                    <img src={this.props.content} alt="" />

                    {this.props.content && this.props.content.includes('i.giphy.com') ?
                        <div className='mt-5' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                            {/* <a href={this.props.content.replace('https://i.giphy.com', 'https://giphy.com')} target='_blank'><img src={giphy_light} className='giphy_attr' /></a> */}
                        </div>
                    : null}
                </div>

                {/* VIEWING PROFILE PIC */}
                {constants.isEmpty(this.account) === false && constants.isEmpty(this.nft) === false ?
                
                    <div className='imageViewer_info_content'>
                        
                        <div className='post_content_header'>

                            <div className='post_content_header_1'>
                                {(constants.isEmpty(this.nft) === false && constants.isTrue(this.nft.verified)) || (constants.isTrue(this.props.nftVerified)) ?
                                    <div className={`preventClickPost relative`} style={{  }}>
                                        <div className="preventClickPost animated-border-box-glow2"></div>
                                        <div className="preventClickPost animated-border-box2">
                                            <img className='preventClickPost' src={constants.getFileLoc(this.account.info_id, this.account.info_profilepicture)} alt="" />
                                        </div>
                                    </div>
                                :
                                    <div className={`preventClickPost nav_profile_button_profile post_profile`} style={{  }}>
                                        {constants.isEmpty(this.account.info_profilepicture) === false ?
                                            <img className='preventClickPost' src={constants.getFileLoc(this.account.info_id, this.account.info_profilepicture)} alt="" />
                                        :
                                            <i className='preventClickPost far fa-user' />
                                        }
                                    </div>
                                }
                            </div>

                            <div className='post_content_header_2'>
                                <div className='preventClickPost flex items-center'>
                                    <div className='preventClickPost font-medium'>{constants.getUserFullName(this.account, true)}</div>
                                    {/* {constants.hasRank(this.account, config.rank_mod) ?
                                        <img src={icon_v_gold} className='ms-1 rank_badge_post' />
                                    : null} */}
                                </div>
                            </div>

                        </div>

                        <div className='font-semibold text-sm mt-4'>NFT address</div>
                        <div className='w3s_label_gray w3s_break_word' onClick={() => constants.copyText(this.nft.address)}>{this.nft.address.substring(0,7)}...{this.nft.address.substring(this.nft.address.length - 5, this.nft.address.length)} <i className='far fa-copy ms-2' /></div>

                        <div className='font-semibold text-sm mt-4'>ID</div>
                        <div className='w3s_label_gray w3s_break_word' onClick={() => constants.copyText(this.nft.id)}>#{this.nft.id} <i className='far fa-copy ms-2' /></div>
                        <div className='font-semibold text-sm mt-2'>Name</div>
                        <div className='w3s_label_gray w3s_break_word' onClick={() => constants.copyText(this.nft.name)}>{this.nft.name} <i className='far fa-copy ms-2' /></div>
                        <div className='font-semibold text-sm mt-2'>Collection</div>
                        <div className='flex items-center'>
                            <div className='w3s_label_gray w3s_break_word' onClick={() => constants.copyText(this.nft.collection)}>{this.nft.collection} <i className='far fa-copy ms-2' /></div>
                            {/* {constants.isTrue(this.nft.verified) ?
                                <img src={icon_v_blue} className='ms-1 rank_badge_post' />
                            : null} */}
                        </div>


                    </div>

                // :
                // // VIEW POST PIC
                // constants.isEmpty(this.post) === false ?

                //     <div className='imageViewer_info_content'>
                            
                      

                //     </div>
                
                : null}

            </div>
        );
    }
  
}

export default withRouter(ImageViewer);



import CryptoJS from 'crypto-js';
import config from '../data/config';

class APILOGIN {

    async Request(username, password) {
                
        const hash = CryptoJS.MD5(username + config.secretKey).toString();
        
        let response = null;

        try {
            response = await fetch(`https://server-t01.torogram.com/php/LOGIN.php/`, {
                method: 'post',
                body: new URLSearchParams(`user=${username}&password=${password}&hash=${hash}`)
            });
        } catch(err) {
            return [];
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
            let response2 = null;
            try {
                response2 = await fetch(`https://server-t01.torogram.com/php/LOGIN.php/`, {
                    method: 'post',
                    body: new URLSearchParams(`user=${username}&password=${password}&hash=${hash}`)
                });
            } catch(err) {
                return [];
            }
            const error = await response2.text();
            console.log(error);
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return []; //data

        }
        //return null;
    }
  
}
  
// singleton
export default (new APILOGIN());
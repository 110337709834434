


import CryptoJS from 'crypto-js';
import config from '../../data/config';

class APIGETPROFILE_USERNAME {

    async Request(_username = '') {
        
        const accountid = config.accountData?.account_id || -1;
        const username = config.accountData?.account_username || '_';
        const password = config.accountData?.account_password || '_';
        
        const hash = CryptoJS.MD5(username + config.secretKey).toString();
        
        let response = null;

        try {
            response = await fetch(`https://server-t01.torogram.com/php/profile/GET_USERNAME.php/?username=${_username}&accountid=${accountid}&user=${username}&password=${password}&hash=${hash}`, {
                method: 'GET',
            });
        } catch(err) {
            return [];
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return [];

        }

    }
  
}
  
// singleton
export default (new APIGETPROFILE_USERNAME());
import React from 'react';
import { Link } from 'react-router-dom';

import config from '../../data/config';
import constants from '../../data/constants';

import logo_dark from '../../assets/images/T_light.png';
import logo_light from '../../assets/images/T_dark.png';
import withRouter from '../../data/withRouter';

import icon_features from '../../assets/images/torogram/icons/star.png';
import icon_team from '../../assets/images/torogram/icons/team.png';
import icon_tokenomics from '../../assets/images/torogram/icons/pie_chart.png';
import icon_roadmap from '../../assets/images/torogram/icons/roadmap.png';
import icon_links from '../../assets/images/torogram/icons/links.png';
import icon_global from '../../assets/images/torogram/icons/global.png';
import icon_users from '../../assets/images/torogram/icons/users.png';
import icon_news from '../../assets/images/torogram/icons/news.png';
import icon_posts from '../../assets/images/torogram/icons/posts.png';
import icon_info from '../../assets/images/torogram/icons/info.png';
import icon_portfolio from '../../assets/images/torogram/icons/portfolio.png';
import icon_search from '../../assets/images/torogram/icons/search.png';
import icon_bell from '../../assets/images/torogram/icons/bell.png';
import icon_bookmarks from '../../assets/images/torogram/icons/bookmarks.png';
import icon_chat from '../../assets/images/torogram/icons/chat.png';
import icon_staking from '../../assets/images/torogram/icons/staking.png';
import icon_hub from '../../assets/images/torogram/icons/hub.png';
import icon_dex from '../../assets/images/torogram/icons/dex.png';
import Alert from '../Alert/Alert';
import Modal from '../Modal/Modal';
import ImageViewer from '../ImageViewer/imageViewer';
import DropdownMobile from '../DropdownMobile/DropdownMobile';
// import ConnectWalletButton from '../Web3/connectWalletButton';
// import ConnectedWallet from '../Web3/connectedWallet';

import icon_home from '../../assets/images/torogram/icons/home.png';
import icon_profile from '../../assets/images/torogram/icons/profile.png';
import icon_books from '../../assets/images/torogram/icons/books.png';

import ModalContent_CreatePost from '../Modal/ModalContent_CreatePost';
import Modal2 from '../Modal/Modal2';
import Modal3 from '../Modal/Modal3';
import ModalContent_Settings from '../Modal/ModalContent_Settings';
import APIGETUNREADCHATMESSAGES from '../../api/ChatMessage/APIGETUNREADCHATMESSAGES';
import APIGETUNREADNOTIFICATIONS from '../../api/Notification/APIGETUNREADNOTIFICATIONS';



class Nav extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.homeScrollHeight = this.props.scrollHeight ? this.props.scrollHeight : 0;
        this.homePostPage = this.props.postPage ? this.props.postPage : 0;

        this.accountData = this.props.accountData;
        this.home_tab = this.props.home_tab;
        this.changeHomeTab = this.props.changeHomeTab;

        this.state = {

            tab: this.props.tab,

            // alert
            alertTitle: '',
            alertText: '',
            alertButtonText: '',
            alertButtonAction: null,

            // notifs
            unreadChats: 0,
            unreadChatsText: '',
            unreadNotifications: 0,
            unreadNotificationsText: '',

        };
        
        this.reloadNav_function = this.reloadNav.bind(this);

    }

    async componentDidMount() {

        window.addEventListener('reloadNav', this.reloadNav_function);

        // const accountData = sessionStorage.getItem('account_data');
        // if (constants.isEmpty(accountData) === false) {
        //     this.accountData = JSON.parse(accountData);
        // }

        this.loadBadges();

        if (constants.isEmpty(config.accountData) === false) {
            this.badgesInterval = setInterval(() => {
                this.loadBadges();
            }, 5000);
        }

        setTimeout(() => {

            // get data
            const htmlTag = document.getElementsByTagName("html")[0];
            const theme = localStorage.getItem('theme');

            if (theme === 'dark') {

                // set theme
                htmlTag.className = 'dark';

                try {
                    let chk = document.getElementById('chk');
                    chk.checked = false; // checkbox parameters
                } catch (error) { }

            } else {

                // set theme
                htmlTag.className = 'light';

                try {
                    let chk = document.getElementById('chk');
                    chk.checked = true; // checkbox parameters
                } catch (error) { }

            }
            
        }, 2000);

    }

    async loadBadges() {

        if (constants.isEmpty(config.accountData) === true) {
            clearInterval(this.badgesInterval);
            return;
        }

        const [
            unreadChats,
            unreadNotifications,
        ] = await Promise.all([
            APIGETUNREADCHATMESSAGES.Request(),
            APIGETUNREADNOTIFICATIONS.Request(),
        ]);

        const unreadMessages = constants.convertToObjectWithArrays(unreadChats, 'chat_id');

        this.setState({ 
            unreadChats: constants.isEmpty(unreadMessages) === false ? Object.keys(unreadMessages).length : 0,
            unreadChatsText: constants.isEmpty(unreadMessages) === false ? Object.keys(unreadMessages).length > 99 ? '99+' : Object.keys(unreadMessages).length : '',
            unreadNotifications: unreadNotifications.length,
            unreadNotificationsText: unreadNotifications.length > 99 ? '99+' : unreadNotifications.length,
        });

    }

    componentDidUpdate(prevProps, prevState) {

        // LOGGED IN
        if (constants.isEmpty(this.accountData) && constants.isEmpty(this.props.accountData) === false) {
            this.accountData = this.props.accountData;
            this.forceUpdate();
        } else if (constants.isEmpty(this.accountData) === false && constants.isEmpty(this.props.accountData) === false && this.accountData.info_id !== this.props.accountData.info_id) {
            this.accountData = this.props.accountData;
            this.forceUpdate();
        } else if (constants.isEmpty(this.accountData) === false && constants.isEmpty(this.props.accountData) === false && this.accountData.info_profilepicture !== this.props.accountData.info_profilepicture) {
            this.accountData = this.props.accountData;
            this.forceUpdate();
        }

        // HOME TAB
        if (constants.isEmpty(this.home_tab) && constants.isEmpty(this.props.home_tab) === false) {
            this.home_tab = this.props.home_tab;
            this.forceUpdate();
        } else if (constants.isEmpty(this.home_tab) === false && constants.isEmpty(this.props.home_tab) === false && this.home_tab !== this.props.home_tab) {
            this.home_tab = this.props.home_tab;
            this.forceUpdate();
        }

    }

    componentWillUnmount() {
        window.removeEventListener('reloadNav', this.reloadNav_function);
        clearInterval(this.badgesInterval);
    }

    reloadNav() {

        this.accountData = config.accountData;

        this.forceUpdate();

    }

    // shouldComponentUpdate(nextProps, nextState) {

    //     // LOGGED IN
    //     if (constants.isEmpty(this.accountData) && constants.isEmpty(nextProps.accountData) === false) {
    //         this.accountData = nextProps.accountData;
    //         this.forceUpdate();
    //         return true;
    //     } else if (constants.isEmpty(this.accountData) === false && constants.isEmpty(nextProps.accountData) === false && this.accountData.info_id !== nextProps.accountData.info_id) {
    //         this.accountData = nextProps.accountData;
    //         this.forceUpdate();
    //         return true;
    //     }

    //     this.forceUpdate();
    //     return false;

    // }

    logoutAlert() {

        constants.closeAllDropdowns(null, true);

        constants.showAlert(this, {
            alertTitle: 'Log out',
            alertText: 'Are you sure you want to log out from Torogram?',
            alertButtonText: 'LOGOUT',
            alertButtonAction: constants.logout,
        });

    }    

    toggleMenu() {

        const mobileMenuContainer = document.getElementById('mobileMenuContainer');
        const mobileMenu = document.getElementById('mobileMenu');

        mobileMenuContainer.classList.toggle('show');
        mobileMenu.classList.toggle('show');

    }

    toggleProfile() {

        const navDropdown = document.getElementById('dropdown_navDropdown');

        navDropdown.classList.toggle('show');

    }

    toggleMore() {

        const navDropdown = document.getElementById('dropdown_navMoreDropdown');

        navDropdown.classList.toggle('show');

    }

    changeTab(tab) {

        if (this.state.tab === tab) {
            if (this.state.tab === config.tab_home) {
                this.changeHomeTab(config.home_tab_global);
                window.scrollTo(0, 0);
            }
            return;
        }

        // this.setState({ tab: tab });

        if (tab === config.tab_info) {

            this.props.navigate('/toro/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_home) {

            this.props.navigate('/social', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_portfolio) {

            this.props.navigate('/portfolio/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_explore) {

            this.props.navigate('/explore/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_chats) {

            this.props.navigate('/chats/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_notifications) {

            this.props.navigate('/notifications/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_bookmarks) {

            this.props.navigate('/bookmarks/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_staking) {

            this.props.navigate('/staking/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_games) {

            this.props.navigate('/games/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_hub) {

            this.props.navigate('/hub/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_dex) {

            this.props.navigate('/token/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_profile) {

            if (constants.isEmpty(this.accountData) === false) {
                this.props.navigate(`/${this.accountData.account_username}`, { replace: false, preventScrollReset: false, state: {} });
            } else {
                this.props.navigate(`/login/`, { replace: false, preventScrollReset: false, state: {} });
            }

        }

    }

    renderHomeTabs() {

        let toRender = <div></div>;

        if (this.state.tab === config.tab_info) {

            toRender = (<div>

                <a className={`w3s_button`} href="#features">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-star' /> */}
                        <img src={icon_features} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Features</span>
                    </div>
                </a>

                <a className={`w3s_button`} href="#team">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-users' /> */}
                        <img src={icon_team} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Team</span>
                    </div>
                </a>

                <a className={`w3s_button`} href="#tokenomics">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-circle-three-quarters' /> */}
                        <img src={icon_tokenomics} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Tokenomics</span>
                    </div>
                </a>

                <a className={`w3s_button`} href="#roadmap">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-network-wired' /> */}
                        <img src={icon_roadmap} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Roadmap</span>
                    </div>
                </a>

                <a className={`w3s_button w3s_disabled`} href="#links">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-location-dot' /> */}
                        <img src={icon_links} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Useful links</span>
                    </div>
                </a>

            </div>);

        } else if (this.state.tab === config.tab_home) {

             toRender = (<div>

                <div className={`w3s_button ${this.home_tab === config.home_tab_global ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.home_tab_global)}>
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-globe' /> */}
                        <img src={icon_global} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Global</span>
                    </div>
                </div>

                <div className={`w3s_button ${this.home_tab === config.home_tab_following ? 'active' : ''} w3s_disabled`} onClick={() => this.changeHomeTab(config.home_tab_following)}>
                    <div className='w3s_button_icon text-indigo-500'>
                        {/* <i className='far fa-users' /> */}
                        <img src={icon_users} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Following</span>
                    </div>
                </div>

                <div className={`w3s_button ${this.home_tab === config.home_tab_updates ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.home_tab_updates)}>
                    <div className='w3s_button_icon text-indigo-400'>
                        {/* <i className='far fa-newspaper' /> */}
                        <img src={icon_news} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Torogram updates</span>
                    </div>
                </div>

            </div>);

        } else if (this.state.tab === config.tab_profile) {

            toRender = (<div>

               <div className={`w3s_button ${this.home_tab === config.home_tab_global ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.profile_tab_posts)}>
                   <div className='w3s_button_icon'>
                       <img src={icon_posts} alt="" />
                   </div>
                   <div className='w3s_button_text'>
                       <span>Posts</span>
                   </div>
               </div>

               <div className={`w3s_button ${this.home_tab === config.home_tab_following ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.profile_tab_info)}>
                   <div className='w3s_button_icon'>
                       <img src={icon_info} alt="" />
                   </div>
                   <div className='w3s_button_text'>
                       <span>Info</span>
                   </div>
               </div>

               {/* <div className={`w3s_button ${this.home_tab === config.home_tab_updates ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.home_tab_updates)}>
                   <div className='w3s_button_icon text-indigo-400'>
                       <img src={icon_news} alt="" />
                   </div>
                   <div className='w3s_button_text'>
                       <span>Torogram updates</span>
                   </div>
               </div> */}

           </div>);

       }

        return toRender;

    }

    openSettings() {
        constants.showModal(this, <ModalContent_Settings parent={this} loading={false} options={{  }} />, 'sm')
    }

    changeMode(mode, event) {
        switch (mode) {
            case 'mode':
                if (document.documentElement.className.includes("dark")) {
                    document.documentElement.className = 'light'
                    localStorage.setItem('theme', 'light');
                } else {
                    document.documentElement.className = 'dark'
                    localStorage.setItem('theme', 'dark');
                }
                break;
            case 'layout':
                if (event.target?.innerText === "LTR") {
                    document.documentElement.dir = "ltr"
                }
                else {
                    document.documentElement.dir = "rtl"
                }
                break;
            default:
                break;
        }
    }

    render() {
        
        return (
            <>

                {/* IMAGE VIEWER */}
                <ImageViewer parent={this} content={config.contentToShow} nft={config.contentToShow_nft} data={config.contentToShow_data} />

                {/* MODAL */}
                <Modal parent={this} content={config.modalContent} size={config.modalSize} />

                {/* MODAL2 */}
                <Modal2 parent={this} content={config.modalContent2} size={config.modalSize2} />

                {/* MODAL3 */}
                <Modal3 parent={this} content={config.modalContent3} size={config.modalSize3} />

                {/* ALERT */}
                <Alert parent={this} content={{ title: config.alertTitle, text: config.alertText, buttonText: config.alertButtonText, action: config.alertButtonAction }} options={{ ...config.alertOptions }} />

                {/* MOBILE DROPDOWN */}
                <DropdownMobile parent={this} content={config.mobileDropdownContent} />

                {/* NORMAL NAV */}
                <div id='w3s_top_nav_desktop' className='home_left_container'>

                    <Link className="logo mb-5" style={{ marginLeft: 10 }} to="/social" onClick={(e) => this.props.location.pathname === '/social' ? window.scrollY === 0 ? window.location.reload() : window.scrollTo(0, 0) : null}>
                        <img src={logo_dark} className="block dark:hidden" alt="" style={{ height: 40 }} />
                        <img src={logo_light} className="hidden dark:block" alt="" style={{ height: 40 }} />
                    </Link>

                    <div className={`w3s_button_n ${this.state.tab === config.tab_home ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_home)}>
                        <div className='w3s_button_icon text-indigo-600'>
                            {/* <i className='far fa-globe' /> */}
                            <img src={icon_home} alt="" />
                        </div>
                        <div className='w3s_button_text'>
                            <span>Home</span>
                        </div>
                    </div>

                    <div className={`w3s_button_n ${this.state.tab === config.tab_profile ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_profile)}>
                        <div className='w3s_button_icon text-indigo-600'>
                            {/* <i className='far fa-globe' /> */}
                            <img src={icon_profile} alt="" />
                        </div>
                        <div className='w3s_button_text'>
                            <span>Profile</span>
                        </div>
                    </div>

                    <div className={`w3s_button_n ${this.state.tab === config.tab_explore ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_explore)}>
                        <div className='w3s_button_icon text-indigo-600'>
                            {/* <i className='far fa-globe' /> */}
                            <img src={icon_search} alt="" />
                        </div>
                        <div className='w3s_button_text'>
                            <span>Explore</span>
                        </div>
                    </div>

                    {constants.isEmpty(config.accountData) === false ?
                        <div className={`w3s_button_n ${this.state.tab === config.tab_notifications ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_notifications)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_bell} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Notifications</span>
                            </div>
                            {this.state.unreadNotifications > 0 ?
                                <div className='unreadBubble nav'>
                                    <span>{this.state.unreadNotificationsText}</span>
                                </div>
                            : null}
                        </div>
                    : null}

                    {constants.isEmpty(config.accountData) === false ?
                        <div className={`w3s_button_n ${this.state.tab === config.tab_bookmarks ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_bookmarks)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_bookmarks} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Bookmarks</span>
                            </div>
                        </div>
                    : null}

                    {constants.isEmpty(config.accountData) === false ?
                        <div className={`w3s_button_n ${this.state.tab === config.tab_chats ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_chats)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_chat} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Chats</span>
                            </div>
                            {this.state.unreadChats > 0 ?
                                <div className='unreadBubble nav'>
                                    <span>{this.state.unreadChatsText}</span>
                                </div>
                            : null}
                        </div>
                    : null}

                    {constants.isWebview() === false ?
                        <div className={`w3s_button_n ${this.state.tab === config.tab_hub ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_hub)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_hub} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Web3 HUB</span>
                            </div>
                        </div>
                    : null}

                    {/* {constants.isWebview() === false ? */}
                        <div className={`w3s_button_n ${this.state.tab === config.tab_portfolio ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_portfolio)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_portfolio} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Portfolio</span>
                            </div>
                        </div>
                    {/* : null} */}

                    <div className={`w3s_button_n ${this.state.tab === config.tab_dex ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_dex)}>
                        <div className='w3s_button_icon text-indigo-600'>
                            {/* <i className='far fa-globe' /> */}
                            <img src={icon_dex} alt="" />
                        </div>
                        <div className='w3s_button_text'>
                            <span>DEX / Scan Token</span>
                        </div>
                    </div>

                    <div className={`w3s_button_n`} onClick={() => this.toggleMore()}>

                        <div className='w3s_button_icon'>
                            <i className='fas fa-ellipsis' />
                            {/* <img src={icon_portfolio} alt="" /> */}
                        </div>
                        <div className='w3s_button_text'>
                            <span>More</span>
                        </div>

                        <div className='w3s_nav_dropdown moreMenu bg-white dark:bg-slate-900' id="dropdown_navMoreDropdown">
                            
                            {constants.isWebview() === false ?
                                <Link className={`w3s_button_n dropdown_li moreMenu`} to='/'>
                                    <div className='dropdown_li w3s_button_icon text-indigo-600'>
                                        {/* <i className='far fa-globe' /> */}
                                        <img className='dropdown_li' src={icon_info} alt="" />
                                    </div>
                                    <div className='dropdown_li w3s_button_text'>
                                        <span className='dropdown_li'>Landing</span>
                                    </div>
                                </Link>
                            : null}

                            {constants.isWebview() === false ?
                                <div className={`dropdown_li w3s_button_n moreMenu`} onClick={() => window.open('https://docs.torogram.com', '_blank')}>
                                    <div className='dropdown_li w3s_button_icon text-indigo-600'>
                                        {/* <i className='far fa-globe' /> */}
                                        <img className='dropdown_li' src={icon_books} alt="" />
                                    </div>
                                    <div className='dropdown_li w3s_button_text'>
                                        <span className='dropdown_li'>Docs</span>
                                    </div>
                                </div>
                            : null}

                            {/* {constants.isWebview() === false ? */}
                                <div className={`dropdown_li w3s_button_n moreMenu ${this.state.tab === config.tab_staking ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_staking)}>
                                    <div className='dropdown_li w3s_button_icon text-indigo-600'>
                                        {/* <i className='far fa-globe' /> */}
                                        <img className='dropdown_li' src={icon_staking} alt="" />
                                    </div>
                                    <div className='dropdown_li w3s_button_text'>
                                        <span className='dropdown_li'>Stake TORO</span>
                                    </div>
                                </div>
                            {/* : null} */}

                        </div>

                    </div>

                    <div className={`w3s_post_button mt-5 ${constants.isEmpty(this.accountData) === true ? 'w3s_disabled' : ''}`} onClick={() => constants.showModal(this.props.parent, <ModalContent_CreatePost parent={this.props.parent} loading={false} options={{}} />)}>
                        <span>Post</span>
                    </div>


                    {/* PROFILE */}
                    {constants.isEmpty(this.accountData) === false ?
                        <div className='w3s_nav_profile_button' onClick={() => this.toggleProfile()}>
                            <div className='w3s_nav_profile_button_1 me-4'>
                                <div className='w3s_nav_profilePictureContainer'>
                                    {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                        <img src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                    :
                                        <i className='far fa-user' />
                                    }
                                </div>
                            </div>
                            <div className='w3s_nav_profile_button_1'>
                                <div><span className='font-bold'>{constants.getUserFullName(this.accountData, true)}</span></div>
                                <div style={{ marginTop: -4 }}><span className='text-muted-500 text-sm'>@{this.accountData.account_username}</span></div>
                            </div>
                            <div className='w3s_nav_profile_button_2'>
                                <i className='fas fa-ellipsis' />
                            </div>
                        </div>
                    : 
                        <Link to={'/login/'} className='w3s_nav_profile_loginButton mt-5'>
                            <span>Sign in</span>
                        </Link>
                    }

                </div>
                
                <div id="w3s_top_nav" className="w3s_navbar bg-white/02 dark:bg-slate-900/02">

                    <div className="w3s_container">

                        <div className="w3s_left-column">

                            {this.state.tab === config.tab_post || this.state.tab === config.tab_reply ?

                                <>

                                    {/* {this.props.location.key !== 'default' ?
                                        <div className='w3s_icon_button' onClick={() => this.props.navigate(-1)}>
                                            <i className='fas fa-chevron-left cursor-pointer' />
                                        </div>
                                    : */}
                                        <Link to={'/social'} state={{ scrollHeight: this.homeScrollHeight, postPage: this.homePostPage }} className='w3s_icon_button'>
                                            <i className='fas fa-chevron-left cursor-pointer' />
                                        </Link>
                                    {/* } */}
                                
                                </>

                            :

                                <Link className="logo" to="/social" onClick={(e) => this.props.location.pathname === '/social' ? window.scrollTo(0, 0) : null}>
                                    <img src={logo_dark} className="block dark:hidden" alt="" style={{ height: 34 }} />
                                    <img src={logo_light} className="hidden dark:block" alt="" style={{ height: 34 }} />
                                </Link>

                            }

                        </div>

                        {this.state.tab === config.tab_post ?

                            <div className="w3s_middle-column-mobile">
                                <span className='font-bold'>Post</span>
                            </div>

                        : null}

                        <div className="w3s_middle-column">

                            <div className='w3s_middle-column_container'>

                                {/* {this.state.tab === config.tab_info ?
                                    <div className='w3s_navButton active'>
                                        <i className='fas fa-circle-info' />
                                    </div>
                                :
                                    <div className='w3s_navButton' onClick={() => this.changeTab(config.tab_info)}>
                                        <i className='far fa-circle-info' />
                                    </div>
                                } */}

                                {this.state.tab === config.tab_home ?
                                    <div className='w3s_navButton active' onClick={() => this.changeTab(config.tab_home)}>
                                        <i className='fas fa-house-blank' />
                                    </div>
                                :
                                    <div className='w3s_navButton' onClick={() => this.changeTab(config.tab_home)}>
                                        <i className='far fa-house-blank' />
                                    </div>
                                }

                                {this.state.tab === config.tab_images ?
                                    <div className='w3s_navButton active'>
                                        <i className='fas fa-image' />
                                    </div>
                                :
                                    <div className='w3s_navButton w3s_disabled'>
                                        <i className='far fa-image' />
                                    </div>
                                }

                                {this.state.tab === config.tab_videos ?
                                    <div className='w3s_navButton active'>
                                        <i className='fas fa-video' />
                                    </div>
                                :
                                    <div className='w3s_navButton w3s_disabled'>
                                        <i className='far fa-video' />
                                    </div>
                                }

                                {this.state.tab === config.tab_games ?
                                    <div className='w3s_navButton active'>
                                        <i className='fas fa-gamepad' />
                                    </div>
                                :
                                    <div className='w3s_navButton w3s_disabled' onClick={() => this.changeTab(config.tab_games)}>
                                        <i className='far fa-gamepad' />
                                    </div>
                                }

                            </div>
                            
                        </div>

                        <div className="w3s_right-column">

                            {constants.isEmpty(this.accountData) === false ?
                                <div className='w3s_profileButton' onClick={() => this.toggleProfile()}>

                                    {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                        <img src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                    :
                                        <i className='far fa-user' />
                                    }

                                </div>
                            :
                                <Link className='w3s_profileButton' to="/login/">
                                    <i className='far fa-right-to-bracket' />
                                </Link>
                            }

                        </div>

                        <div className="w3s_right-column_mobile">

                            {constants.isEmpty(this.accountData) === false ?
                                <div className='w3s_profileButton mobile' onClick={() => this.toggleMenu()}>

                                    {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                        <img src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                    :
                                        <i className='far fa-user' />
                                    }

                                </div>
                            :
                                <div className='w3s_menuButton mobile' onClick={() => this.toggleMenu()}>
                                    <i className='far fa-bars' />
                                </div>
                            }

                            {/* {constants.isEmpty(this.accountData) === false ?
                                // <div className='w3s_profileButton me-1' onClick={() => this.toggleProfile()}>

                                //     {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                //         <img src={constants.getFileLoc(this.accountData.account_username, this.accountData.info_profilepicture)} alt="" />
                                //     :
                                //         <i className='far fa-user' />
                                //     }

                                // </div>
                                null
                            :
                                // me-1
                                <Link className='w3s_profileButton' to="/login/">
                                    <i className='far fa-right-to-bracket' />
                                </Link>
                            } */}

                            {/* <div className='w3s_menuButton' onClick={() => this.toggleMenu()}>
                                <i className='far fa-bars' />
                            </div> */}

                        </div>

                    </div>

                </div>

                {/* MOBILE NAV BOTTOM */}
                <div id='w3s_bottom_nav' className='w3s_mobile_navbar bg-white/02 dark:bg-slate-900/02'>

                    <div className='w3s_mobile_navbar_container'>

                        {/* {this.state.tab === config.tab_info ?
                            <div className='w3s_navButton mobile active top'>
                                <i className='fas fa-circle-info' />
                            </div>
                        :
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_info)}>
                                <i className='far fa-circle-info' />
                            </div>
                        } */}

                        {this.state.tab === config.tab_home ?
                            <div className='w3s_navButton mobile active top' onClick={() => this.changeTab(config.tab_home)}>
                                <i className='fas fa-house-blank' />
                            </div>
                        :
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_home)}>
                                <i className='far fa-house-blank' />
                            </div>
                        }

                        {this.state.tab === config.tab_explore ?
                            <div className='w3s_navButton mobile active top'>
                                <i className='fas fa-magnifying-glass' />
                            </div>
                        :
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_explore)}>
                                <i className='far fa-magnifying-glass' />
                            </div>
                        }

                        {/* UNCOMMENT */}
                        {/* constants.isWebview() === false &&  */}
                        {this.state.tab === config.tab_portfolio ?
                            <div className='w3s_navButton mobile active top'>
                                <i className='fas fa-wallet' />
                            </div>
                        :
                        // constants.isWebview() === false ?
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_portfolio)}>
                                <i className='far fa-wallet' />
                            </div>
                        // : 
                        //     null
                        }

                        {constants.isEmpty(config.accountData) === false ?
                            this.state.tab === config.tab_notifications ?
                                <div className='w3s_navButton mobile active top relative'>
                                    <i className='fas fa-bell' />
                                    {this.state.unreadNotifications > 0 ?
                                        <div className='unreadBubble navMobile'>
                                            <span className='font-normal'>{this.state.unreadNotificationsText}</span>
                                        </div>
                                    : null}
                                </div>
                            :
                                <div className='w3s_navButton mobile relative' onClick={() => this.changeTab(config.tab_notifications)}>
                                    <i className='far fa-bell' />
                                    {this.state.unreadNotifications > 0 ?
                                        <div className='unreadBubble navMobile'>
                                            <span className='font-normal'>{this.state.unreadNotificationsText}</span>
                                        </div>
                                    : null}
                                </div>
                        : null}

                        {constants.isEmpty(config.accountData) === false ?
                            this.state.tab === config.tab_chats ?
                                <div className='w3s_navButton mobile active top relative'>
                                    <i className='fas fa-comments' />
                                    {this.state.unreadChats > 0 ?
                                        <div className='unreadBubble navMobile'>
                                            <span className='font-normal'>{this.state.unreadChatsText}</span>
                                        </div>
                                    : null}
                                </div>
                            :
                                <div className='w3s_navButton mobile relative' onClick={() => this.changeTab(config.tab_chats)}>
                                    <i className='far fa-comments' />
                                    {this.state.unreadChats > 0 ?
                                        <div className='unreadBubble navMobile'>
                                            <span className='font-normal'>{this.state.unreadChatsText}</span>
                                        </div>
                                    : null}
                                </div>
                        : null}

                    </div>

                </div>

                <div className='w3s_mobile-menu_container' id="mobileMenuContainer" onClick={() => this.toggleMenu()}>

                    <div className="w3s_mobile-menu bg-gray-50/02 dark:bg-slate-950/02" id="mobileMenu">

                        {constants.isEmpty(this.accountData) === false ?

                            <div className={`w3s_button nav_profile_button`} onClick={() => this.props.navigate(`/${this.accountData.account_username}`, { replace: false, preventScrollReset: false, state: {} })}>
                                <div className='dropdown_li nav_profile_button_1'>
                                    <div className='dropdown_li nav_profile_button_profile'>

                                        {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                            <img className='dropdown_li' src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                        :
                                            <i className='dropdown_li far fa-user' />
                                        }

                                    </div>
                                </div>
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li font-bold'>{constants.getUserFullName(this.accountData)}</span>
                                    <span className='dropdown_li text-sm font-normal text-muted-500'>@{this.accountData.account_username}</span>
                                </div>
                                <div className='dropdown_li nav_profile_button_3'>
                                    <i className='dropdown_li fas fa-chevron-right' />
                                </div>
                            </div>

                        : 

                            <Link className={`w3s_button nav_profile_button`} to="/login/">
                                <div className='dropdown_li nav_profile_button_1' style={{ width: 50 }}>
                                    <div className='dropdown_li nav_profile_button_icon' style={{ width: 32, height: 32, fontSize: 15 }}>
                                        <i className='dropdown_li fas fa-right-to-bracket' />
                                    </div>
                                </div>
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li'>Sign in</span>
                                </div>
                            </Link>

                        }

                        {/* <ConnectWalletButton /> */}

                        {/* {this.state.tab === config.tab_games ?
                            <ConnectedWallet mobileNav={true} />
                        : null} */}
                        
                        {/* <div className='w3s_subtitle text-muted-500 text-lg mt-3'>
                            <span>Your shortcuts</span>
                        </div>

                        {this.renderHomeTabs()} */}

                        <div className={`w3s_button ${this.state.tab === config.tab_profile ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_profile)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_profile} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Profile</span>
                            </div>
                        </div>

                        {constants.isEmpty(config.accountData) === false ?
                            <div className={`w3s_button ${this.state.tab === config.tab_bookmarks ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_bookmarks)}>
                                <div className='w3s_button_icon text-indigo-600'>
                                    {/* <i className='far fa-globe' /> */}
                                    <img src={icon_bookmarks} alt="" />
                                </div>
                                <div className='w3s_button_text'>
                                    <span>Bookmarks</span>
                                </div>
                            </div>
                        : null}

                        <div className={`w3s_button ${this.state.tab === config.tab_dex ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_dex)}>
                            <div className='w3s_button_icon text-indigo-600'>
                                {/* <i className='far fa-globe' /> */}
                                <img src={icon_dex} alt="" />
                            </div>
                            <div className='w3s_button_text'>
                                <span>DEX / Scan token</span>
                            </div>
                        </div>

                        <div className='dropdown_li w3s_divider' style={{ marginTop: 10, marginBottom: 10 }} />

                        {/* {constants.isWebview() === false ? */}
                            <Link className={`w3s_button`} to='/'>
                                <div className='w3s_button_icon text-indigo-600'>
                                    {/* <i className='far fa-globe' /> */}
                                    <img src={icon_info} alt="" />
                                </div>
                                <div className='w3s_button_text'>
                                    <span>Landing</span>
                                </div>
                            </Link>
                        {/* : null} */}

                        {/* {constants.isWebview() === false ? */}
                            <div className={`w3s_button`} onClick={() => window.open('https://docs.torogram.com', '_blank')}>
                                <div className='w3s_button_icon text-indigo-600'>
                                    {/* <i className='far fa-globe' /> */}
                                    <img src={icon_books} alt="" />
                                </div>
                                <div className='w3s_button_text'>
                                    <span>Docs</span>
                                </div>
                            </div>
                        {/* : null} */}

                        {/* UNCOMMENT */}
                        {/* {constants.isWebview() === false ? */}
                            <div className={`w3s_button ${this.state.tab === config.tab_staking ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_staking)}>
                                <div className='w3s_button_icon text-indigo-600'>
                                    {/* <i className='far fa-globe' /> */}
                                    <img src={icon_staking} alt="" />
                                </div>
                                <div className='w3s_button_text'>
                                    <span>Stake TORO</span>
                                </div>
                            </div>
                        {/* : null} */}

                        {constants.isWebview() === false ?
                            <div className={`w3s_button ${this.state.tab === config.tab_hub ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_hub)}>
                                <div className='w3s_button_icon text-indigo-600'>
                                    {/* <i className='far fa-globe' /> */}
                                    <img src={icon_hub} alt="" />
                                </div>
                                <div className='w3s_button_text'>
                                    <span>Web3 HUB</span>
                                </div>
                            </div>
                        : null}

                        {constants.isEmpty(this.accountData) === false ?
                            <div className='dropdown_li w3s_divider' style={{ marginTop: 10, marginBottom: 10 }} />
                        : null}

                        {constants.isEmpty(this.accountData) === false ?
                            <div className={`w3s_button nav_profile_button`} onClick={() => this.openSettings()}>
                                <div className='dropdown_li nav_profile_button_1' style={{ width: 50 }}>
                                    <div className='dropdown_li nav_profile_button_icon' style={{ width: 32, height: 32, fontSize: 15 }}>
                                        <i className='dropdown_li fas fa-gear' />
                                    </div>
                                </div>
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li'>Settings</span>
                                </div>
                            </div>
                        : null}

                        {constants.isEmpty(this.accountData) === false ?
                            constants.isTrue(this.accountData.account_web3) ?
                                null
                            :
                                <div className={`w3s_button nav_profile_button red`} onClick={() => this.logoutAlert()}>
                                    <div className='dropdown_li nav_profile_button_1' style={{ width: 50 }}>
                                        <div className='dropdown_li nav_profile_button_icon' style={{ width: 32, height: 32, fontSize: 15 }}>
                                            <i className='dropdown_li fas fa-right-from-bracket' />
                                        </div>
                                    </div>
                                    <div className='dropdown_li nav_profile_button_2'>
                                        <span className='dropdown_li'>Log out</span>
                                    </div>
                                </div>
                        : null}

                        <div className='flex items-center justify-between w3s_break_word' style={{ padding: '0px 10px', position: 'absolute', bottom: 20, width: 300 }}>
                            <span className='font-semibold'>Theme</span>
                            <label className="switchTheme">
                                <input type="checkbox" id="chk" onClick={(event) => this.changeMode('mode', event)} />
                                <span className="sliderTheme"></span>
                            </label>
                        </div>

                        {/* <div className='w3s_subtitle text-muted-500 text-lg' style={{ marginTop: 14 }}>
                            <span>Menu</span>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_info ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_info)}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-circle-info' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>About Toro</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_home ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_home)}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-house-blank' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Timeline</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_images ? 'active' : ''} w3s_disabled`}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-image' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Photos</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_videos ? 'active' : ''} w3s_disabled`}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-video' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Videos</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_games ? 'active' : ''} w3s_disabled`}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-gamepad' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Gaming</span>
                            </div>
                        </div> */}

                    </div>

                </div>

                {constants.isEmpty(this.accountData) === false ?
                    <div className='w3s_nav_dropdown bg-white dark:bg-slate-900' id="dropdown_navDropdown">
                        <ul>
                            <li className='dropdown_li nav_profile_button' onClick={() => this.props.navigate(`/${this.accountData.account_username}`, { replace: false, preventScrollReset: false, state: {} })}>
                                <div className='dropdown_li nav_profile_button_1'>
                                    <div className='dropdown_li nav_profile_button_profile'>

                                        {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                            <img className='dropdown_li' src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                        :
                                            <i className='dropdown_li far fa-user' />
                                        }

                                    </div>
                                </div>
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li font-bold'>{constants.getUserFullName(this.accountData)}</span>
                                    <span className='dropdown_li text-sm text-muted-500 font-normal'>@{this.accountData.account_username}</span>
                                </div>
                                <div className='dropdown_li nav_profile_button_3'>
                                    <i className='dropdown_li fas fa-chevron-right' />
                                </div>
                            </li>

                            <div className='dropdown_li w3s_divider' style={{ marginTop: 10, marginBottom: 10 }} />

                            <li className='dropdown_li nav_profile_button' onClick={() => this.openSettings()}>
                                {/* <div className='dropdown_li nav_profile_button_1'>
                                    <div className='dropdown_li nav_profile_button_icon'>
                                        <i className='dropdown_li fas fa-right-from-bracket' />
                                    </div>
                                </div> */}
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li'>Settings</span>
                                </div>
                            </li>

                            {constants.isTrue(this.accountData.account_web3) ?
                                // <Dec_Logout /> 
                                null
                            :
                                <li className='dropdown_li nav_profile_button red' onClick={() => this.logoutAlert()}>
                                    {/* <div className='dropdown_li nav_profile_button_1'>
                                        <div className='dropdown_li nav_profile_button_icon'>
                                            <i className='dropdown_li fas fa-right-from-bracket' />
                                        </div>
                                    </div> */}
                                    <div className='dropdown_li nav_profile_button_2'>
                                        <span className='dropdown_li'>Log out</span>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                : null}

            </>
        );
    }
  
}

export default withRouter(Nav);
import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';
import icon_account from '../../assets/images/torogram/icons/account.png';
import icon_password from '../../assets/images/torogram/icons/password.png';
import icon_profile from '../../assets/images/torogram/icons/profile.png';
import icon_privacy from '../../assets/images/torogram/icons/privacy.png';
import icon_wallets from '../../assets/images/torogram/icons/wallets.png';
import icon_theme from '../../assets/images/torogram/icons/theme.png';
import icon_notifications from '../../assets/images/torogram/icons/notifications.png';
import ModalContent_Settings_Theme from './ModalContent_Settings_Theme';
import ModalContent_Settings_Notifications from './ModalContent_Settings_Notifications';

class ModalContent_Settings extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.parent = this.props.parent;
        this.options = this.props.options; // reloadProfile(stateReload), 

        this.state = {

            loading: constants.isEmpty(this.props.loading) === false ? this.props.loading : true,

        };

    }

    async componentDidMount() {

        this.setState({ loading: false });

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.options) && constants.isEmpty(this.props.options) === false) {

            this.options = this.props.options;
            this.forceUpdate();

        } else if (constants.isEmpty(this.options) === false && constants.isEmpty(this.props.options) === false && this.options !== this.props.options) {
            
            this.options = this.props.options;
            this.forceUpdate();

        }

        if (this.state.loading !== this.props.loading && prevState.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

    }

    setPage(page) {

        if (this.state.page === page) {
            return;
        }

        if (page === 'profile') {

        }

    }

    goBack(page) {

        if (page === 'profile') {
            
        }

    }

    close() {

        this.setState({ 

        });

        config.modalContent = null;
        config.modalSize = 'normal';

        // CLOSE
        const modal = document.getElementById('w3s_modal');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        // this.parent.forceUpdate();

    }

    render() {

        return (
            <div style={{ width:'100%', height:'100%' }}>

                {this.state.loading === true ?

                    // LOADER
                    <div className='w3s_loader_modal bg-transparent'>
                        <div className="w3s_spinner">
                            <div className="w3s_double-bounce1"></div>
                            <div className="w3s_double-bounce2"></div>
                        </div>
                    </div>

                :

                    <div style={{ width:'100%', height:'100%' }}>

                        {/* PAGES */}
                        {/* <ModalPage_Profile parent={this.parent} options={this.options} /> */}

                        <div className='modal_header'>
                            {/* <div className='modal_back' onClick={() => this.back()}><i className='fas fa-arrow-left' /></div> */}
                            <h2 className='text-xl'>Settings</h2>
                            <div className='modal_close' onClick={() => this.close()}><i className='fas fa-xmark' /></div>
                        </div>

                        <div className='w3s_divider' />

                        <div className='modal_content'>

                            {/* SCROLLABLE AREA */}
                            <div className='modal_content_post notPost'>

                                <div className='modal_settings_button' onClick={() => this.openAccount()}>
                                    <div className='modal_settings_button_icon'>
                                        <img src={icon_account} alt="" />
                                    </div>
                                    <div className='modal_settings_button_text'>
                                        Account
                                    </div>
                                    <div className='modal_settings_button_right'>
                                        <i className='fas fa-arrow-right' />
                                    </div>
                                </div>

                                {constants.isTrue(config.accountData.account_web3) === false ?
                                    <div className='modal_settings_button' onClick={() => this.openPassword()}>
                                        <div className='modal_settings_button_icon'>
                                            <img src={icon_password} alt="" />
                                        </div>
                                        <div className='modal_settings_button_text'>
                                            Password
                                        </div>
                                        <div className='modal_settings_button_right'>
                                            <i className='fas fa-arrow-right' />
                                        </div>
                                    </div>
                                : null}

                                <div className='modal_settings_button mt-2' onClick={() => this.openProfile()}>
                                    <div className='modal_settings_button_icon'>
                                        <img src={icon_profile} alt="" />
                                    </div>
                                    <div className='modal_settings_button_text'>
                                        Profile
                                    </div>
                                    <div className='modal_settings_button_right'>
                                        <i className='fas fa-arrow-right' />
                                    </div>
                                </div>

                                <div className='modal_settings_button mt-2' onClick={() => this.openWallets()}>
                                    <div className='modal_settings_button_icon'>
                                        <img src={icon_wallets} alt="" />
                                    </div>
                                    <div className='modal_settings_button_text'>
                                        Wallets
                                    </div>
                                    <div className='modal_settings_button_right'>
                                        <i className='fas fa-arrow-right' />
                                    </div>
                                </div>

                                <div className='modal_settings_button mt-2' onClick={() => this.openPrivacy()}>
                                    <div className='modal_settings_button_icon'>
                                        <img src={icon_privacy} alt="" />
                                    </div>
                                    <div className='modal_settings_button_text'>
                                        Privacy
                                    </div>
                                    <div className='modal_settings_button_right'>
                                        <i className='fas fa-arrow-right' />
                                    </div>
                                </div>

                                <div className='modal_settings_button mt-2' onClick={() => this.openNotifications()}>
                                    <div className='modal_settings_button_icon'>
                                        <img src={icon_notifications} alt="" />
                                    </div>
                                    <div className='modal_settings_button_text'>
                                        Notifications
                                    </div>
                                    <div className='modal_settings_button_right'>
                                        <i className='fas fa-arrow-right' />
                                    </div>
                                </div>

                                <div className='modal_settings_button mt-2' onClick={() => this.openTheme()}>
                                    <div className='modal_settings_button_icon'>
                                        <img src={icon_theme} alt="" />
                                    </div>
                                    <div className='modal_settings_button_text'>
                                        Theme
                                    </div>
                                    <div className='modal_settings_button_right'>
                                        <i className='fas fa-arrow-right' />
                                    </div>
                                </div>

                            </div>

                            {/* BUTTON */}
                            {/* <div className={`w3s_info_button_full mt-5`} onClick={() => this.save()}><i className='fas fa-check me-2' />Save</div> */}
                         
                        </div>

                    </div>

                }

            </div>
        );
    }
  
}

export default withRouter(ModalContent_Settings);
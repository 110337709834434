import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Switch from './component/Switch';

// EXTRA
import './assets/libs/flickity.css';
import 'react-advanced-cropper/dist/style.css';
import { Toaster } from 'react-hot-toast';

// import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
// import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
// import { InjectedConnector } from 'wagmi/connectors/injected'
// import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

// import { WagmiConfig, configureChains, createConfig } from 'wagmi';
// import { mainnet, bsc, bscTestnet, cronos, polygon, arbitrum } from 'viem/chains';

// import { infuraProvider } from 'wagmi/providers/infura'

import config from './data/config';
import Landing from './pages/landing/landing';
import Play from './pages/play/play';
import Payout from './pages/payout/payout';

// // 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'd2aa8632fcd379066d4827bdaaa958f6';

// // 2. Create wagmiConfig
// const { chains, publicClient } = configureChains(
//     [ mainnet, bsc, cronos, polygon, arbitrum ], // bsc        // infuraProvider({ apiKey: '4f5ca298c6c045139314db875e83010e' }),
//     [
//         jsonRpcProvider({
//             rpc: (chain) => ({
//                 http: chain.id === mainnet.id ? `https://ethereum.publicnode.com`
//                         : chain.id === bsc.id ? `https://bsc-dataseed1.binance.org/`
//                         : chain.id === cronos.id ? `https://cronos-evm.publicnode.com`
//                         : chain.id === polygon.id ? `https://polygon-bor.publicnode.com`
//                         : chain.id === arbitrum.id ? `https://arbitrum-one.publicnode.com`
//                         : `https://ethereum.publicnode.com`,
//             }),
//         }),
//         // jsonRpcProvider({
//         //     rpc: (chain) => ({
//         //         http: `https://bsc-dataseed1.binance.org/`,
//         //     }),
//         // }),
//         // jsonRpcProvider({
//         //     rpc: (chain) => ({
//         //         http: `https://cronos-evm.publicnode.com`,
//         //     }),
//         // }),
//         // jsonRpcProvider({
//         //     rpc: (chain) => ({
//         //         http: `https://polygon-bor.publicnode.com`,
//         //     }),
//         // }),
//         // jsonRpcProvider({
//         //     rpc: (chain) => ({
//         //         http: `https://arbitrum-one.publicnode.com`,
//         //     }),
//         // }),
//     ]
// )

const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://ethereum-rpc.publicnode.com'
};

const metadata = {
    name: 'Web3Scape',
    description: 'Connect your wallet with Web3Scape!',
    url: 'https://web3scape.io',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
};

// const wagmiConfig = createConfig({
//     autoConnect: true,
//     connectors: [
//         new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
//         new InjectedConnector({ chains, options: { shimDisconnect: true } }),
//     ],
//     publicClient
// })

// config.wagmiConfig = wagmiConfig;

createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
});


// // 3. Create modal
// createWeb3Modal({ 
//     wagmiConfig, 
//     projectId, 
//     chains, 
//     themeVariables: {
//         '--w3m-z-index': 1001,
//     },
// });

export default function App() {
    return (
        // <WagmiConfig config={wagmiConfig}>

            <BrowserRouter>

                {/* TOASTER */}
                <div><Toaster
                    toastOptions={{
                        className: 'w3s_toast',
                    }}
                    containerStyle={{
                        marginTop: 50,
                        marginBottom: 50,
                    }}
                /></div>

                <Routes>
                    {/* <Route path="/" element={<Navigate to="/index" />} /> */}
                    <Route path="/add-wallet" element={<Landing />} />

                    <Route path="/payout" element={<Payout />} />

                    {/* PLAY */}
                    {/* <Route exact path="/play" element={<Play />} /> */}

                    {/* INFO */}
                    {/* <Route exact path="/toro" element={<Info />} /> */}

                    {/* GAMES */}
                    {/* <Route exact path="/games" element={<Games />} />
                    <Route exact path="/games/raffle" element={<Raffle />} /> */}

                </Routes>
            
            </BrowserRouter>

        // </WagmiConfig>
    )
}

import config from "../../data/config";




class APIPostNewWallet {

    async Request(jwt, wallet) {
        
        let response = null;

        // let data = new FormData();
        // data.append("username", username);
        // data.append("password", password);

        let data = { 
            "address": wallet
        };

        console.log(jwt)
        console.log(wallet)

        try {
            response = await fetch(`http://81.169.241.250:8081/api/wallet/user/add`, {
                method: 'POST',
                // mode: 'no-cors',
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(data),
            });
        } catch(err) {
            console.log(err)
            return [];
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return [];

        }

    }
  
}
  
// singleton
export default (new APIPostNewWallet());
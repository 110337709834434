import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';
import APIGETPRIVACY from '../../api/Privacy/APIGETPRIVACY';
import APIPUTWALLETMAIN from '../../api/Wallet/APIPUTWALLETMAIN';
import APIPOSTPRIVACY from '../../api/Privacy/APIPOSTPRIVACY';
import APIPUTPRIVACY from '../../api/Privacy/APIPUTPRIVACY';
import APIGETPN from '../../api/pushnotification/APIGETPN';
import APIPOSTPN from '../../api/pushnotification/APIPOSTPN';
import APIPUTPN from '../../api/pushnotification/APIPUTPN';
import APIDELETEPN from '../../api/pushnotification/APIDELETEPN';

class ModalContent_Settings_Notifications extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.parent = this.props.parent;
        this.options = this.props.options; // reloadProfile(stateReload), 

        this.state = {

            loading: constants.isEmpty(this.props.loading) === false ? this.props.loading : true,

            pushnotifications: null,
            hasPermissions: false,

        };

        this.onWebViewMessage_function = this.onWebViewMessage.bind(this);

    }

    async componentDidMount() {

        await this.loadData();

        this.setState({ loading: false });

        if (constants.isWebview()) {
            window.addEventListener("message", this.onWebViewMessage_function);
        }

    }

    async onWebViewMessage(event) {

        if (event.data.startsWith('notification_permissions:')) {
            await this.loadData();
        }

        this.setState({ loading: false });

    }

    async loadData() {

        const [
            notifications,
        ] = await Promise.all([
            APIGETPN.Request(config.accountData.info_id),
        ]);

        const pushnotifications = constants.isEmpty(notifications) ? null : notifications[0];

        this.setState({ 

            pushnotifications: pushnotifications,
            hasPermissions: constants.isEmpty(pushnotifications) ? false : !constants.isEmpty(pushnotifications.n_token),

        }, () => {

            document.getElementById('notify_permissions').checked = constants.isEmpty(pushnotifications) ? false : !constants.isEmpty(pushnotifications.n_token);
            document.getElementById('notify_follower').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_follow;
            document.getElementById('notify_mention').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_mention;
            document.getElementById('notify_chat').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_chat;
            document.getElementById('notify_likep').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_like_post;
            document.getElementById('notify_commentp').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_comment_post;
            document.getElementById('notify_repostp').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_repost_post;
            document.getElementById('notify_liker').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_like_reply;
            document.getElementById('notify_commentr').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_comment_reply;
            document.getElementById('notify_repostr').checked = constants.isEmpty(pushnotifications) ? false : pushnotifications.n_repost_reply;

        });

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.options) && constants.isEmpty(this.props.options) === false) {

            this.options = this.props.options;
            this.forceUpdate();

        } else if (constants.isEmpty(this.options) === false && constants.isEmpty(this.props.options) === false && this.options !== this.props.options) {
            
            this.options = this.props.options;
            this.forceUpdate();

        }

        // if (this.state.loading !== this.props.loading && prevState.loading !== this.props.loading) {
        //     this.setState({ loading: this.props.loading });
        // }

    }

    componentWillUnmount() {

        window.removeEventListener("message", this.onWebViewMessage_function);

    }

    async setCheckbox(type) {

        const permissions = document.getElementById('notify_permissions').checked;
        const follower = document.getElementById('notify_follower').checked;
        const mention = document.getElementById('notify_mention').checked;
        const chat = document.getElementById('notify_chat').checked;
        const likep = document.getElementById('notify_likep').checked;
        const commentp = document.getElementById('notify_commentp').checked;
        const repostp = document.getElementById('notify_repostp').checked;
        const liker = document.getElementById('notify_liker').checked;
        const commentr = document.getElementById('notify_commentr').checked;
        const repostr = document.getElementById('notify_repostr').checked;

        const notifications = await APIGETPN.Request(config.accountData.info_id);
        const pushnotifications = constants.isEmpty(notifications) ? null : notifications[0];

        // permissions
        if (type === 'permissions') {

            if (permissions) {

                try {
                    if (constants.isWebview()) {
                        this.setState({ loading: true });
                        window.postMessage('notification_permissions:');
                        window.ReactNativeWebView.postMessage('notification_permissions:');
                    }
                } catch (error) {
                    return;
                }

            }

            return;

        }

        if (constants.isEmpty(pushnotifications) === true) {
            await APIPOSTPN.Request(null, follower ? 1 : 0, 0, 0, 0, 0, 0, 0, 0, 0);
        } else {
            await APIDELETEPN.Request();
            await APIPOSTPN.Request(pushnotifications.n_token, follower ? 1 : 0, mention ? 1 : 0, chat ? 1 : 0, likep ? 1 : 0, commentp ? 1 : 0, repostp ? 1 : 0, liker ? 1 : 0, commentr ? 1 : 0, repostr ? 1 : 0);
        }

        await this.loadData();

        // if (type === 'wallet') {

        //     if (wallet === false && constants.isEmpty(this.state.mainWallet) === false) {
        //         await APIPUTWALLETMAIN.Request(this.state.mainWallet.wallet_id, 0);
        //     }

        // } else {
        //     await APIPUTPRIVACY.Request(country ? 1 : 0, created ? 1 : 0);
        // }

        // this.options?.reloadProfile?.(true);

    }

    close() {

        const modal = document.getElementById('w3s_modal2');
        modal.classList.remove('show');
        // document.body.classList.remove('modal-open');

        config.modalContent2 = null;
        config.modalSize2 = 'normal';

        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);

    }

    render() {

        return (
            <div style={{ width:'100%', height:'100%' }}>

                {this.state.loading === true ?

                    // LOADER
                    <div className='w3s_loader_modal bg-transparent'>
                        <div className="w3s_spinner">
                            <div className="w3s_double-bounce1"></div>
                            <div className="w3s_double-bounce2"></div>
                        </div>
                    </div>

                :

                    <div style={{ width:'100%', height:'100%' }}>

                        {/* PAGES */}
                        {/* <ModalPage_Profile parent={this.parent} options={this.options} /> */}

                        <div className='modal_header'>
                            <div className='modal_back' onClick={() => this.close()}><i className='fas fa-arrow-left' /></div>
                            <h2 className='text-xl'>Notifications</h2>
                        </div>

                        <div className='w3s_divider' />

                            <div className='modal_content'>

                                {/* SCROLLABLE AREA */}
                                <div className='modal_content_post notPost'>

                                    {/* <label className="switch">
                                        <input type="checkbox" />
                                        <div className="slider">
                                            <div className="circle">
                                                <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                    </g>
                                                </svg>
                                                <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </label> */}

                                    <div className='flex items-center justify-between w3s_break_word mt-2'>
                                        <div>
                                            <div className='font-semibold'>Notification permissions</div>
                                            <div className='text-xs text-muted-600'>Turn 'off' and 'on' to reset permissions (must be on mobile)</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_permissions' type="checkbox" onClick={() => this.setCheckbox('permissions')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>New follower</div>
                                            <div className='text-xs text-muted-600'>Receive notifications when someone started following you</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_follower' type="checkbox" onClick={() => this.setCheckbox('follower')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Mentions</div>
                                            <div className='text-xs text-muted-600'>Receive notifications when you've been mentioned in a post/reply</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_mention' type="checkbox" onClick={() => this.setCheckbox('mention')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Chat</div>
                                            <div className='text-xs text-muted-600'>Receive notifications about chats</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_chat' type="checkbox" onClick={() => this.setCheckbox('chat')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Post like</div>
                                            <div className='text-xs text-muted-600'>Receive notification when someone likes your post</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_likep' type="checkbox" onClick={() => this.setCheckbox('likep')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Post comment</div>
                                            <div className='text-xs text-muted-600'>Receive notification when someone comments on your post</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_commentp' type="checkbox" onClick={() => this.setCheckbox('commentp')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Post repost</div>
                                            <div className='text-xs text-muted-600'>Receive notification when someone reposts your post</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_repostp' type="checkbox" onClick={() => this.setCheckbox('repostp')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Reply like</div>
                                            <div className='text-xs text-muted-600'>Receive notification when someone likes your reply</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_liker' type="checkbox" onClick={() => this.setCheckbox('liker')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Reply comment</div>
                                            <div className='text-xs text-muted-600'>Receive notification when someone comments on your reply</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_commentr' type="checkbox" onClick={() => this.setCheckbox('commentr')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={`flex items-center justify-between w3s_break_word mt-2 ${this.state.hasPermissions ? '' : 'w3s_disabled'}`}>
                                        <div>
                                            <div className='font-semibold'>Reply repost</div>
                                            <div className='text-xs text-muted-600'>Receive notification when someone reposts your reply</div>
                                        </div>
                                        <label className="switch">
                                            <input id='notify_repostr' type="checkbox" onClick={() => this.setCheckbox('repostr')} />
                                            <div className="slider">
                                                <div className="circle">
                                                    <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                        </g>
                                                    </svg>
                                                    <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                </div>

                                {/* BUTTON */}
                                {/* <div className={`w3s_info_button_full mt-5`} onClick={() => this.saveProfile()}><i className='fas fa-check me-2' />Save</div> */}
                            
                            </div>

                    </div>

                }

            </div>
        );
    }
  
}

export default withRouter(ModalContent_Settings_Notifications);



class APIPayoutToken {

    async Request(token, chain, amount, jwt, wallet) {
        
        let response = null;

        try {
            response = await fetch(`https://server-t-web3-api.torogram.com/api/payout-token?token=${token}&chain=${chain}&amount=${amount}&jwt=${jwt}&wallet=${wallet}`, {
                method: 'GET',
            });
        } catch(err) {
            return [];
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return [];

        }

    }
  
}
  
// singleton
export default (new APIPayoutToken());
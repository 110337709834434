import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';

class Modal extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.content = this.props.content;
        this.size = this.props.size;

        this.state = {

        };

    }

    async componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.content) && constants.isEmpty(this.props.content) === false) {
            this.content = this.props.content;
            this.forceUpdate();
        } else if (constants.isEmpty(this.content) === false && constants.isEmpty(this.props.content) === false && this.content !== this.props.content) {
            this.content = this.props.content;
            this.forceUpdate();
        } else if (constants.isEmpty(this.size) && constants.isEmpty(this.props.size) === false) {
            this.size = this.props.size;
            this.forceUpdate();
        } else if (constants.isEmpty(this.size) === false && constants.isEmpty(this.props.size) === false && this.size !== this.props.size) {
            this.size = this.props.size;
            this.forceUpdate();
        }

    }

    closeOnClick(e) {

        if (e.target.id !== 'w3s_modal') {
            return;
        }

        this.content = null;
        this.size = 'normal';

        config.modalContent = null;
        config.modalSize = 'normal';

        const modal = document.getElementById('w3s_modal');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        this.forceUpdate();

    }

    render() {

        return (
            <div id='w3s_modal' className='modal' onMouseDown={(e) => this.closeOnClick(e)}>

                <div className={`modal_container ${this.size} bg-white dark:bg-slate-800`}>
                    {this.content}
                </div>

            </div>
        );
    }
  
}

export default withRouter(Modal);
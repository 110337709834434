// import { useWeb3Modal } from '@web3modal/wagmi/react';
// import { useAccount, useDisconnect, useNetwork, useSignMessage, useSwitchNetwork } from 'wagmi';

import metamask_icon from '../../assets/images/torogram/icons/metamask.png';
import { useState } from 'react';
import React from 'react';
import Web3 from 'web3';
import config from '../../data/config';

import { BrowserProvider, ethers } from 'ethers';
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import toast from 'react-hot-toast';
import bigInt from 'big-integer';
import APIVerifyAndSaveWallet from '../../api/XHunt/APIVerifyAndSaveWallet';
import constants from '../../data/constants';

export default function VerifyAndSaveWallet(props) {

    const [ loading, setLoading ] = useState(false);

    const { open, close } = useWeb3Modal();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { disconnect } = useDisconnect();
    const { walletProvider } = useWeb3ModalProvider();

    const [ _address, setAddress ] = useState(null);

    const sign = async () => {

        // get provider
        const provider = new BrowserProvider(walletProvider);

        setLoading(true);
        setAddress(address);

        const network = await provider.getNetwork();

        if (bigInt(network.chainId).eq(bigInt(1)) === false) {
            toast.error(`Please switch to Ethereum network in your wallet`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            constants.switchNetwork();
            setLoading(false);
            return;
        }

        const timestamp = new Date().getTime();
        const msg = `save`;

        // const message = (`login${timestamp}`);
        const message = ethers.solidityPackedKeccak256(
            [ 'address', 'string', 'uint256' ],
            [ address, msg, timestamp ]
        );
        const hashedMessage = ethers.toBeArray(message);

        // get signer (mostly walletconnect -> metamask)
        const signer = await provider.getSigner();

        try {
            // get signature
            const signature = await signer?.signMessage(hashedMessage);

            // verify signature
            const verifiedAndSaved = await APIVerifyAndSaveWallet.Request(address, msg, timestamp, signature, props.userData.id);

            if (constants.isTrue(verifiedAndSaved) === true) {

                // done it's saved
                toast.success(`Saved wallet to your account!`, {
                    position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                    duration: 5000,
                });

                setLoading(false);

            } else {
                toast.error(`Wrong signature`, {
                    position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                    duration: 5000,
                });
                setLoading(false);
            }

        } catch (error) {
            toast.error(`You've denied the wallet signing`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            setLoading(false);
        }

    };

    if (loading) {

        return (
            <div className='w3s_info_button_gray_full mt-5'>
                <div className="w3s_spinner_sm">
                    <div className="w3s_double-bounce1"></div>
                    <div className="w3s_double-bounce2"></div>
                </div>
            </div>
        );

    } else if (isConnected && constants.isEmpty(address) === false) {

        // if (constants.isEmpty(_address) === true || _address !== address) {
        //     sign();
        // }

        return (
            <div className={`w3s_info_button_gray_full mt-5`} onClick={() => sign()}><img src={metamask_icon} width={24} />&nbsp;Sign and Save Wallet</div>
        );

    } else {

        return (
            <div className={`w3s_info_button_gray_full mt-5`} onClick={() => open()}><img src={metamask_icon} width={24} />&nbsp;Connect wallet</div>
        );

    }

};
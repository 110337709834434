import React from "react";
import { Link } from "react-router-dom";
import logo_icon_80 from '../../assets/images/logo-icon-80.png';
import {FaRegEnvelope,FaDribbble,FaLinkedin, FaFacebookF, FaInstagram, FaTwitter,FaRegFile,FaBehance,PiShoppingCart} from '../../assets/icons/icons'

import logo from '../../assets/web3scape/web3scape_x1000.png';
import logo_s from '../../assets/web3scape/web3scape_icon.png';


export default function Footer(){
    return(
        <footer className='footer_box animateIn'>

            <div className='footer_row'>

                <div className='footer_logo'>

                    <Link to="/" onClick={(e) => this.props.location.pathname === '/' ? window.scrollTo(0, 0) : null}>
                        <img src={logo} className="block dark:hidden" alt="" style={{ height: 80 }} />
                        <img src={logo} className="hidden dark:block" alt="" style={{ height: 80 }} />
                    </Link>

                    <div className='text-muted-600 mt-4 text-sm'>Bringing you back what you loved!</div>
                                    
                    <div className='footer_socials mt-4'>
                        <div className='hero_social_button2'>
                            <i className='fab fa-x' />
                        </div>
                        <div className='hero_social_button2'>
                            <i className='fab fa-telegram' />
                        </div>
                        <div className='hero_social_button2'>
                            <i className='fab fa-discord' />
                        </div>
                        <div className='hero_social_button2'>
                            <i className='fas fa-chart-column' />
                        </div>
                        <div className='hero_social_button2'>
                            <i className='fa-kit fa-solid-unicorn-circle-dollar' />
                        </div>
                        {/* <a className="w3s_footer_button" href='https://torogram.com' target='_blank'>
                            <i className="far fa-globe"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://t.me/torogramportal' target='_blank'>
                            <i className="fab fa-telegram"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://x.com/torogram_eth' target='_blank'>
                            <i className="fab fa-x"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://www.linkedin.com/in/jordy-ortega-872911128/' target='_blank'>
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://instagram.com/torogram.official' target='_blank'>
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://tiktok.com/@torogramofficial' target='_blank'>
                            <i className="fab fa-tiktok"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://medium.com/@torogram' target='_blank'>
                            <i className="fab fa-medium"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://github.com/jordyortega/Torogram' target='_blank'>
                            <i className="fab fa-github"></i>
                        </a>
                        <a className="w3s_footer_button" href='mailto:info@torogram.com' target='_blank'>
                            <i className="far fa-envelope"></i>
                        </a>
                        <a className="w3s_footer_button" href='https://torogram.com/social' target='_blank'>
                            <i className="far fa-mobile"></i>
                        </a> */}
                    </div>
                                    
                </div>

                <div className='w3s_footer_links'>
                    <div className='font-bold text-muted-600'>Web3Scape</div>
                    <div className='w3s_link_inherit mt-1'>Account Panel</div>
                    <div className='w3s_link_inherit mt-1'>Features</div>
                    <div className='w3s_link_inherit mt-1'>Guides</div>
                    <div className='w3s_link_inherit mt-1'>Highscores</div>
                    <div className='w3s_link_inherit mt-1'>Vote</div>
                    <div className='w3s_link_inherit mt-1'>Community</div>
                </div>

                <div className='w3s_footer_links'>
                    <div className='font-bold text-muted-600'>Resources</div>
                    <a className='w3s_link_inherit mt-1' href="https://java.com/download" target="_blank">Java</a>
                    <a className='w3s_link_inherit mt-1' href="https://web3scape.io/game/downloads/Web3Scape Launcher.jar" target="_blank">Launcher</a>
                    <a className='w3s_link_inherit mt-1' href="https://web3scape.io/game/downloads/web3scape.jar" target="_blank">Direct Client</a>
                </div>

            </div>

            <div className='w-full text-center mt-4'>
                <span className='text-muted-500'>© Copyright Web3Scape</span>
            </div>

        </footer>
    )
}
import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';
import APIPOSTPOST from '../../api/Post/APIPOSTPOST';
import toast from 'react-hot-toast';
import Flickity from 'react-flickity-component';
import APIPOSTPOSTIMAGE from '../../api/Post/APIPOSTPOSTIMAGE';
import APIUPLOADFILE from '../../api/APIUPLOADFILE';
import APIPUTPOST from '../../api/Post/APIPUTPOST';

import Compress from 'compress.js';
import APIDELETEPOSTIMAGE from '../../api/Post/APIDELETEPOSTIMAGE';
import APIDELETEFILE from '../../api/APIDELETEFILE';
import ModalContent_GIF from './ModalContent_GIF';
import APIPOSTHASHTAG_POST from '../../api/Hashtag/APIPOSTHASHTAG_POST';
import APIGETMENTIONS_NAME from '../../api/Mention/APIGETMENTIONS_NAME';

import EmojiPicker from 'emoji-picker-react';
import APIGETPROFILE_USERNAME from '../../api/Profile/APIGETPROFILE_USERNAME';
import torogramNotifications from '../../data/torogramNotifications';

class ModalContent_CreatePost extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.parent = this.props.parent;
        this.options = this.props.options;

        this.isTyping = false;

        this.mentions = [];

        this.state = {

            showFileDrop: false,
            files: [],
            postImages: [],
            postImagesDelete: [],

            currentMention: null,
            mentionsList: [],

            privacy: config.privacy_everyone,

            GIF: null,

            emojisOpen: false,

            text: '',

            loading: constants.isEmpty(this.props.loading) === false ? this.props.loading : true,

        };

    }

    async componentDidMount() {

        this.setState({ loading: false }, () => {

            if (constants.isEmpty(this.options.post) === false && constants.isEmpty(this.postArea) === false) { // is editing post

                this.postArea.defaultValue = `${constants.decodeEntities(this.options.post.post_text)}`;
                this.postArea.value = `${constants.decodeEntities(this.options.post.post_text)}`;

                // add post images
                if (this.options?.postImages || this.options?.postImages?.length > 0) {
                    this.setState({ postImages: this.options.postImages });
                }

                // set privacy
                if (constants.isEmpty(this.options?.post?.post_privacy) === false && this.options.post.post_privacy > 0) {
                    this.setState({ privacy: this.options.post.post_privacy });
                }

                this.setState({ text: constants.isEmpty(this.options.post.post_text) ? '' : this.options.post.post_text, GIF: constants.isEmpty(this.options.post.post_gif) === false ? this.options.post.post_gif : null });
                this.forceUpdate();
                
                // set post area height
                if (this.postArea) {
                    setTimeout(() => {
                        this.resizeTextArea_hard(this.postArea);
                    }, 100);
                }

            }

            // focus on opening modal
            if (this.postArea && this.isTyping !== true) {
                this.postArea.focus();
                this.postArea.selectionStart = this.postArea.selectionEnd = this.postArea.value.length;
            }
            
        });

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.options) && constants.isEmpty(this.props.options) === false) {

            this.options = this.props.options;

            // eventually load post options
            if (constants.isEmpty(this.options.post) === false && constants.isEmpty(this.postArea) === false) { // is editing post
                this.postArea.defaultValue = `${constants.decodeEntities(this.options.post.post_text)}`;
                this.postArea.value = `${constants.decodeEntities(this.options.post.post_text)}`;
                this.setState({ text: constants.isEmpty(this.options.post.post_text) ? '' : this.options.post.post_text, GIF: constants.isEmpty(this.options.post.post_gif) === false ? this.options.post.post_gif : null });
            }

            // add post images
            if (this.options?.postImages || this.options?.postImages?.length > 0) {
                this.setState({ postImages: this.options.postImages });
            }

            // set privacy
            if (constants.isEmpty(this.options?.post?.post_privacy) === false && this.options.post.post_privacy > 0) {
                this.setState({ privacy: this.options.post.post_privacy });
            }

            // set focus on open new modal
            if (this.postArea && this.isTyping !== true) {
                this.postArea.focus();
                this.postArea.selectionStart = this.postArea.selectionEnd = this.postArea.value.length;
            }

            this.forceUpdate();

            // set post area height
            if (this.postArea) {
                setTimeout(() => {
                    this.resizeTextArea_hard(this.postArea);
                }, 100);
            }

        } else if (constants.isEmpty(this.options) === false && constants.isEmpty(this.props.options) === false && this.options !== this.props.options) {
            
            this.options = this.props.options;

            // eventually load post options
            if (constants.isEmpty(this.options.post) === false && constants.isEmpty(this.postArea) === false) { // is editing post
                this.postArea.defaultValue = `${constants.decodeEntities(this.options.post.post_text)}`;
                this.postArea.value = `${constants.decodeEntities(this.options.post.post_text)}`;
                this.setState({ text: constants.isEmpty(this.options.post.post_text) ? '' : this.options.post.post_text, GIF: constants.isEmpty(this.options.post.post_gif) === false ? this.options.post.post_gif : null });
            }

            // add post images
            if (this.options?.postImages || this.options?.postImages?.length > 0) {
                this.setState({ postImages: this.options.postImages });
            }

            // set privacy
            if (constants.isEmpty(this.options?.post?.post_privacy) === false && this.options.post.post_privacy > 0) {
                this.setState({ privacy: this.options.post.post_privacy });
            }

            // set focus on open new modal
            if (this.postArea && this.isTyping !== true) {
                this.postArea.focus();
                this.postArea.selectionStart = this.postArea.selectionEnd = this.postArea.value.length;
            }

            this.forceUpdate();
            
            // set post area height
            if (this.postArea) {
                setTimeout(() => {
                    this.resizeTextArea_hard(this.postArea);
                }, 100);
            }

        }

        if (this.state.loading !== this.props.loading && prevState.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

    }

    decentralized_verify() {

        const text = document.getElementById('createpost').value;

        if (text.length < 1 && constants.isEmpty(this.state.GIF)) {
            toast.error(`Post needs to be longer than 1 character`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return false;
        }

        if (text.length > 500) {
            toast.error(`Post cannot be longer than 500 characters`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return false;
        }

        return true;

    }

    async decentralized_upload() {

        let imgs = [];

        // UPLOAD IMAGES
        if (this.state.files.length > 0) {

            const files = Array.from(this.state.files);

            for (const file of files) {

                // GIFS, VIDEOS, ETC
                if (file.type === 'image/gif' || file.type.includes('video')) {

                    const uploaded = await APIUPLOADFILE.Request(file);

                    if (uploaded.status === 'success') {

                        imgs.push(uploaded.loc);

                    } else {
                        // error
                    }

                // JPGS, PNGS etc
                } else { // compress png and jpg etc

                    let _files = [];
                    _files.push(file);

                    const compress = new Compress();
                    const newFiles = await compress.compress(_files, {
                        size: 1, // the max size in MB, defaults to 2MB
                        quality: .75, // the quality of the image, max is 1,
                        maxWidth: 1920, // the max width of the output image, defaults to 1920px
                        maxHeight: 1920, // the max height of the output image, defaults to 1920px
                        resize: true, // defaults to true, set false if you do not want to resize the image width and height
                        rotate: false, // See the rotation section below
                    });

                    for (const file of newFiles) { // only has 1 so [0], but compress always returns an array

                        // from compressed data to file data
                        const img1 = file;
                        const base64str = img1.data;
                        const imgExt = img1.ext;
                        const _file = Compress.convertBase64ToFile(base64str, imgExt);

                        const uploaded = await APIUPLOADFILE.Request(_file);

                        if (uploaded.status === 'success') {

                            imgs.push(uploaded.loc);

                        } else {
                            // error
                        }

                    }

                }

            }

        }

        return imgs;

    }

    async decentralized_post(imgs) {

        const text = document.getElementById('createpost').value;
        const date = new Date();
        const dateString = constants.dateToString(date);
        const timeString = constants.dateToTimeString(date);

        if (text.length < 1 && constants.isEmpty(this.state.GIF)) {
            toast.error(`Post needs to be longer than 1 character`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        if (text.length > 500) {
            toast.error(`Post cannot be longer than 500 characters`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        this.setState({ loading: true });

        // GIF
        const GIF = constants.isEmpty(this.state.GIF) === false ? this.state.GIF : null;

        const postid = await APIPOSTPOST.Request(text, GIF, dateString, timeString, this.state.privacy);

        if (constants.isEmpty(postid) === true) {
            // SUCCESS
            toast.error(`Error occured while posting`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            this.close();
            return null;
        }

        // HASHTAGS
        const hashtags = text.match(/#(\w+)/g);
        let hashtagPromises = [];
        if (constants.isEmpty(hashtags) === false) {
            for (const hashtag of hashtags) {
                hashtagPromises.push(APIPOSTHASHTAG_POST.Request(postid, hashtag.substring(1)));
            }
        }

        await Promise.all(hashtagPromises);

        // UPLOAD IMAGES
        if (imgs.length > 0) {
            for (const imgLoc of imgs) {
                await APIPOSTPOSTIMAGE.Request(postid, imgLoc);
            }
        }

        // reload posts event
        window.dispatchEvent(config.event_reloadPosts);

        this.close();

    }

    async post() {

        const text = document.getElementById('createpost').value;
        const date = new Date();
        const dateString = constants.dateToString(date);
        const timeString = constants.dateToTimeString(date);

        if (text.length < 1 && constants.isEmpty(this.state.GIF)) {
            toast.error(`Post needs to be longer than 1 character`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        if (text.length > 500) {
            toast.error(`Post cannot be longer than 500 characters`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        this.setState({ loading: true });

        // GIF
        const GIF = constants.isEmpty(this.state.GIF) === false ? this.state.GIF : null;

        const postid = await APIPOSTPOST.Request(text, GIF, dateString, timeString, this.state.privacy);

        if (constants.isEmpty(postid) === true) {
            // SUCCESS
            toast.error(`Error occured while posting`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            this.close();
            return;
        }

        // HASHTAGS
        const hashtags = text.match(/#(\w+)/g);
        let hashtagPromises = [];
        if (constants.isEmpty(hashtags) === false) {
            for (const hashtag of hashtags) {
                hashtagPromises.push(APIPOSTHASHTAG_POST.Request(postid, hashtag.substring(1)));
            }
        }

        // MENTIONS NOTIFY
        const mentions = text.match(/@(\w+)/g);
        if (constants.isEmpty(mentions) === false) {

            for (const mention of mentions) {

                const _mention = mention.substring(1);
                const profile = await APIGETPROFILE_USERNAME.Request(_mention);

                if (constants.isEmpty(profile[0]) === false) {

                    torogramNotifications.sendPushNotification(profile[0].account_id, 'Torogram', `${config.accountData.info_display} has mentioned you in a post`, 'mention', {});

                }

            }

        }

        await Promise.all(hashtagPromises);

        // UPLOAD IMAGES
        if (this.state.files.length > 0) {

            const files = Array.from(this.state.files);

            for (const file of files) {

                // GIFS, VIDEOS, ETC
                if (file.type === 'image/gif' || file.type.includes('video')) {

                    const uploaded = await APIUPLOADFILE.Request(file);

                    if (uploaded.status === 'success') {

                        await APIPOSTPOSTIMAGE.Request(postid, uploaded.loc);

                    } else {
                        // error
                    }

                // JPGS, PNGS etc
                } else { // compress png and jpg etc

                    let _files = [];
                    _files.push(file);

                    const compress = new Compress();
                    const newFiles = await compress.compress(_files, {
                        size: 1, // the max size in MB, defaults to 2MB
                        quality: .75, // the quality of the image, max is 1,
                        maxWidth: 1920, // the max width of the output image, defaults to 1920px
                        maxHeight: 1920, // the max height of the output image, defaults to 1920px
                        resize: true, // defaults to true, set false if you do not want to resize the image width and height
                        rotate: false, // See the rotation section below
                    });

                    for (const file of newFiles) { // only has 1 so [0], but compress always returns an array

                        // from compressed data to file data
                        const img1 = file;
                        const base64str = img1.data;
                        const imgExt = img1.ext;
                        const _file = Compress.convertBase64ToFile(base64str, imgExt);

                        const uploaded = await APIUPLOADFILE.Request(_file);

                        if (uploaded.status === 'success') {

                            await APIPOSTPOSTIMAGE.Request(postid, uploaded.loc);

                        } else {
                            // error
                        }

                    }

                }

            }

            // const newFiles = await compress.compress(files, {
            //     size: 1, // the max size in MB, defaults to 2MB
            //     quality: .75, // the quality of the image, max is 1,
            //     maxWidth: 1920, // the max width of the output image, defaults to 1920px
            //     maxHeight: 1920, // the max height of the output image, defaults to 1920px
            //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
            //     rotate: false, // See the rotation section below
            // });

            // for (const file of newFiles) {

            //     // from compressed data to file data
            //     const img1 = file;
            //     const base64str = img1.data;
            //     const imgExt = img1.ext;
            //     const _file = Compress.convertBase64ToFile(base64str, imgExt);

            //     const uploaded = await APIUPLOADFILE.Request(_file);

            //     if (uploaded.status === 'success') {

            //         await APIPOSTPOSTIMAGE.Request(postid, uploaded.loc);

            //     } else {
            //         // error
            //     }

            // }

        }

        // reload posts event
        window.dispatchEvent(config.event_reloadPosts);

        // SUCCESS
        toast.success(`Successfully posted`, {
            position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
            duration: 5000,
        });

        this.close();

    }

    async edit() {

        const text = document.getElementById('createpost').value;

        if (text.length < 1 && constants.isEmpty(this.state.GIF)) {
            toast.error(`Post needs to be longer than 1 character`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        this.setState({ loading: true });

        const GIF = constants.isEmpty(this.state.GIF) === false ? this.state.GIF : null;

        const done = await APIPUTPOST.Request(this.options.post.post_id, text, GIF, this.state.privacy);

        // DELETE OLD PICTURES
        if (this.state.postImagesDelete.length > 0) {
            for (const d of this.state.postImagesDelete) {
                await APIDELETEPOSTIMAGE.Request(d.postimage_id);
                await APIDELETEFILE.Request(d.postimage_loc);
            }
        }

        // UPLOAD NEW IMAGES
        if (this.state.files.length > 0) {

            const files = Array.from(this.state.files);

            for (const file of files) {

                // GIFS, VIDEOS, ETC
                if (file.type === 'image/gif' || file.type.includes('video')) {

                    const uploaded = await APIUPLOADFILE.Request(file);

                    if (uploaded.status === 'success') {

                        await APIPOSTPOSTIMAGE.Request(this.options.post.post_id, uploaded.loc);

                    } else {
                        // error
                    }

                // JPGS, PNGS etc
                } else { // compress png and jpg etc

                    let _files = [];
                    _files.push(file);

                    const compress = new Compress();
                    const newFiles = await compress.compress(_files, {
                        size: 1, // the max size in MB, defaults to 2MB
                        quality: .75, // the quality of the image, max is 1,
                        maxWidth: 1920, // the max width of the output image, defaults to 1920px
                        maxHeight: 1920, // the max height of the output image, defaults to 1920px
                        resize: true, // defaults to true, set false if you do not want to resize the image width and height
                        rotate: false, // See the rotation section below
                    });

                    for (const file of newFiles) { // only has 1 so [0], but compress always returns an array

                        // from compressed data to file data
                        const img1 = file;
                        const base64str = img1.data;
                        const imgExt = img1.ext;
                        const _file = Compress.convertBase64ToFile(base64str, imgExt);

                        const uploaded = await APIUPLOADFILE.Request(_file);

                        if (uploaded.status === 'success') {

                            await APIPOSTPOSTIMAGE.Request(this.options.post.post_id, uploaded.loc);

                        } else {
                            // error
                        }

                    }

                }

            }

        }

        if (done) {

            // reload posts event
            window.dispatchEvent(config.event_reloadPosts);

            // SUCCESS
            toast.success(`Successfully edited the post`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });

        } else {

            // ERROR
            toast.error(`Something went wrong. Please try again`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });

        }

        this.close();

    }

    // resizeTextArea(e) {
    //     const t = e.currentTarget;
    //     t.style.overflowY = 'hidden';
    //     t.style.height = "auto";
    //     const p = parseFloat(window.getComputedStyle(t, null).getPropertyValue("padding-top")) 
    //             + parseFloat(window.getComputedStyle(t, null).getPropertyValue("padding-bottom"));
    //     t.style.height = t.scrollHeight - p + "px";
    // }

    resizeTextArea_hard(element) {
        const t = element;
        t.style.height=t.scrollHeight+"px";
    }

    resizeTextArea(e) {
        const t = e.currentTarget;

        if (t.clientHeight < t.scrollHeight) {
            t.style.height=t.scrollHeight+"px";
        }

    }

    resizeTextArea_keyDown(e) {
        const t = e.currentTarget;

        if (e.keyCode === 8) {
            const p = 20;
            t.style.height = t.scrollHeight - p + "px";
        } else if (e.keyCode === 46) {
            const p = 20;
            t.style.height = t.scrollHeight - p + "px";
        }

    }

    removeFromPictures(index, file) {

        const edit = constants.isEmpty(file) === false && constants.isEmpty(file.postimage_id) === false; //  && file.postimage_id === this.state.postImages[index].postimage_id

        if (edit) {
            let files = this.state.postImages;
            constants.removeFromArray2(files, 'postimage_id', file.postimage_id);

            let toDelete = this.state.postImagesDelete;
            toDelete.push(file);

            this.setState({ postImages: files, postImagesDelete: toDelete });
        } else {
            let files = Array.from(this.state.files);
            const fileIndex = files.indexOf(file);
            files.splice(fileIndex, 1);
            this.setState({ files: files });
        }

    }

    renderImagesAndVideos() {

        const count = this.state.files.length + this.state.postImages.length;

        // RENDER 1 IMAGE/VIDEO
        if (count === 1) {

            const file0 = constants.isEmpty(this.state.files[0]) ? this.state.postImages[0] : this.state.files[0];

            return (
                // <div className='post_content_images_grid'>
                //     <div className='post_content_images_grid_1'>
                //         <img src={constants.getFileLoc(post.account_username, postimage.postimage_loc)} />
                //     </div>
                // </div>
                <div className='post_content_images createPost'>
                    <div className='post_content_images_1'>
                        {((file0 instanceof File) && file0.type.includes('video')) || (constants.isEmpty(file0.postimage_loc) === false && file0.postimage_loc.substr(file0.postimage_loc.length - 4) === '.mp4') ?
                            <>
                                <video className='preventClickPost' controls>
                                    <source className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} type="video/mp4" />
                                </video>
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                            </>
                        :
                            <>
                                <img className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} />
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                            </>
                        }
                    </div>
                </div>
            );

        }
        else // RENDER 2 IMAGES/VIDEOS
        if (count === 2) {

            let files = [];

            for (const file of this.state.postImages) {
                files.push(file);
            }
            for (const file of this.state.files) {
                files.push(file);
            }

            const file0 = files[0];
            const file1 = files[1];

            return (
                <div className='post_content_images createPost'>
                    <div className='post_content_images_2'>
                        {((file0 instanceof File) && file0.type.includes('video')) || (constants.isEmpty(file0.postimage_loc) === false && file0.postimage_loc.substr(file0.postimage_loc.length - 4) === '.mp4') ?
                            <>
                                <video className='preventClickPost' controls>
                                    <source className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} type="video/mp4" />
                                </video>
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                            </>
                        :
                            <>
                                <img className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} />
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                            </>
                        }
                    </div>
                    <div className='post_content_images_2'>
                        {((file1 instanceof File) && file1.type.includes('video')) || (constants.isEmpty(file1.postimage_loc) === false && file1.postimage_loc.substr(file1.postimage_loc.length - 4) === '.mp4') ?
                            <>
                                <video className='preventClickPost' controls>
                                    <source className='preventClickPost' src={(file1 instanceof File) ? URL.createObjectURL(file1) : constants.getFileLoc(this.options.post.post_account_id, file1.postimage_loc)} type="video/mp4" />
                                </video>
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file1)}><i className='fas fa-xmark' /></div>
                            </>
                        :
                            <>
                                <img className='preventClickPost' src={(file1 instanceof File) ? URL.createObjectURL(file1) : constants.getFileLoc(this.options.post.post_account_id, file1.postimage_loc)} />
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file1)}><i className='fas fa-xmark' /></div>
                            </>
                        }
                    </div>
                </div>
            );

        }
        else // RENDER 3 IMAGES/VIDEOS
        if (count === 3) {

            let files = [];

            for (const file of this.state.postImages) {
                files.push(file);
            }
            for (const file of this.state.files) {
                files.push(file);
            }

            const file0 = files[0];
            const file1 = files[1];
            const file2 = files[2];

            return (
                <div className='post_content_images createPost'>
                    <div className='post_content_images_3_1'>
                        {((file0 instanceof File) && file0.type.includes('video')) || (constants.isEmpty(file0.postimage_loc) === false && file0.postimage_loc.substr(file0.postimage_loc.length - 4) === '.mp4') ?
                            <>
                                <video className='preventClickPost' controls>
                                    <source className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} type="video/mp4" />
                                </video>
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                            </>
                        :
                            <>
                                <img className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} />
                                <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                            </>
                        }
                    </div>
                    <div className='post_content_images_3_2'>
                        <div className='post_content_images_3_2_container'>
                            {((file1 instanceof File) && file1.type.includes('video')) || (constants.isEmpty(file1.postimage_loc) === false && file1.postimage_loc.substr(file1.postimage_loc.length - 4) === '.mp4') ?
                                <>
                                    <video className='preventClickPost' controls>
                                        <source className='preventClickPost' src={(file1 instanceof File) ? URL.createObjectURL(file1) : constants.getFileLoc(this.options.post.post_account_id, file1.postimage_loc)} type="video/mp4" />
                                    </video>
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file1)}><i className='fas fa-xmark' /></div>
                                </>
                            :
                                <>
                                    <img className='preventClickPost' src={(file1 instanceof File) ? URL.createObjectURL(file1) : constants.getFileLoc(this.options.post.post_account_id, file1.postimage_loc)} />
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file1)}><i className='fas fa-xmark' /></div>
                                </>
                            }
                        </div>
                        <div className='post_content_images_3_2_container'>
                            {((file2 instanceof File) && file2.type.includes('video')) || (constants.isEmpty(file2.postimage_loc) === false && file2.postimage_loc.substr(file2.postimage_loc.length - 4) === '.mp4') ?
                                <>
                                    <video className='preventClickPost' controls>
                                        <source className='preventClickPost' src={(file2 instanceof File) ? URL.createObjectURL(file2) : constants.getFileLoc(this.options.post.post_account_id, file2.postimage_loc)} type="video/mp4" />
                                    </video>
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file2)}><i className='fas fa-xmark' /></div>
                                </>
                            :
                                <>
                                    <img className='preventClickPost' src={(file2 instanceof File) ? URL.createObjectURL(file2) : constants.getFileLoc(this.options.post.post_account_id, file2.postimage_loc)} />
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file2)}><i className='fas fa-xmark' /></div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            );

        }
        else // RENDER 4 IMAGES/VIDEOS
        if (count === 4) {

            let files = [];

            for (const file of this.state.postImages) {
                files.push(file);
            }
            for (const file of this.state.files) {
                files.push(file);
            }

            const file0 = files[0];
            const file1 = files[1];
            const file2 = files[2];
            const file3 = files[3];

            return (
                <div className='post_content_images createPost'>
                    <div className='post_content_images_3_2'>
                        <div className='post_content_images_3_2_container'>
                            {((file0 instanceof File) && file0.type.includes('video')) || (constants.isEmpty(file0.postimage_loc) === false && file0.postimage_loc.substr(file0.postimage_loc.length - 4) === '.mp4') ?
                                <>
                                    <video className='preventClickPost' controls>
                                        <source className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} type="video/mp4" />
                                    </video>
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                                </>
                            :
                                <>
                                    <img className='preventClickPost' src={(file0 instanceof File) ? URL.createObjectURL(file0) : constants.getFileLoc(this.options.post.post_account_id, file0.postimage_loc)} />
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file0)}><i className='fas fa-xmark' /></div>
                                </>
                            }
                        </div>
                        <div className='post_content_images_3_2_container'>
                            {((file1 instanceof File) && file1.type.includes('video')) || (constants.isEmpty(file1.postimage_loc) === false && file1.postimage_loc.substr(file1.postimage_loc.length - 4) === '.mp4') ?
                                <>
                                    <video className='preventClickPost' controls>
                                        <source className='preventClickPost' src={(file1 instanceof File) ? URL.createObjectURL(file1) : constants.getFileLoc(this.options.post.post_account_id, file1.postimage_loc)} type="video/mp4" />
                                    </video>
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file1)}><i className='fas fa-xmark' /></div>
                                </>
                            :
                                <>
                                    <img className='preventClickPost' src={(file1 instanceof File) ? URL.createObjectURL(file1) : constants.getFileLoc(this.options.post.post_account_id, file1.postimage_loc)} />
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file1)}><i className='fas fa-xmark' /></div>
                                </>
                            }
                        </div>
                    </div>
                    <div className='post_content_images_3_2'>
                        <div className='post_content_images_3_2_container'>
                            {((file2 instanceof File) && file2.type.includes('video')) || (constants.isEmpty(file2.postimage_loc) === false && file2.postimage_loc.substr(file2.postimage_loc.length - 4) === '.mp4') ?
                                <>
                                    <video className='preventClickPost' controls>
                                        <source className='preventClickPost' src={(file2 instanceof File) ? URL.createObjectURL(file2) : constants.getFileLoc(this.options.post.post_account_id, file2.postimage_loc)} type="video/mp4" />
                                    </video>
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file2)}><i className='fas fa-xmark' /></div>
                                </>
                            :
                                <>
                                    <img className='preventClickPost' src={(file2 instanceof File) ? URL.createObjectURL(file2) : constants.getFileLoc(this.options.post.post_account_id, file2.postimage_loc)} />
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file2)}><i className='fas fa-xmark' /></div>
                                </>
                            }
                        </div>
                        <div className='post_content_images_3_2_container'>
                            {((file3 instanceof File) && file3.type.includes('video')) || (constants.isEmpty(file3.postimage_loc) === false && file3.postimage_loc.substr(file3.postimage_loc.length - 4) === '.mp4') ?
                                <>
                                    <video className='preventClickPost' controls>
                                        <source className='preventClickPost' src={(file3 instanceof File) ? URL.createObjectURL(file3) : constants.getFileLoc(this.options.post.post_account_id, file3.postimage_loc)} type="video/mp4" />
                                    </video>
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file3)}><i className='fas fa-xmark' /></div>
                                </>
                            :
                                <>
                                    <img className='preventClickPost' src={(file3 instanceof File) ? URL.createObjectURL(file3) : constants.getFileLoc(this.options.post.post_account_id, file3.postimage_loc)} />
                                    <div className='w3s_info_button_transparent_remove' onClick={() => this.removeFromPictures(0, file3)}><i className='fas fa-xmark' /></div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            );

        }

    }

    // renderImages() {

    //     let toRender = [];

    //     for (const file of this.state.files) {

    //         toRender.push(
    //             <div className='post_image_item'>

    //                 <img src={URL.createObjectURL(file)} />

    //             </div>
    //         );

    //     }

    //     return toRender;

    // }

    dragdropMouseEnter(e) {
        e.preventDefault();
        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag.classList.contains('dark') === true) {
            e.currentTarget.style.backgroundColor = 'rgb(82 82 91)';
        } else {
            e.currentTarget.style.backgroundColor = 'rgb(243 244 246)';
        }
    }

    dragdropMouseLeave(e) {
        e.preventDefault();
        if (e.target.classList.contains('dragbox')) {
            return;
        }

        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag.classList.contains('dark') === true) {
            e.currentTarget.style.backgroundColor = 'rgb(63 63 70)';
        } else {
            e.currentTarget.style.backgroundColor = 'rgb(249 250 251)';
        }

    }

    onClickDragDrop(e) {
        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag.classList.contains('dark') === true) {
            e.currentTarget.style.backgroundColor = 'rgb(63 63 70)';
        } else {
            e.currentTarget.style.backgroundColor = 'rgb(249 250 251)';
        }
    }

    dropFiles(e) {
        e.preventDefault();

        if (e.dataTransfer.files.length + this.state.files.length + this.state.postImages.length > 4) {
            toast.error(`You can only upload a maximum of 4 photos/videos`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        let files = Array.from(this.state.files);
        for (const file of Array.from(e.dataTransfer.files)) {
            files.push(file);
        }

        this.setState({ files: files, showFileDrop: false });
    }

    onChangeImageUpload(e) {

        if (e.target.files.length + this.state.files.length + this.state.postImages.length > 4) {
            toast.error(`You can only upload a maximum of 4 photos/videos`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        let biggerFiles = false;
        let files = Array.from(this.state.files);
        for (const file of Array.from(e.target.files)) {
            if (file.size > 25000000) { // higher than 25mb
                biggerFiles = true;
                continue;
            }
            files.push(file);
        }

        // show error message bigger than 25mb
        if (biggerFiles === true) {
            toast.error(`One or more files are bigger than 25mb`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
        }

        this.setState({ files: files, showFileDrop: false });
    }

    toggleFileDrop() {

        if (this.state.showFileDrop === true) {
            this.setState({ showFileDrop: false, files: [] });
        } else if (this.state.files.length === 0) {
            this.setState({ showFileDrop: true});
        } else {

        }

    }

    selectGIF(GIF) {
        this.setState({ GIF: GIF.id });
    }

    removeGIF() {
        this.setState({ GIF: null });
    }

    openGIFSModal() {

        constants.showSubModal(this.parent, <ModalContent_GIF parent={this.parent} options={{ selectGIF: this.selectGIF.bind(this) }} />);

    }

    openEmojis() {

        this.setState({ emojisOpen: !this.state.emojisOpen });

    }

    openMentionContainer() {

        const mentionBox = document.getElementById('mentionBox');
        mentionBox.style.display = 'flex';
        this.getMentioned('');

    }

    async getMentioned(mention) {

        let mentionsList = [];

        this.setState({ mentionsList: [] });

        mention = mention.substring(1);

        const users = await APIGETMENTIONS_NAME.Request(mention);

        if (users.length === 0) {
            mentionsList = null;
        } else {
            mentionsList = users;
        }

        this.setState({ mentionsList: mentionsList });

    } 

    renderMentionedUsers() {

        let toRender = [];

        for (const user of this.state.mentionsList) {

            const hasNFT = constants.isEmpty(user.nftimage_id) === false && constants.isTrue(user.nftimage_nft_verified);

            toRender.push(

                <div className='preventMention post_content_header mention_row' onMouseDown={() => { const myField = this.postArea; if (myField) { this.cursorPos = myField.selectionStart; } }} onClick={() => this.insertMention(user)}>

                    <div className='post_content_header_1'>
                        {hasNFT ?
                            <div className={`preventMention relative`} style={{  }}>
                                <div className="preventMention animated-border-box-glow2"></div>
                                <div className="preventMention animated-border-box2">
                                    <img className='preventMention' src={constants.getFileLoc(user.info_id, user.info_profilepicture)} alt="" />
                                </div>
                            </div>
                        :
                            <div className={`preventMention nav_profile_button_profile post_profile`} style={{  }}>
                                {constants.isEmpty(user.info_profilepicture) === false ?
                                    <img className='preventMention' src={constants.getFileLoc(user.info_id, user.info_profilepicture)} alt="" />
                                :
                                    <i className='preventMention far fa-user' />
                                }
                            </div>
                        }
                    </div>

                    <div className='post_content_header_2'>
                        <div className='preventMention flex items-center'>
                            <div className='preventMention font-medium'>{constants.getUserFullName(user, true)}</div>
                            {/* {constants.hasRank(user, config.rank_mod) ?
                                <img src={icon_v_gold} className='ms-1 rank_badge_post' />
                            : null} */}
                        </div>
                        <div className='preventMention text-muted-500 text-sm'>@{user.account_username}</div>
                    </div>

                </div>

            );

        }

        return toRender;

    }

    insertMention(user) {

        constants.closeAllDropdowns(null, true);

        const myField = this.postArea;
        let myValue = '@' + user.account_username;

        const currentMention = constants.isEmpty(this.state.currentMention) ? '' : this.state.currentMention;

        if (myField) {

            const stringUntilEdittedMention = myField.value.substring(0, this.cursorPos);
            const lastWordStringUntilMention = stringUntilEdittedMention.split(" ").slice(-1);
            const lastWordIsMention = (/\B@+/).test(lastWordStringUntilMention);

            if (constants.isEmpty(currentMention) === false) {
                myField.value = myField.value.toLowerCase().replace(new RegExp("\\"+currentMention+"\\b"), '');
            }

            let toCutOff = currentMention.length;

            if (lastWordIsMention && lastWordStringUntilMention[0].length < currentMention.length) {
                toCutOff = lastWordStringUntilMention[0].length;
            }

            if (myField.selectionStart || myField.selectionStart == '0') {
                var startPos = myField.selectionStart;
                var endPos = myField.selectionEnd;
                myField.value = myField.value.substring(0, this.cursorPos - toCutOff)
                    + myValue
                    + myField.value.substring(this.cursorPos - toCutOff, myField.value.length);
                myField.selectionStart = this.cursorPos - toCutOff + myValue.length;
                myField.selectionEnd = this.cursorPos - toCutOff + myValue.length;
            } else {
                myField.value += myValue;
            }

            if (constants.isEmpty(myField.value.charAt(myField.selectionStart + 1))) {
                myField.value += ' ';
            }

            // set new mentions
            const mentions = myField.value.match(/@(\w+)/g);
            this.mentions = mentions;

            this.setState({ currentMention: null, text: myField.value }, () => {
                if (myField) {
                    myField.focus();
                }
            });

        }

    }

    setPrivacy(privacy) {
        constants.closeAllDropdowns(null, true);
        this.setState({ privacy: privacy });
    }

    setText(e) {

        const text = e.target.value;
 
        if (500 - text.length <= 0) {
            document.getElementById('post_charsLeft').style.color = 'rgb(220, 38, 38)';
        } else if (500 - text.length <= 50) {
            document.getElementById('post_charsLeft').style.color = 'rgb(249, 115, 22)';
        } else if (500 - text.length <= 100) {
            document.getElementById('post_charsLeft').style.color = 'rgb(234 179 8)';
        } else if (500 - text.length > 100) {
            document.getElementById('post_charsLeft').style.color = 'inherit';
        }

        // mentions
        const mentions = text.match(/@(\w+)/g);

        let mention = null;

        if (constants.isEmpty(mentions) === false && mentions.length > 0) {

            if (constants.isEmpty(this.mentions) === false && this.mentions.length > 0) {

                for (const i in mentions) {
                    if (constants.isEmpty(this.mentions[i]) === true || mentions[i] !== this.mentions[i]) {
                        mention = mentions[i];
                        break;
                    }
                }

            } else {
                mention = mentions[0];
            }

        }

        // save new mentions
        this.mentions = mentions;

        // const lastMention = constants.isEmpty(mentions) === false && mentions.length > 0 ? mentions[mentions.length - 1] : null;

        const lastWord = text.split(" ").slice(-1);
        const lastWordIsMention = (/\B@\w+/).test(lastWord[0]);

        const mentionBox = document.getElementById('mentionBox');

        if (constants.isEmpty(mention) === false && mention.length > 1) {
            mentionBox.style.display = 'flex';
        } else if ((constants.isEmpty(mention) === true || mention.length === 0 || lastWordIsMention === false) && mentionBox.style.display === 'flex') {
            mentionBox.style.display = 'none';
        }

        if (constants.isEmpty(mention) === false && mention.length > 1 && mentionBox.style.display === 'flex') {
            this.getMentioned(mention);
            this.closeStuff(null, true);
        }

        this.setState({ text: text, currentMention: mention });

        this.isTyping = true;
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.isTyping = false;
        }, 400);

    }

    insertEmoji(emoji, e) {

        // this.closeStuff(null, true);

        const myField = this.postArea;
        let myValue = emoji.emoji;

        if (myField) {

            if (myField.selectionStart || myField.selectionStart == '0') {
                var startPos = myField.selectionStart;
                var endPos = myField.selectionEnd;
                myField.value = myField.value.substring(0, startPos)
                    + myValue
                    + myField.value.substring(endPos, myField.value.length);
                myField.selectionStart = startPos + myValue.length;
                myField.selectionEnd = endPos + myValue.length;
            } else {
                myField.value += myValue;
            }

            this.setState({ currentMention: null, text: myField.value }, () => {
                if (myField) {
                    myField.focus();
                }
            });

        }

    }

    closeStuff(e, force = false) {

        if (!force && (e.target?.className?.includes?.('epr-') || e.target?.className?.includes?.('epr_'))) {
            return;
        }

        this.setState({ emojisOpen: false });

    }

    close() {

        // RESET
        if (constants.isEmpty(this.postArea) === false) {
            this.postArea.value = '';
        }

        this.mentions = [];

        this.setState({ 
            currentMention: null,
            mentionsList: [],
            showFileDrop: false,
            files: [],
            postImages: [],
            postImagesDelete: [],
            GIF: null,
            text: '',
            emojisOpen: false,
        });

        config.modalContent = null;
        config.modalSize = 'normal';

        // CLOSE
        const modal = document.getElementById('w3s_modal');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        // this.parent.forceUpdate();

    }

    render() {

        return (
            <div style={{ width:'100%', height:'100%' }} onMouseDown={(e) => this.closeStuff(e)}>

                {this.state.loading === true ?

                    // LOADER
                    <div className='w3s_loader_modal bg-transparent'>
                        <div className="w3s_spinner">
                            <div className="w3s_double-bounce1"></div>
                            <div className="w3s_double-bounce2"></div>
                        </div>
                    </div>

                :

                    <div style={{ width:'100%', height:'100%' }}>

                        <div className='modal_header'>
                            <h2 className='text-xl'>Create post</h2>
                            <div className='modal_close' onClick={() => this.close()}><i className='fas fa-xmark' /></div>
                        </div>

                        <div className='w3s_divider' />

                        <div className='modal_content'>

                            {/* NAME */}
                            <div className='modal_post_name_row'>

                                <div className='modal_post_name_row_1'>
                                    <div className='nav_profile_button_profile' style={{  }}>
                                        {constants.isEmpty(config.accountData.info_profilepicture) === false ?
                                            <img className='' src={constants.getFileLoc(config.accountData.info_id, config.accountData.info_profilepicture)} alt="" />
                                        :
                                            <i className='far fa-user' />
                                        }
                                    </div>
                                </div>

                                <div className='modal_post_name_row_2'>
                                    <span className='font-medium'>{constants.getUserFullName()}</span>
                                </div>

                                <div className='modal_post_name_row_3'>

                                    <div className='w3s_label2_normal' onClick={() => constants.showDropdown(
                                        this.props.parent, 
                                        window.innerWidth <= 576,
                                        {
                                            title: 'Who can reply?',
                                            buttons: [
                                                { text: 'Everyone', icon: <i className='fas fa-earth-americas' />, type: null, selected: null, action: this.setPrivacy.bind(this, config.privacy_everyone), show: true, disabled: false },
                                                { text: 'Users you follow', icon: <i className='fas fa-user-check' />, type: null, selected: null, action: this.setPrivacy.bind(this, config.privacy_usersFollow), show: true, disabled: false },
                                                { text: 'Verified users', icon: <i className='fas fa-badge-check' />, type: null, selected: null, action: this.setPrivacy.bind(this, config.privacy_verified), show: true, disabled: false },
                                                { text: 'Users you mention', icon: <i className='fas fa-at' />, type: null, selected: null, action: this.setPrivacy.bind(this, config.privacy_mentioned), show: true, disabled: false },
                                            ],
                                        },
                                        `dropdown_privacy`)
                                    }>
                                        {this.state.privacy === config.privacy_usersFollow ?
                                            <span>Reply: <i className='far fa-user-check' /></span>
                                        :
                                        this.state.privacy === config.privacy_verified ?
                                            <span>Reply: <i className='far fa-badge-check' /></span>
                                        :
                                        this.state.privacy === config.privacy_mentioned ?
                                            <span>Reply: <i className='far fa-at' /></span>
                                        :
                                            <span>Reply: <i className='far fa-earth-americas' /></span>
                                        }
                                    </div>

                                    <div className='w3s_nav_dropdown post_aa bg-white dark:bg-slate-900' id={`dropdown_privacy`}>
                                        <ul className=''>
                                            <li className='dropdown_li' onClick={() => this.setPrivacy(config.privacy_everyone)}><i className='fas fa-earth-americas me-2' />Everyone</li>
                                            <li className='dropdown_li mt-1' onClick={() => this.setPrivacy(config.privacy_usersFollow)}><i className='fas fa-user-check me-2' />Users you follow</li>
                                            <li className='dropdown_li mt-1' onClick={() => this.setPrivacy(config.privacy_verified)}><i className='fas fa-badge-check me-2' />Verified users</li>
                                            <li className='dropdown_li mt-1' onClick={() => this.setPrivacy(config.privacy_mentioned)}><i className='fas fa-at me-2' />Users you mention</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>

                            {/* POST AREA */}
                            <div className='modal_content_post mt-4'>

                                <textarea
                                    ref={(ref) => this.postArea=ref}
                                    onInput={(e) => this.resizeTextArea(e)}
                                    onKeyDown={(e) => this.resizeTextArea_keyDown(e)}
                                    onChange={(e) => this.setText(e)}
                                    name="createpost" 
                                    id="createpost" 
                                    className="create_post_inputfield bg-transparent outline-none border-transparent" 
                                    placeholder={`What's on your mind, ${config.accountData.account_username}?`}
                                />

                                {/* GIF */}
                                {constants.isEmpty(this.state.GIF) === false ?
                                    <div style={{ marginTop: '0.5rem', position: 'relative' }}>
                                        <img src={`https://i.giphy.com/media/${this.state.GIF}/giphy.gif`} className='giphyGIF' />
                                        <div className='w3s_info_button_transparent_remove' onClick={() => this.removeGIF()}><i className='fas fa-xmark' /></div>
                                    </div>
                                : null}

                                {/* IMAGES VIDEOS */}
                                {this.state.files.length > 0 || this.state.postImages.length > 0 ?
                                    // <div style={{ paddingBottom: 25 }}>
                                    //     <Flickity
                                    //         className={'modal_content_post_images'} // default ''
                                    //         elementType={'div'} // default 'div'
                                    //         options={{
                                    //             draggable: this.state.files.length > 1 ? true : false,
                                    //             prevNextButtons: false,
                                    //             pageDots: this.state.files.length > 1 ? true : false,
                                    //         }} // takes flickity options {}
                                    //         disableImagesLoaded={false} // default false
                                    //         reloadOnUpdate={true} // default false
                                    //         static={false} // default false
                                    //     >
                                    //         {this.renderImages()}
                                    //     </Flickity>
                                    // </div>
                                    this.renderImagesAndVideos()
                                : null}

                                {/* FILE DROP */}
                                {this.state.showFileDrop === true ?
                                    <div className='modal_content_dragdrop'>
                                        <label className='modal_content_dragdrop_content' htmlFor='post_images_upload' onClick={(e) => this.onClickDragDrop(e)} onMouseEnter={(e) => this.dragdropMouseEnter(e)} onMouseLeave={(e) => this.dragdropMouseLeave(e)} onDragOver={(e) => this.dragdropMouseEnter(e)} onDragLeave={(e) => this.dragdropMouseLeave(e)} onDrop={(e) => this.dropFiles(e)}>
                                            <span className='dragbox text-lg font-semibold'>Add photos/videos</span>
                                            <span className='dragbox text-xs text-muted-600'>or drag and drop it here</span>
                                            <span style={{ position: 'absolute', bottom: 20 }} className='dragbox text-xxs text-muted-600'>max 25mb</span>
                                        </label>
                                        <input id='post_images_upload' type="file" multiple={true} onChange={(e) => this.onChangeImageUpload(e)} accept={'.jpg,.png,.gif,.jpeg,video/mp4,video/x-m4v,video/quicktime,video/*'} />
                                    </div>
                                : null}

                            </div>

                            <div id='post_charsLeft' className='flex justify-end'>
                                <span className='font-semibold'>{500 - this.state.text.length}</span>
                            </div>

                            {/* MENTIONS */}
                            <div className='mention_container_wrapper preventMention'>

                                <div id='mentionBox' className='mention_container preventMention'>

                                    {this.state.mentionsList === null ?
                                        <div className='preventMention flex flex-col items-center justify-center w-full' style={{ padding: '10px 10px' }}>

                                            <div style={{ fontSize: 24 }} className='preventMention text-muted-500'><i className='preventMention far fa-user-times' /></div>
                                            <div className='preventMention mt-1 text-muted-500 text-center'>No followers or following users found</div>

                                        </div>
                                    : this.state.mentionsList.length === 0 ?
                                        <div className='preventMention w3s_loader_mentions bg-transparent'>
                                            <div className="preventMention w3s_spinner">
                                                <div className="preventMention w3s_double-bounce1"></div>
                                                <div className="preventMention w3s_double-bounce2"></div>
                                            </div>
                                        </div>
                                    :
                                        <div className='preventMention w-full'>{this.renderMentionedUsers()}</div>
                                    }

                                </div>

                                {this.state.emojisOpen ?
                                    <EmojiPicker 
                                        theme={document.documentElement.className.includes("dark") ? 'dark' : 'light'}
                                        className='emojipicker_container'
                                        onEmojiClick={(emoji, e) => this.insertEmoji(emoji, e)}
                                        width={'100%'}
                                        height={300}
                                        previewConfig={{ showPreview: false }}
                                    />
                                : null}

                            </div>

                            {/* ADD TO POST */}
                            <div className='modal_content_add mt-2'>

                                <div className='text-sm'>Add to post</div>

                                <div className='modal_content_add_tools'>
                                    {this.state.files.length > 0 || this.state.postImages.length > 0 ?
                                        <>
                                            <label className='modal_content_add_tools_button' htmlFor='post_images_upload2'>
                                                <i className='fas fa-photo-film text-emerald-500' />
                                            </label>
                                            <input id='post_images_upload2' type="file" multiple={true} onChange={(e) => this.onChangeImageUpload(e)} accept={'.jpg,.png,.gif,.jpeg,video/mp4,video/x-m4v,video/quicktime,video/*'} />
                                        </>
                                    : 
                                        <div className='modal_content_add_tools_button' onClick={() => this.toggleFileDrop()}>
                                            <i className='fas fa-photo-film text-emerald-500' />
                                        </div>
                                    }
                                    <div className='modal_content_add_tools_button' onClick={() => this.openGIFSModal()}>
                                        <i className='fas fa-gif text-fuchsia-500' />
                                    </div>
                                    <div className='modal_content_add_tools_button' onClick={() => this.openEmojis()}>
                                        <i className='fas fa-face-laugh-beam text-yellow-500' />
                                    </div>
                                    <div className='modal_content_add_tools_button' onClick={() => this.openMentionContainer()}>
                                        <i className='fas fa-user text-blue-500' />
                                    </div>
                                    {/* <div className='modal_content_add_tools_button w3s_disabled'>
                                        <i className='fas fa-location-dot text-red-500' />
                                    </div> */}
                                </div>

                            </div>

                            {/* POST */}
                            {constants.isTrue(config.accountData.account_web3) ?
                                null
                            : constants.isEmpty(this.options.post) === true ?
                                <div className={`w3s_info_button_full mt-5 ${this.state.text.length === 0 && constants.isEmpty(this.state.GIF) ? 'w3s_disabled' : ''}`} onClick={() => this.post()}><i className='fas fa-paper-plane me-2' />Post</div>
                            :
                                <div className={`w3s_info_button_full mt-5 ${this.state.text.length === 0 && constants.isEmpty(this.state.GIF) ? 'w3s_disabled' : ''}`} onClick={() => this.edit()}><i className='fas fa-edit me-2' />Edit</div>
                            }

                        </div>

                    </div>

                }

            </div>
        );
    }
  
}

export default withRouter(ModalContent_CreatePost);
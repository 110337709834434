import bull_birthday from '../assets/images/torogram/bull_birthday.png';
import bull_paint from '../assets/images/torogram/bull_paint.png';
import bull_phone from '../assets/images/torogram/bull_phone.png';
import bull_gaming from '../assets/images/torogram/bull_gaming.png';
import bull_king from '../assets/images/torogram/bull_king.png';

const updates = [
    {
        title: 'Torogram version 0.4.3',
        date: new Date(2024, 4, 9, 12, 39), // year, month - 1, day, hour, minute
        text: `We're excited to enhance your Torogram experience with new updates!\n\nNow, Torogram features push notifications for chats, ensuring you never miss a message.\n\nAdditionally, you can easily check notifications directly from the navigation menu, and we've introduced badges for notifications and chats, keeping you informed of new activity at a glance.\n\nThese updates are designed to make your interactions on Torogram smoother and more responsive. Enjoy staying connected with greater ease!`,
        list: [
            'Push notification chats',
            'Notification checks navigation bar',
            'Badges for notifications and chats',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.4.2',
        date: new Date(2024, 4, 8, 20, 31), // year, month - 1, day, hour, minute
        text: `We're pleased to announce that chatting on both the Torogram app and website is now fully operational.\n\nUsers can enjoy real-time conversations, seamlessly connecting centralized and decentralized users in one platform. Whether you're catching up with friends or collaborating on projects, Torogram's integrated chat system ensures you can communicate effectively and instantly.\n\nEnjoy chatting live and make the most of your interactions on Torogram!`,
        list: [
            'Start new chats',
            'Chat with decentralized accounts',
            'Chat with centralized accounts',
            'Real-time chatting',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.4.1',
        date: new Date(2024, 3, 23, 20, 15), // year, month - 1, day, hour, minute
        text: `Great news for Torogram users! We've successfully implemented push notifications for our mobile apps, enhancing your connectivity and engagement.\n\nYou now have the flexibility to choose which notifications you want to receive, including updates for following, mentions, chats, and interactions like likes, comments, and reposts on both your posts and replies.\n\nThis personalized approach puts you in control, allowing you to tailor your Torogram experience to suit your preferences. Stay connected and never miss an important update again!`,
        list: [
            'Push notifications app',
            'Push notifications permissions',
            'Push notifications settings',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.4.0',
        date: new Date(2024, 3, 18, 18, 30), // year, month - 1, day, hour, minute
        text: `Torogram now gives you more control over your experience! You can hide posts you don't want to see from your timeline, ensuring your feed stays relevant and enjoyable. Additionally, if you encounter any issues with other users, you have the option to block them.\n\nFor those seeking even more privacy or wishing to step away, you can permanently delete your account if it's set up with an email and password. For decentralized accounts accessed through a wallet, you have the option to nullify your account, maintaining your privacy and security on your terms.`,
        list: [
            'Fresh design',
            'Web3 HUB',
            'AI Tax Calculator',
            'Hide posts',
            'Block users',
            'Delete/nullify your own account',
        ],
        img: bull_king,
    },
    {
        title: 'Torogram version 0.3.0',
        date: new Date(2024, 1, 5, 16, 37), // year, month - 1, day, hour, minute
        text: `Torogram proudly introduces decentralized accounts, blending the best of centralized and decentralized worlds. When you create an account, it's stored in your wallet and minted as an NFT. Logging in retrieves your account's metadata from the NFT, and any updates to your account refresh this metadata URI. Our custom verification process ensures you're the true owner of the NFT and, consequently, the account.\n\nEven posting on Torogram creates an NFT, with post details pulled from the metadata attributes. You're in complete control of your data.\n\nTorogram stands as the first platform to seamlessly integrate centralized and decentralized accounts, marking a milestone in social media innovation.`,
        list: [
            'Custom relayer that pays for gas fees',
            'Decentralised operations',
            'Decentralised storage',
            'Account NFT',
            'Post NFT',
            'Combined decentralised and centralised platform',
            'Own your own data',
        ],
        img: bull_king,
    },
    {
        title: 'Torogram version 0.2.01',
        date: new Date(2024, 1, 5, 16, 37), // year, month - 1, day, hour, minute
        text: `Think we'd take a break after a hard-working week? Absolutely not! Torogram thrives, regardless of market conditions.\n\nWe're excited to announce new enhancements: you can now add emojis directly into your posts and replies, making your interactions more expressive. Our thread functionality has been upgraded, allowing you to view an entire thread – the main post and all replies – seamlessly. Plus, we've improved notifications, enabling you to see the content of your notifications at a glance.\n\nTorogram keeps pushing forward, enhancing your experience day by day!`,
        list: [
            'Phase 2 finished',
            'Emoji keyboard',
            'Improved threads layout',
            'Improved notifications content',
        ],
        img: bull_birthday,
    },
    {
        title: 'Torogram version 0.2.0',
        date: new Date(2024, 1, 4, 23, 52), // year, month - 1, day, hour, minute
        text: `We're excited to announce a significant achievement: after two productive months, we've completed Phase 2 of our roadmap! This milestone means Torogram's social platform basics are fully operational and ready for you to explore. Our latest update brings enhanced privacy settings for posts, simplified account adjustments like changing usernames and passwords, and smooth, reload-free page updates. Discover new trending posts, users, and hashtags on our added explore page, and enjoy the ease of searching for users, hashtags, and posts. Plus, stay connected with in-account notifications and email alerts – with the option to toggle them on or off.\n\nBut that's not all! We're now embarking on Phase 3, a major leap for Torogram, as we expand into SocialFi, De-Fi, and TradFi narratives. Expect more thrilling updates and a few surprises as we continue to evolve.\n\nStay tuned for more information and announcements next week. We're just getting started, and the future looks BIG!`,
        list: [
            'Phase 2 finished',
            'All basics of social platform',
            'Set privacy in posts',
            'Change username/password/email',
            'Explore page',
            'Search users/hashtags/posts',
            'Trending posts/hashtags',
            'Notifications',
            'Email notifications',
        ],
        img: bull_birthday,
    },
    {
        title: 'Torogram version 0.1.36',
        date: new Date(2024, 0, 31, 22, 50), // year, month - 1, day, hour, minute
        text: `We're thrilled to introduce a new feature on Torogram: now you can track the number of views on your posts and replies! Views are counted as soon as your content appears on a screen, giving you insight into your audience's engagement.\n\nEven more exciting, we're on the brink of completing Phase 2 of our roadmap this week! Time to celebrate our progress and gear up for Phase 3, starting next week.\n\nA new chapter awaits, full of promising updates and features. Stay with us as we continue to grow and evolve!`,
        list: [
            'Views reply and post',
            'View counter',
            'Mentioning users is smoother',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.1.35',
        date: new Date(2024, 0, 29, 22, 17), // year, month - 1, day, hour, minute
        text: `We're thrilled to share that Phase 2 of Torogram is progressing rapidly and bringing fantastic new capabilities!\n\nStarting today, you can enhance your posts with hashtags, making them more discoverable. Dive into the world of trending and recent hashtags – a feature we're actively tracking from now on.\n\nSoon, these hashtags will also be accessible on the mobile explore page for an enriched browsing experience.\n\nAdditionally, mentioning and linking to other Torogram users is now possible, fostering better connections within our community.`,
        list: [
            'Mentioning users',
            'Pop-up screen with followers and following',
            'Automatically insert usernames',
            'Username detection (@)',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.1.34',
        date: new Date(2024, 0, 26, 19, 45), // year, month - 1, day, hour, minute
        text: `We're thrilled to share that Phase 2 of Torogram is progressing rapidly and bringing fantastic new capabilities!\n\nStarting today, you can enhance your posts with hashtags, making them more discoverable. Dive into the world of trending and recent hashtags – a feature we're actively tracking from now on.\n\nSoon, these hashtags will also be accessible on the mobile explore page for an enriched browsing experience.\n\nAdditionally, mentioning and linking to other Torogram users is now possible, fostering better connections within our community.`,
        list: [
            'Use hashtags',
            'Hashtags have their own page',
            'Trending hashtags',
            'Recent hashtags',
            'Mention users',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.1.32',
        date: new Date(2024, 0, 22, 22, 0), // year, month - 1, day, hour, minute
        text: `Torogram now lets you easily bookmark your favorite posts and replies. Keep track of important content with just a click. Never lose sight of what matters to you on Torogram!`,
        list: [
            'Bookmarks page',
            'Bookmarks counter',
            'Bookmark posts',
            'Bookmark replies',
            'Bookmark threads',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.1.31',
        date: new Date(2024, 0, 20, 16, 8), // year, month - 1, day, hour, minute
        text: `You can now share Torogram posts and replies to different platforms! Each share increases the post's share count, boosting its visibility. Spread your favorite content easily and watch the conversations grow!`,
        list: [
            'Share to multiple platforms',
            'Share counter',
            'Share posts',
            'Share replies',
            'Share threads',
        ],
        img: bull_phone,
    },
    {
        title: 'Torogram version 0.1.3',
        date: new Date(2024, 0, 19, 21, 43), // year, month - 1, day, hour, minute
        text: `We're thrilled to announce a major upgrade on Torogram: now you can monitor your crypto portfolio directly within our platform! Say goodbye to using apps like Zerion or other wallets for tracking your digital assets. Our dedicated blockchain node ensures that Torogram supports a variety of custom tokens and provides up-to-date pricing. Plus, our portfolio feature is multichain, offering comprehensive coverage.
        \n\nThis addition is a significant step in enhancing Torogram's web3 features. Looking forward, we're excited to bring you more functionalities, including detailed charts, transaction histories, and insightful profit and loss analysis. Stay tuned for these revolutionary updates on Torogram!`,
        list: [
            'Multichain portfolio',
            'Add custom tokens',
            'Track current prices',
            'Token info',
            'Multi wallet support',
        ],
        img: bull_paint,
    },
    {
        title: 'Torogram version 0.1.2',
        date: new Date(2024, 0, 17, 2, 52), // year, month - 1, day, hour, minute
        text: `Great news from Torogram! Our latest update brings the ability to repost any post or reply, making it easier to share your favorite content. These reposts will appear in your timeline, where you and others can like and comment on them, adding to the vibrant community interaction.\n\nWe've also introduced a 'most popular' formula, which we can adjust at any time, allowing you to explore the most engaging posts.\n\nExperience smoother like animations and counters, adding a dynamic touch to your interactions.\n\nFor longer posts, a convenient 'show more' option appears after four lines, keeping your feed tidy while providing full insights.\n\nPlus, enjoy auto-focus on inputs for quicker posting and replying.\n\nDive into these updates for a more seamless and engaging Torogram experience!`,
        list: [
            'Reposting',
            'Like and comment on reposts',
            'Reposts show up on the time line',
            'Most popular formula',
            'Most popular posts view',
            'Smoother like animations',
            'Posts longer than 4 lines show "show more" button',
            'Auot focus input on creating posts and replies',
        ],
        img: bull_paint,
    },

    {
        title: 'Torogram version 0.1.0',
        date: new Date(2023, 11, 29, 2, 0), // year, month - 1, day, hour, minute
        text: `We've hit a major milestone – Phase 1 of Torogram's roadmap is now complete! Dive into a richer, more interactive experience: post, reply, and create engaging threads using GIPHY, pictures, and videos. Enjoy seamless browsing with our auto-compressed images and videos, ensuring faster load times. Now, the stage is set for you to connect, interact, and explore like never before on Torogram.`,
        list: [
            'Phase 1 roadmap completed',
            'Use GIPHYs',
            'Edit/delete replies',
            'Images/videos replies',
            'Like replies',
            'Threads',
            'Upload up to 4 images/videos',
            'Delete/edit images and videos',
            'Image viewer',
            'Load older posts',
        ],
        img: bull_birthday,
    },

    {
        title: 'Torogram version 0.0.82',
        date: new Date(2023, 11, 17, 18, 25), // year, month - 1, day, hour, minute
        text: `We're thrilled to share some fantastic updates that have just gone live on Torogram! We've given our website and mobile app a complete makeover, ensuring they're not only more visually appealing but also much easier to navigate. This means you'll find it smoother to browse, post, and connect, no matter what device you're using.\n\nMoreover, we've introduced a much-requested feature – the ability to reply directly to posts. This makes having conversations and engaging with others on the platform more seamless and interactive. Our focus remains on improving your experience, and we believe these enhancements will make your time on Torogram even more enjoyable.`,
        list: [
            'Better web design',
            'Better mobile design',
            'Reply to posts',
            'Interact with posts',
        ],
        img: bull_paint,
    },

    {
        title: 'Torogram version 0.0.7',
        date: new Date(2023, 11, 6, 0, 0), // year, month - 1, day, hour, minute
        text: `We're excited to introduce "Raffle," the latest addition to Torogram's gaming experience! This alpha version brings you a player vs player gamble game with a simple yet thrilling concept: a 50/50 chance to win your bet. As we roll out this initial release, please remember that we're still polishing the UI, and your feedback is crucial for our improvements. Plus, stay tuned for significant UI upgrades across Torogram coming soon.`,
        list: [
            'Connect your wallet',
            'Check your $TORO balance',
            'Create gambling rooms',
            'Join and play gambling rooms',
        ],
        img: bull_gaming,
    },

    {
        title: 'Torogram version 0.0.6',
        date: new Date(2023, 10, 28, 20, 0), // year, month - 1, day, hour, minute
        text: `We have introduced new features to enhance your experience on our platform. You can now easily edit and delete your posts for more control over your content. Additionally, we've implemented a convenient 'forgot password' feature, complete with an automated email system to help you quickly recover your account access`,
        list: [
            'Edit posts',
            'Delete posts',
            'Forgot password',
            'Automated mail token system',
        ],
        img: bull_phone,
    },

    {
        title: 'Torogram version 0.0.5',
        date: new Date(2023, 10, 4, 18, 0), // year, month - 1, day, hour, minute
        text: `Torogram now allows users to express themselves uniquely by liking posts with their favorite emojis. It's a fun and personalized way to connect with content and show appreciation, adding a touch of individuality to your social interactions`,
        list: [
            'Like mechanism',
            'Different emojis',
            'Like animation',
            'Double tap to like',
        ],
        img: bull_phone,
    },

    {
        title: 'Torogram version 0.0.4',
        date: new Date(2023, 9, 26, 17, 0), // year, month, day, hour, minute
        text: `We're thrilled to introduce several enhancements to your Torogram experience. Now, your Profile page has been revamped for a sleeker look. You can effortlessly update your profile info, including your profile picture and cover photo, with our user-friendly tools.\n\nBut that's not all! We've added an Image cropper and slider feature for picture-perfect posts. Create engaging content by creating posts and adding images with a convenient slider.`,
        list: [
            'Profile page',
            'Update profile info',
            'Update profile picture',
            'Update cover photo',
            'Image cropper and slider',
            'Create posts',
            'Add images to posts with a slider',
        ],
        img: bull_paint,
    },

    {
        title: 'Torogram version 0.0.3',
        date: new Date(2023, 9, 6, 19, 0), // year, month, day, hour, minute
        text: `Exciting changes are happening! Our info page has been given a makeover. We've also elevated your browsing experience with a sleek website design upgrade. For a more visual journey, we've added a handy image viewer. And speaking of journeys, our roadmap has been refreshed to provide a clearer roadmap of our evolving path ahead. Dive in and explore these enhancements, as we continue to enhance your experience and progress together toward our exciting future at Torogram.`,
        list: [
            'Updated info page (website)',
            'Website design upgrade',
            'Added image viewer',
            'Updated roadmap',
        ],
        img: bull_paint,
    },

    {
        title: 'Torogram version 0.0.2',
        date: new Date(2023, 9, 3, 20, 30),
        text: `Now you have the convenience of saving your preferred dark mode, enjoying lightning-fast page redirects to any section of our platform and making login a breeze with our 'remember me' button. We've made your experience smoother and more personalized!`,
        list: [
            'Store dark mode',
            'Fast redirecting',
            'Remember me button login',
        ],
        img: bull_birthday,
    },

    {
        title: 'Torogram got updated! 🎉',
        date: new Date(2023, 9, 2, 21),
        text: `Starting now, you'll notice that our platform is quite basic in its current form. However, we're committed to constant improvement. We aim to enhance the platform regularly with updates scheduled for release every Friday. Stay tuned, as we're also working on revising our white paper and updating our roadmap, which will be coming your way very soon!`,
        list: [],
        img: bull_birthday,
    },

];

export default (updates);
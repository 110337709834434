import React from 'react';
import Nav from '../../component/Navbar/nav';
// import Navbar from '../../component/Navbar/navbar';

import config from '../../data/config';
import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import updates from '../../data/updates';
import toast from 'react-hot-toast';


import hero from '../../assets/images/torogram/hero.png';
import robot from '../../assets/images/torogram/robot.png';
import robot2 from '../../assets/images/torogram/robot2.png';
import robot3 from '../../assets/images/torogram/robot3.png';
import robot4 from '../../assets/images/torogram/robot4.png';
import jordy from '../../assets/images/torogram/jordy_transparent.png';
import build from '../../assets/images/torogram/build.png';
import pano from '../../assets/images/torogram/pano.png';
import astrox from '../../assets/images/torogram/astrox.png';
import fbg from '../../assets/images/torogram/fbg.png';
import azee from '../../assets/images/torogram/azee.jpg';
import snt from '../../assets/images/torogram/snt.png';
import gfx1 from '../../assets/images/torogram/gfx_1.png';
import Loader from '../../component/Loader/loader';
import img_wallpaper from '../../assets/web3scape/img_wallpaper.jpg';
import ModalContent_CreatePost from '../../component/Modal/ModalContent_CreatePost';

import NavLanding from '../../component/Navbar/navLanding';

import logo from '../../assets/web3scape/web3scape_x1000.png';
import logo_s from '../../assets/web3scape/web3scape_icon.png';
import playnow from '../../assets/web3scape/playnow.png';
import about from '../../assets/web3scape/about.png';
import stones from '../../assets/web3scape/stones.png';
import { Link } from 'react-router-dom';
import Footer from '../../component/Footer/footer';
import ConnectWallet from '../../component/Decentralized/ConnectWallet';
import APIPostNewWallet from '../../api/XHunt/APIPostNewWallet';
import { ethers } from 'ethers';
import APIGetWalletsUser from '../../api/XHunt/APIGetWalletsUser';
import APIPayoutEth from '../../api/XHunt/APIPayoutEth';
import APIPayoutToken from '../../api/XHunt/APIPayoutToken';

class Payout extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        // this.lastLoaded = 0;

        this.lastScroll = 0;

        this.assetsToLoad = 0;

        const urlParams = new URLSearchParams(window.location.search);

        this.jwt = urlParams.get('jwt');
        this.userData = this.parseJwt(this.jwt);

        this.state = {

            loading: true,

            accountData: config.accountData,

            home_tab: constants.isEmpty(localStorage.getItem('home_tab')) || isNaN(parseInt(localStorage.getItem('home_tab'))) ? config.home_tab_global : parseInt(localStorage.getItem('home_tab')),
            home_sort: constants.isEmpty(localStorage.getItem('home_sort')) || isNaN(parseInt(localStorage.getItem('home_sort'))) ? config.home_sort_recent : parseInt(localStorage.getItem('home_sort')),

            postText: '',

            // postPage: 0,

            wallet: null,
            wallets: [],

        };

        this.handleScroll_function = this.handleScroll.bind(this);

        // this.handleScroll_function = this.handleScroll.bind(this);

    }

    async componentDidMount() {

        const wallets = await APIGetWalletsUser.Request(this.jwt);

        window.addEventListener('scroll', this.handleScroll_function);

        this.setState({ loading: false, wallets: constants.isEmpty(wallets.walletaddresses) === false ? wallets?.walletaddresses : [] });

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll_function);
    }

    loadedAsset() {
        this.assetsToLoad--;
        if (this.assetsToLoad <= 0) {
            this.setState({ loading: false }, () => {

                window.scrollTo({ top: 0, behavior: "instant" });

                this.handleScroll(null);

                const animatedDivsAlways = document.getElementsByClassName('w3s_always');

                for (const d of animatedDivsAlways) {

                    if (!d || d.classList.contains('show')) {
                        continue;
                    }
                    d.classList.add('show');

                }

            });
        }
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    selectWallet(e) {

        if (constants.isEmpty(e.target.value)) {
            return;
        }

        this.setState({ wallet: e.target.value });

    }

    async payoutToken() {

        if (constants.isEmpty(this.state.wallet)) {
            toast.error(`Please select a wallet first`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        this.setState({ loading: true });

        await APIPayoutToken.Request('0xab14f1262ea8d910756828cbe08e6cedfb66360d', '1', 10000, this.jwt, this.state.wallet);

        toast.success(`Payout successfull! Enjoy =)`, {
            position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
            duration: 10000,
        });

        this.setState({ loading: false });

    }

    async payoutEth() {

        if (constants.isEmpty(this.state.wallet)) {
            toast.error(`Please select a wallet first`, {
                position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
                duration: 5000,
            });
            return;
        }

        this.setState({ loading: true });

        await APIPayoutEth.Request('0.0001', this.jwt, this.state.wallet);

        toast.success(`Payout successfull! Enjoy =)`, {
            position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
            duration: 10000,
        });

        this.setState({ loading: false });

    }

    renderWallets() {

        let toReturn = [];

        for (const w of this.state.wallets) {
            toReturn.push(<option value={w.address}>{w.address}</option>);
        }

        return toReturn;

    }

    changeTab(tab) {

        if (this.state.home_tab === tab) {
            return;
        }

        if (tab !== config.home_tab_updates) {
            localStorage.setItem('home_tab', tab);
        }

        this.setState({ home_tab: tab });

    }

    changeSortTab(tab) {

        if (this.state.home_sort === tab) {
            return;
        }

        localStorage.setItem('home_sort', tab);

        this.setState({ home_sort: tab });

    }
 
    handleScroll(e) {

        const currentTime = new Date().getTime();

        if (currentTime - this.lastScroll < 10) {
            return;
        }

        this.lastScroll = currentTime;

        const offset = 200;

        const animatedDivs1 = document.getElementsByClassName('animateLeft');
        const animatedDivs2 = document.getElementsByClassName('animateRight');
        const animatedDivs3 = document.getElementsByClassName('animateIn');

        for (const d of animatedDivs1) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

        for (const d of animatedDivs2) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

        for (const d of animatedDivs3) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

    }

    render() {

        return (
            <div className='bg-gray-50 dark:bg-slate-950' onMouseDown={(e) => constants.closeAllDropdowns(e)}>

                {/* LOADER */}
                {this.state.loading ?
                    <Loader loading={this.state.loading} />
                : null}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
                    
                    <div className='dapp_box'>
                        <div className='text-xl font-bold'>X-Hunt Payout</div>
                        <div>Welcome {this.userData.username}</div>
                        <div className='text-sm font-semibold mt-5'>Choose a wallet</div>
                        <select name="wallets" id="wallets-select" onChange={(e) => this.selectWallet(e)}>
                            <option value='null'>--Please choose an option--</option>
                            {this.renderWallets()}
                        </select>

                        {constants.isEmpty(this.state.wallet) === false ?
                            <div className='w3s_info_button_green_full mt-2' onClick={() => this.payoutToken()}>Payout all found tokens</div>
                        : null}
                    </div>

                </div>

            </div>
        );
    }
  
}

export default withRouter(Payout);
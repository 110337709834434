import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';

class Alert extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.content = this.props.content;
        this.options = this.props.options;

        this.state = {

            loading: constants.isEmpty(this.props.loading) === false ? this.props.loading : true,

        };

    }

    async componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.content) && constants.isEmpty(this.props.content) === false) {
            this.content = this.props.content;
            this.forceUpdate();
        } else if (constants.isEmpty(this.content) === false && constants.isEmpty(this.props.content) === false && this.content !== this.props.content) {
            this.content = this.props.content;
            this.forceUpdate();
        }

        if (constants.isEmpty(this.options) && constants.isEmpty(this.props.options) === false) {
            this.options = this.props.options;
            this.forceUpdate();
        } else if (constants.isEmpty(this.options) === false && constants.isEmpty(this.props.options) === false && this.options !== this.props.options) {
            this.options = this.props.options;
            this.forceUpdate();
        }

        if (this.state.loading !== this.props.loading && prevState.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

    }

    closeOnClick(e) {

        if (e.target.id !== 'w3s_alert') {
            return;
        }

        const modal = document.getElementById('w3s_alert');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        this.forceUpdate();

    }

    close() {

        const modal = document.getElementById('w3s_alert');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        this.forceUpdate();

    }

    render() {

        return (
            <div id='w3s_alert' className='alert' onMouseDown={(e) => this.closeOnClick(e)}>

                <div className='modal_container bg-white dark:bg-slate-800'>

                    {this.state.loading === true ?

                        // LOADER
                        <div className='w3s_loader_modal bg-transparent'>
                            <div className="w3s_spinner">
                                <div className="w3s_double-bounce1"></div>
                                <div className="w3s_double-bounce2"></div>
                            </div>
                        </div>

                    :

                        <div style={{ width:'100%', height:'100%' }}>

                            <div className='modal_header'>
                                <h2 className='text-xl'>{this.content.title}</h2>
                                <div className='modal_close' onClick={() => this.close()}><i className='fas fa-xmark' /></div>
                            </div>

                            <div className='w3s_divider' />

                            <h2 className='mt-4 text-center'>{this.content.text}</h2>

                            <div className='w3s_divider mt-4' />

                            <div className='alert_bottom_bar mt-4'>
                                
                                <div className='w3s_info_button_gray_sm' onClick={() => this.close()}><i className='fas fa-ban me-2' />Cancel</div>
                                
                                {this.content.buttonText === 'REMOVE' ?
                                    <div className='w3s_info_button_red_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-xmark me-2' />Remove</div>
                                :
                                this.content.buttonText === 'ACCOUNTDELETE' ?
                                    constants.isTrue(config.accountData.account_web3) === false ?
                                        <div className='w3s_info_button_red_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-xmark me-2' />Delete</div>
                                    :
                                        null
                                :
                                this.content.buttonText === 'DELETE' ?
                                    <div className='w3s_info_button_red_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-xmark me-2' />Delete</div>
                                :
                                this.content.buttonText === 'LOGOUT' ?
                                    <div className='w3s_info_button_red_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-right-from-bracket me-2' />Logout</div>
                                :
                                this.content.buttonText === 'HIDE' ?
                                    <div className='w3s_info_button_red_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-eye-slash me-2' />Hide</div>
                                :
                                this.content.buttonText === 'BLOCK' ?
                                    <div className='w3s_info_button_red_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-ban me-2' />Block</div>
                                :
                                this.content.buttonText === 'CREATE' ?
                                    <div className='w3s_info_button_green_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-plus-square me-2' />Create</div>
                                :
                                this.content.buttonText === 'SAVE' ?
                                    <div className='w3s_info_button_green_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-check me-2' />Save</div>
                                :
                                this.content.buttonText === 'SET' ?
                                    <div className='w3s_info_button_green_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-check me-2' />Set</div>
                                :
                                    <div className='w3s_info_button_sm' onClick={() => { this.close(); this.content.action(); }}><i className='fas fa-arrow-right-to-line me-2' />Continue</div>
                                }


                            </div>

                        </div>

                    }

                </div>

            </div>
        );
    }
  
}

export default withRouter(Alert);
import React from 'react';
import Nav from '../../component/Navbar/nav';
// import Navbar from '../../component/Navbar/navbar';

import config from '../../data/config';
import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import updates from '../../data/updates';

import hero from '../../assets/images/torogram/hero.png';
import robot from '../../assets/images/torogram/robot.png';
import robot2 from '../../assets/images/torogram/robot2.png';
import robot3 from '../../assets/images/torogram/robot3.png';
import robot4 from '../../assets/images/torogram/robot4.png';
import jordy from '../../assets/images/torogram/jordy_transparent.png';
import build from '../../assets/images/torogram/build.png';
import pano from '../../assets/images/torogram/pano.png';
import astrox from '../../assets/images/torogram/astrox.png';
import fbg from '../../assets/images/torogram/fbg.png';
import azee from '../../assets/images/torogram/azee.jpg';
import snt from '../../assets/images/torogram/snt.png';
import gfx1 from '../../assets/images/torogram/gfx_1.png';
import Loader from '../../component/Loader/loader';
import img_wallpaper from '../../assets/web3scape/img_wallpaper.jpg';
import play_wallpaper from '../../assets/web3scape/play_wallpaper.jpg';
import playnow_text from '../../assets/web3scape/playnow_text2.png';
import wizard from '../../assets/web3scape/wizard.png';
import ModalContent_CreatePost from '../../component/Modal/ModalContent_CreatePost';

import NavLanding from '../../component/Navbar/navLanding';
import Switcher from '../../component/Switch';

import logo_dark from '../../assets/images/T_light.png';
import logo_light from '../../assets/images/T_dark.png';
import logo from '../../assets/web3scape/web3scape_x1000.png';
import playnow from '../../assets/web3scape/playnow.png';
import stones from '../../assets/web3scape/stones.png';
import logo_s from '../../assets/web3scape/web3scape_icon.png';
import { Link } from 'react-router-dom';
import Footer from '../../component/Footer/footer';

class Play extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        // this.lastLoaded = 0;

        this.lastScroll = 0;

        this.assetsToLoad = 2;

        this.state = {

            loading: true,

            accountData: config.accountData,

            home_tab: constants.isEmpty(localStorage.getItem('home_tab')) || isNaN(parseInt(localStorage.getItem('home_tab'))) ? config.home_tab_global : parseInt(localStorage.getItem('home_tab')),
            home_sort: constants.isEmpty(localStorage.getItem('home_sort')) || isNaN(parseInt(localStorage.getItem('home_sort'))) ? config.home_sort_recent : parseInt(localStorage.getItem('home_sort')),

            postText: '',

            // postPage: 0,

        };

        this.handleScroll_function = this.handleScroll.bind(this);

        // this.handleScroll_function = this.handleScroll.bind(this);

    }

    async componentDidMount() {

        const a = constants.getOS();

        window.addEventListener('scroll', this.handleScroll_function);

        // this.setState({ loading: false }, () => {
        //     this.handleScroll(null);
        // });

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll_function);
    }

    loadedAsset() {
        this.assetsToLoad--;
        if (this.assetsToLoad <= 0) {
            this.setState({ loading: false }, () => {

                window.scrollTo({ top: 0, behavior: "instant" });

                this.handleScroll(null);

                const animatedDivsAlways = document.getElementsByClassName('w3s_always');

                for (const d of animatedDivsAlways) {

                    if (!d || d.classList.contains('show')) {
                        continue;
                    }
                    d.classList.add('show');

                }

            });
        }
    }

    changeTab(tab) {

        if (this.state.home_tab === tab) {
            return;
        }

        if (tab !== config.home_tab_updates) {
            localStorage.setItem('home_tab', tab);
        }

        this.setState({ home_tab: tab });

    }

    changeSortTab(tab) {

        if (this.state.home_sort === tab) {
            return;
        }

        localStorage.setItem('home_sort', tab);

        this.setState({ home_sort: tab });

    }

    handleScroll(e) {

        const currentTime = new Date().getTime();

        if (currentTime - this.lastScroll < 10) {
            return;
        }

        this.lastScroll = currentTime;

        const offset = 200;

        const animatedDivs1 = document.getElementsByClassName('animateLeft');
        const animatedDivs2 = document.getElementsByClassName('animateRight');
        const animatedDivs3 = document.getElementsByClassName('animateIn');

        for (const d of animatedDivs1) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

        for (const d of animatedDivs2) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

        for (const d of animatedDivs3) {

            if (!d || d.classList.contains('show')) {
                continue;
            }
            const top = d.getBoundingClientRect().top;
            if ((top) >= 0 && (top + offset) <= window.innerHeight) {
                d.classList.add('show');
            }

        }

    }

    render() {

        return (
            <div className='bg-gray-50 dark:bg-slate-950' onMouseDown={(e) => constants.closeAllDropdowns(e)}>

                <img className='gfx_landing' src={logo_s} />

                {/* LOADER */}
                {this.state.loading ?
                    <Loader loading={this.state.loading} />
                : null}

                <div className='home_container overflow-x-hidden'>

                    <NavLanding parent={this} accountData={this.state.accountData} tab={'play'} />

                    <div className='play_banner'>

                        <div className='hero_image_background_container w3s_always animateIn delay1'>
                            <div className='play_background_overlay' />
                            <img className='play_image_background' src={play_wallpaper} onLoad={(e) => this.loadedAsset()} />
                        </div>

                        <img className='play-text' src={playnow_text} style={{zIndex: 2}} onLoad={(e) => this.loadedAsset()} />

                        {/* <div className='hero_landing_left'>

                            <img className='hero_logo' src={logo} onLoad={(e) => this.loadedAsset()} />

                        </div> */}

                        <div className='hero_background_bottom'>
                        </div>

                    </div>

                    <div className='container home_middle_container landing'>

                        <div id='steps' className='section_landing_page'>

                            {constants.isMobile2() ?

                                <div className='section_landing_normal'>

                                    <div className='section_title animateIn'><span className='hero_neon1'>Web3Scape</span> is not yet on mobile</div>
                                
                                    <div className='section_feature_box lg animateIn' style={{ marginTop: 50, justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='text-lg text-muted-800 text-center'>Web3Scape is not yet downloadable for the mobile. We will work towards a mobile client in the future!<br/><br/>Please head to our website on your desktop!</div>
                                    </div>

                                </div>
                            
                            :

                                <div className='section_landing_normal'>

                                    <div className='section_title animateIn'>How to play <span className='hero_neon1'>Web3Scape</span></div>
                                    
                                    {/* <div className='section_row'>

                                        <div className='section_half animateRight'>
                                            <div className='section_subText text-muted-800'>Choose <span className='hero_neon1'>Torogram</span> for a revolutionary experience where social media meets the vast possibilities of Web3. Our platform is not just a space to connect and share but a comprehensive hub for decentralized finance, AI innovations and gaming.<br></br><br></br>By selecting <span className='hero_neon1'>Torogram</span>, you're stepping into a world where your digital identity, assets and interactions are seamlessly integrated, offering unparalleled control and opportunities. Our dedication to creating cutting-edge tools and fostering a vibrant community of like-minded individuals ensures that Torogram is more than just a platform; it's a gateway to the future of online interaction.</div>
                                        </div>

                                        <div className='section_half_2 animateLeft'>
                                            <img className='' src={robot2} />
                                        </div>

                                    </div> */}

                                    <div id='features' className='section_row_features_page'>

                                        <div className='section_feature_box animateRight'>
                                            <div className='section_feature_box_icon text-xl'><i className='fab fa-java' /></div>
                                            <div className='section_feature_box_title'>1. Install Java</div>
                                            <div className='section_feature_box_text text-muted-800'>Web3Scape runs on Java. That means you'll have to download and install Java in order to play. Go to Java's official website <a className='w3s_link' href='https://java.com/download' target='_blank'>here</a> or download below.</div>
                                            <a href='https://java.com/download' target='_blank' className='w3s_info_button_full mt-5'>Download Java</a>
                                        </div>

                                        <div className='section_feature_box animateRight delay1'>
                                            <div className='section_feature_box_icon text-xl'><i className='fas fa-rocket-launch' /></div>
                                            <div className='section_feature_box_title'>2. Download launcher</div>
                                            <div className='section_feature_box_text text-muted-800'>The launcher is a jar file which can be ran after installing Java. The launcher makes sure you have the latest updates before playing Web3Scape.</div>
                                            <a href='https://web3scape.io/game/downloads/Web3Scape Launcher.jar' target='_blank' className='w3s_info_button_full mt-5'>Download Launcher</a>
                                        </div>

                                        <div className='section_feature_box animateRight delay2'>
                                            <div className='section_feature_box_icon text-xl'><i className='fas fa-user-plus' /></div>
                                            <div className='section_feature_box_title'>3. Account creation</div>
                                            <div className='section_feature_box_text text-muted-800'>No Sign-Up needed. Just fill in your username and password for the first time and it automatically creates an account for you on the go.<br/><br/>Still experiencing problems with the Launcher? Try running the <a className='w3s_link' href='https://web3scape.io/game/downloads/web3scape.jar' target='_blank'>Direct Client here</a>.</div>
                                        </div>

                                        {/* <div className='section_feature_box animateLeft'>
                                            <div className='section_feature_box_icon text-xl'><i className='fas fa-comments' /></div>
                                            <div className='section_feature_box_title'>Social platform</div>
                                            <div className='section_feature_box_text text-muted-800'>Torogram is more than a social platform; here you can share, engage and connect on a deeper level. Discover new content and create lasting connections</div>
                                        </div>

                                        <div className='section_feature_box animateLeft delay1'>
                                            <div className='section_feature_box_icon text-xl'><i className='fas fa-network-wired' /></div>
                                            <div className='section_feature_box_title'>Web3 HUB</div>
                                            <div className='section_feature_box_text text-muted-800'>Navigate the future with Torogram's Web3 HUB. Find games, projects and more - all embracing the decentralized web. It's your portal to the cutting-edge of technology</div>
                                        </div>

                                        <div className='section_feature_box animateLeft delay2'>
                                            <div className='section_feature_box_icon text-xl'><i className='fas fa-money-bill' /></div>
                                            <div className='section_feature_box_title'>Revenue Sharing</div>
                                            <div className='section_feature_box_text text-muted-800'>By holding $TORO, you can earn a share of the platform's profits. It's our way of saying thank you for helping Torogram grow</div>
                                        </div> */}

                                    </div>

                                    <div className='section_feature_box lg animateIn' style={{ marginTop: 50 }}>
                                        <div className='section_feature_box_row'>

                                            <div className='section_feature_box_left'>
                                                <div className='section_feature_box_title'>still having trouble?</div>
                                                <div className='section_feature_box_text text-muted-800 text-lg'>Nothing to worry about! Our wizard will help you to play Web3Scape!</div>

                                                {constants.getOS() === 'Windows' ?
                                                    <>
                                                        <div className='section_feature_box_text text-muted-800 text-lg'>Our wizard has detected that you have a <span className='hero_neon1'>Windows</span> system. Please check our wizard's solution below!<br/><br/><span className='italic'>"Download Jarfix below to fix your Java error or download the Direct Client below to play Web3Scape."</span></div>
                                                        <div className='flex flex-row items-center flex-wrap landing_button_row mt-5' style={{ gap: '10px 10px' }}>
                                                            <a href='https://web3scape.io/game/downloads/jarfix.exe' target='_blank' className='w3s_info_button'>Download Jarfix</a>
                                                            <a href='https://web3scape.io/game/downloads/web3scape.jar' target='_blank' className='w3s_info_button'>Download Client</a>
                                                        </div>
                                                    </>
                                                :
                                                    <>
                                                        <div className='section_feature_box_text text-muted-800 text-lg'>Our wizard has detected that you have a <span className='hero_neon1'>MacOS / Linux / UNIX</span> system. Please check our wizard's solution below!<br/><br/><span className='italic'>"Download the Direct Client below to play Web3Scape."</span></div>
                                                        <div className='flex flex-row items-center flex-wrap landing_button_row mt-5' style={{ gap: '10px 10px' }}>
                                                            <a href='https://web3scape.io/game/downloads/web3scape.jar' target='_blank' className='w3s_info_button'>Download Client</a>
                                                        </div>
                                                    </>
                                                }


                                            </div>

                                            <div className='section_feature_box_right'>
                                                <img src={wizard} width={'60%'} />
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>

                            }

                        </div>

                        {/* <div id='dapp' className='section_landing'>
                            <div className='landing_banner_box animateIn'>
                                <div className='landing_banner_title'>Ready To Decentralize?</div>
                                <div className='landing_banner_text'>Prepare to explore the vast possibilities of decentralized finance, AI innovations and social connectivity with Torogram – where your digital world expands beyond imagination</div>
                                <div className='flex flex-row items-center landing_button_row' style={{ marginTop: 25 }}>
                                    <Link to={'/hub'} className='landing_banner_button'><span>Web3 HUB</span></Link>
                                    <Link to={'/social'} className='landing_banner_button'><span>Social dApp</span></Link>
                                </div>
                                <img className='landing_banner_robot' src={robot3} />
                            </div>
                        </div>

                        <div id='developer' className='section_landing'>

                            <div className='section_landing_normal'>

                                <div className='section_title right animateIn'>Words From <span className='hero_neon1'>Lead-Developer</span></div>
                                
                                <div className='section_row'>

                                    <div className='section_half_center animateRight'>
                                        <img className='' src={jordy} />
                                    </div>

                                    <div className='section_half_2 normal'>
                                        <div className='section_subText text-muted-800'>
                                            <div className='animateLeft'>" My name is Jordy, and I am a 28-year-old software developer based in The Netherlands. My journey into programming began at the age of 13, driven by a keen interest in game development. By 16, I had successfully managed an emulated game server, attracting over 1000 online players simultaneously.</div>
                                            <br></br>
                                            <div className='animateLeft'>This early success deepened my fascination with software development, leading me to pursue and obtain a degree in the field in The Netherlands. After my studies, while working at a pizza restaurant, the owner recognized my skills and challenged me to create a scheduling app for the business. Embracing this opportunity, I developed an application that marked the beginning of my entrepreneurial journey.</div>
                                            <br></br>
                                            <div className='animateLeft'>Today, I run a successful business in The Netherlands, focusing on SaaS Employee Scheduling, alongside my former pizza restaurant employer. Our application is available on both the Google Play Store and the iOS App Store (see <a className='w3s_link' href='https://shiftey.app' target='_blank'>Shiftey App</a> and its Dutch version <a className='w3s_link' href='https://tiemdo.nl' target='_blank'>Tiemdo</a>). This venture not only showcases the quality of my work but also my passion for software development. My expertise extends to building complete systems, deploying apps to various platforms, managing large databases, and designing server architectures, all while ensuring simplicity and user-friendliness.</div>
                                            <br></br>
                                            <div className='animateLeft'>My foray into the crypto world began during the 2018 bull run. Despite the ups and downs of trading meme tokens, I identified a significant gap in the market: a need for a social media platform that leverages the unique capabilities of web3 technology. This led to the inception of Torogram, a project that aims to integrate cutting-edge features and a sense of community within the crypto space.</div>
                                            <br></br>
                                            <div className='animateLeft'>I am deeply passionate about and committed to Torogram. It represents not just a business venture, but a journey I am eager to embark on with the community. Together, we can make Torogram a cornerstone of the crypto world. Let's make this happen! "</div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div id='tokenomics' className='section_landing'>

                            <div className='section_landing_normal'>

                                <div className='section_title animateIn'>Deflationary <span className='hero_neon1'>Tokenomics</span></div>
                                
                                <div className='section_row'>

                                    <div className='section_half_center animateRight'>

                                        <div className='landing_tokenomics_box'>
                                            <div className='landing_tokenomics_row w3s_break_word' style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                                                <div className='hero_neon1 font-medium'>Contract Address</div>
                                                <a className='w3s_link_inherit' href='https://etherscan.io/token/0xab14f1262ea8d910756828cbe08e6cedfb66360d' target='_blank'>0xAb14f1262Ea8D910756828cBe08E6cEdFb66360D</a>
                                            </div>
                                            <div className='landing_tokenomics_row2'>
                                                <div className='hero_neon1 font-medium'>Network</div>
                                                <div className='text-muted-800'>Ethereum (ETH)</div>
                                            </div>
                                            <div className='landing_tokenomics_row'>
                                                <div className='hero_neon1 font-medium'>Total Supply</div>
                                                <div className='text-muted-800'>100M (100,000,000) tokens</div>
                                            </div>
                                            <div className='landing_tokenomics_row2'>
                                                <div className='hero_neon1 font-medium'>Tax</div>
                                                <div className='text-muted-800'>5/5 buying and selling (no transfer tax)</div>
                                            </div>
                                            <div className='landing_tokenomics_row'>
                                                <div className='hero_neon1 font-medium'>Max Buy</div>
                                                <div className='text-muted-800'>1.5% (1,500,000 tokens)</div>
                                            </div>
                                            <div className='landing_tokenomics_row2' style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                <div className='text-muted-800'>$TORO maintains stability by strategically and consistently contributing to the liquidity pool, ensuring a sustained and stable price for the benefit of its users.</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='section_half_2 big animateLeft'>
                                        <img className='' src={robot4} />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className='section_landing'>

                            <div className='section_landing_normal'>
                                
                                <div className='section_row_features' style={{ marginTop: 0 }}>

                                    <div className='section_feature_box sm animateIn'>
                                        <div className='section_feature_box_icon green text-xl'><i className='fas fa-circle-check' /></div>
                                        <div className='section_feature_box_title'>Audit</div>
                                        <div className='section_feature_box_text text-muted-800'>Our contract is verified and audited by <a className='w3s_link_inherit font-bold' href='https://app.solidproof.io/projects/torogram' target='_blank'>Solidproof</a></div>
                                    </div>

                                    <div className='section_feature_box sm animateIn'>
                                        <div className='section_feature_box_icon green text-xl'><i className='fas fa-circle-check' /></div>
                                        <div className='section_feature_box_title'>KYC</div>
                                        <div className='section_feature_box_text text-muted-800'>The team is doxxed and KYC by <a className='w3s_link_inherit font-bold' href='https://github.com/solidproof/projects/tree/main/2024/Torogram' target='_blank'>Solidproof</a></div>
                                    </div>

                                    <div className='section_feature_box sm animateIn'>
                                        <div className='section_feature_box_icon green text-xl'><i className='fas fa-circle-check' /></div>
                                        <div className='section_feature_box_title'>LP Lock</div>
                                        <div className='section_feature_box_text text-muted-800'>Our LP is locked and always extended on <a className='w3s_link_inherit font-bold' href='https://app.uncx.network/amm/uni-v2/pair/0xbca0660a58fe70e153e6b533eb130943b68e666b' target='_blank'>UNCX</a></div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className='section_landing'>

                            <div className='section_landing_normal'>

                                <div className='section_title'>Our <span className='hero_neon1'>Partners</span></div>
                                
                                <div className='landing_partners_row'>

                                    <img className='landing_partner_img' src={build} />
                                    <img className='landing_partner_img' src={pano} />
                                    <img className='landing_partner_img' src={astrox} />
                                    <img className='landing_partner_img' src={fbg} />
                                    <img className='landing_partner_img' src={azee} />
                                    <img className='landing_partner_img' src={snt} />

                                </div>

                            </div>

                        </div>

                        <div className='section_landing'>

                            <div className='landing_banner_box animateIn'>
                                <div className='landing_banner_title'>Ready To Decentralize?</div>
                                <div className='landing_banner_text'>Prepare to explore the vast possibilities of decentralized finance, AI innovations and social connectivity with Torogram – where your digital world expands beyond imagination</div>
                                <div className='flex flex-row items-center landing_button_row' style={{ marginTop: 25 }}>
                                    <Link to={'/hub'} className='landing_banner_button'><span>Web3 HUB</span></Link>
                                    <Link to={'/social'} className='landing_banner_button'><span>Social dApp</span></Link>
                                </div>
                                <img className='landing_banner_robot' src={robot3} />
                            </div>
                            
                        </div>

                        <div className='footer_box'>

                            <div className='footer_row'>

                                <div className='footer_logo'>

                                    <Link to="/" onClick={(e) => this.props.location.pathname === '/' ? window.scrollTo(0, 0) : null}>
                                        <img src={logo_dark} className="block dark:hidden" alt="" style={{ height: 45 }} />
                                        <img src={logo_light} className="hidden dark:block" alt="" style={{ height: 45 }} />
                                    </Link>

                                    <div className='text-muted-600 mt-4 text-sm'>We simplify using Web3 - where Web3 meets AI + De-Fi tools and Social innovations</div>
                                
                                    <div className='footer_socials mt-4'>
                                        <a className="w3s_footer_button" href='https://torogram.com' target='_blank'>
                                            <i className="far fa-globe"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://t.me/torogramportal' target='_blank'>
                                            <i className="fab fa-telegram"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://x.com/torogram_eth' target='_blank'>
                                            <i className="fab fa-x"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://www.linkedin.com/in/jordy-ortega-872911128/' target='_blank'>
                                            <i className="fab fa-linkedin"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://instagram.com/torogram.official' target='_blank'>
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://tiktok.com/@torogramofficial' target='_blank'>
                                            <i className="fab fa-tiktok"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://medium.com/@torogram' target='_blank'>
                                            <i className="fab fa-medium"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://github.com/jordyortega/Torogram' target='_blank'>
                                            <i className="fab fa-github"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='mailto:info@torogram.com' target='_blank'>
                                            <i className="far fa-envelope"></i>
                                        </a>
                                        <a className="w3s_footer_button" href='https://torogram.com/social' target='_blank'>
                                            <i className="far fa-mobile"></i>
                                        </a>
                                    </div>
                                
                                </div>

                                <div className='w3s_footer_links'>
                                    <div className='font-bold text-muted-600'>TORO</div>
                                    <a className='w3s_link_inherit mt-1' href='https://docs.torogram.com/roadmap' target='_blank'>Roadmap</a>
                                    <a className='w3s_link_inherit mt-1' href='https://docs.torogram.com' target='_blank'>Docs</a>
                                    <a className='w3s_link_inherit mt-1' href='https://torogram.com/downloads/whitepaper.pdf' target='_blank'>Whitepaper</a>
                                    <a className='w3s_link_inherit mt-1' href='https://www.dextools.io/app/en/ether/pair-explorer/0xbca0660a58fe70e153e6b533eb130943b68e666b?t=1711656918473' target='_blank'>Dextools</a>
                                    <a className='w3s_link_inherit mt-1' href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xab14f1262ea8d910756828cbe08e6cedfb66360d' target='_blank'>Buy $TORO</a>
                                </div>

                                <div className='w3s_footer_links'>
                                    <div className='font-bold text-muted-600'>Resources</div>
                                    <a className='w3s_link_inherit mt-1' href='https://torogram.com/hub' target='_blank'>Web3 HUB dApp</a>
                                    <a className='w3s_link_inherit mt-1' href='https://torogram.com/social' target='_blank'>Social dApp</a>
                                    <a className='w3s_link_inherit mt-1' href='https://github.com/solidproof/projects/tree/main/2024/Torogram' target='_blank'>KYC</a>
                                    <a className='w3s_link_inherit mt-1' href='https://app.solidproof.io/projects/torogram' target='_blank'>Audit</a>
                                    <a className='w3s_link_inherit mt-1' href='https://app.uncx.network/amm/uni-v2/pair/0xbca0660a58fe70e153e6b533eb130943b68e666b' target='_blank'>LP Lock</a>
                                </div>

                            </div>

                            <div className='w-full text-center mt-4'>
                                <span className='text-muted-500'>© Copyright Torogram</span>
                            </div>

                        </div> */}

                        <Footer />

                    </div>

                    {/* <Switcher/> */}

                </div>

            </div>
        );
    }
  
}

export default withRouter(Play);
import React from 'react';
import { Link } from 'react-router-dom';

import config from '../../data/config';
import constants from '../../data/constants';

import logo_dark from '../../assets/images/T_light.png';
import logo_light from '../../assets/images/T_dark.png';
import withRouter from '../../data/withRouter';

import icon_features from '../../assets/images/torogram/icons/star.png';
import icon_team from '../../assets/images/torogram/icons/team.png';
import icon_tokenomics from '../../assets/images/torogram/icons/pie_chart.png';
import icon_roadmap from '../../assets/images/torogram/icons/roadmap.png';
import icon_links from '../../assets/images/torogram/icons/links.png';
import icon_global from '../../assets/images/torogram/icons/global.png';
import icon_users from '../../assets/images/torogram/icons/users.png';
import icon_news from '../../assets/images/torogram/icons/news.png';
import icon_posts from '../../assets/images/torogram/icons/posts.png';
import icon_info from '../../assets/images/torogram/icons/info.png';
import icon_portfolio from '../../assets/images/torogram/icons/portfolio.png';
import icon_search from '../../assets/images/torogram/icons/search.png';
import icon_bell from '../../assets/images/torogram/icons/bell.png';
import icon_bookmarks from '../../assets/images/torogram/icons/bookmarks.png';
import icon_staking from '../../assets/images/torogram/icons/staking.png';
import Alert from '../Alert/Alert';
import Modal from '../Modal/Modal';
import ImageViewer from '../ImageViewer/imageViewer';
import DropdownMobile from '../DropdownMobile/DropdownMobile';
import logo from '../../assets/web3scape/web3scape_x1000.png';
import logo_s from '../../assets/web3scape/web3scape_icon.png';
// import ConnectWalletButton from '../Web3/connectWalletButton';
// import ConnectedWallet from '../Web3/connectedWallet';

import icon_home from '../../assets/images/torogram/icons/home.png';
import icon_profile from '../../assets/images/torogram/icons/profile.png';
import icon_books from '../../assets/images/torogram/icons/books.png';

import ModalContent_CreatePost from '../Modal/ModalContent_CreatePost';
import Modal2 from '../Modal/Modal2';
import Modal3 from '../Modal/Modal3';
import ModalContent_Settings from '../Modal/ModalContent_Settings';



class NavLanding extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.accountData = this.props.accountData;
        this.home_tab = this.props.home_tab;
        this.changeHomeTab = this.props.changeHomeTab;

        this.state = {

            tab: this.props.tab,

            // alert
            alertTitle: '',
            alertText: '',
            alertButtonText: '',
            alertButtonAction: null,

        };
        
        this.reloadNav_function = this.reloadNav.bind(this);

    }

    async componentDidMount() {

        window.addEventListener('reloadNav', this.reloadNav_function);

        // const accountData = sessionStorage.getItem('account_data');
        // if (constants.isEmpty(accountData) === false) {
        //     this.accountData = JSON.parse(accountData);
        // }

    }

    componentDidUpdate(prevProps, prevState) {

    

    }

    componentWillUnmount() {
        window.removeEventListener('reloadNav', this.reloadNav_function);
    }

    reloadNav() {

        this.accountData = config.accountData;

        this.forceUpdate();

    }

    // shouldComponentUpdate(nextProps, nextState) {

    //     // LOGGED IN
    //     if (constants.isEmpty(this.accountData) && constants.isEmpty(nextProps.accountData) === false) {
    //         this.accountData = nextProps.accountData;
    //         this.forceUpdate();
    //         return true;
    //     } else if (constants.isEmpty(this.accountData) === false && constants.isEmpty(nextProps.accountData) === false && this.accountData.info_id !== nextProps.accountData.info_id) {
    //         this.accountData = nextProps.accountData;
    //         this.forceUpdate();
    //         return true;
    //     }

    //     this.forceUpdate();
    //     return false;

    // }

    logoutAlert() {

        constants.closeAllDropdowns(null, true);

        constants.showAlert(this, {
            alertTitle: 'Log out',
            alertText: 'Are you sure you want to log out from Torogram?',
            alertButtonText: 'LOGOUT',
            alertButtonAction: constants.logout,
        });

    }    

    toggleMenu() {

        const mobileMenuContainer = document.getElementById('mobileMenuContainer');
        const mobileMenu = document.getElementById('mobileMenu');

        mobileMenuContainer.classList.toggle('show');
        mobileMenu.classList.toggle('show');

    }

    closeMenu() {

        const mobileMenuContainer = document.getElementById('mobileMenuContainer');
        const mobileMenu = document.getElementById('mobileMenu');

        mobileMenuContainer.classList.remove('show');
        mobileMenu.classList.remove('show');

    }

    toggleProfile() {

        const navDropdown = document.getElementById('dropdown_navDropdown');

        navDropdown.classList.toggle('show');

    }

    changeTab(tab) {

        if (this.state.tab === tab) {
            if (this.state.tab === config.tab_home) {
                this.changeHomeTab(config.home_tab_global);
                window.scrollTo(0, 0);
            }
            return;
        }

        // this.setState({ tab: tab });

        if (tab === config.tab_info) {

            this.props.navigate('/toro/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_home) {

            this.props.navigate('/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_portfolio) {

            this.props.navigate('/portfolio/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_explore) {

            this.props.navigate('/explore/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_notifications) {

            this.props.navigate('/notifications/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_bookmarks) {

            this.props.navigate('/bookmarks/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_staking) {

            this.props.navigate('/staking/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_games) {

            this.props.navigate('/games/', { replace: false, preventScrollReset: false, state: {} });

        } else if (tab === config.tab_profile) {

            if (constants.isEmpty(this.accountData) === false) {
                this.props.navigate(`/${this.accountData.account_username}`, { replace: false, preventScrollReset: false, state: {} });
            } else {
                this.props.navigate(`/login/`, { replace: false, preventScrollReset: false, state: {} });
            }

        }

    }

    renderHomeTabs() {

        let toRender = <div></div>;

        if (this.state.tab === config.tab_info) {

            toRender = (<div>

                <a className={`w3s_button`} href="#features">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-star' /> */}
                        <img src={icon_features} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Features</span>
                    </div>
                </a>

                <a className={`w3s_button`} href="#team">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-users' /> */}
                        <img src={icon_team} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Team</span>
                    </div>
                </a>

                <a className={`w3s_button`} href="#tokenomics">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-circle-three-quarters' /> */}
                        <img src={icon_tokenomics} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Tokenomics</span>
                    </div>
                </a>

                <a className={`w3s_button`} href="#roadmap">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-network-wired' /> */}
                        <img src={icon_roadmap} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Roadmap</span>
                    </div>
                </a>

                <a className={`w3s_button w3s_disabled`} href="#links">
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-location-dot' /> */}
                        <img src={icon_links} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Useful links</span>
                    </div>
                </a>

            </div>);

        } else if (this.state.tab === config.tab_home) {

             toRender = (<div>

                <div className={`w3s_button ${this.home_tab === config.home_tab_global ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.home_tab_global)}>
                    <div className='w3s_button_icon text-indigo-600'>
                        {/* <i className='far fa-globe' /> */}
                        <img src={icon_global} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Global</span>
                    </div>
                </div>

                <div className={`w3s_button ${this.home_tab === config.home_tab_following ? 'active' : ''} w3s_disabled`} onClick={() => this.changeHomeTab(config.home_tab_following)}>
                    <div className='w3s_button_icon text-indigo-500'>
                        {/* <i className='far fa-users' /> */}
                        <img src={icon_users} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Following</span>
                    </div>
                </div>

                <div className={`w3s_button ${this.home_tab === config.home_tab_updates ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.home_tab_updates)}>
                    <div className='w3s_button_icon text-indigo-400'>
                        {/* <i className='far fa-newspaper' /> */}
                        <img src={icon_news} alt="" />
                    </div>
                    <div className='w3s_button_text'>
                        <span>Torogram updates</span>
                    </div>
                </div>

            </div>);

        } else if (this.state.tab === config.tab_profile) {

            toRender = (<div>

               <div className={`w3s_button ${this.home_tab === config.home_tab_global ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.profile_tab_posts)}>
                   <div className='w3s_button_icon'>
                       <img src={icon_posts} alt="" />
                   </div>
                   <div className='w3s_button_text'>
                       <span>Posts</span>
                   </div>
               </div>

               <div className={`w3s_button ${this.home_tab === config.home_tab_following ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.profile_tab_info)}>
                   <div className='w3s_button_icon'>
                       <img src={icon_info} alt="" />
                   </div>
                   <div className='w3s_button_text'>
                       <span>Info</span>
                   </div>
               </div>

               {/* <div className={`w3s_button ${this.home_tab === config.home_tab_updates ? 'active' : ''}`} onClick={() => this.changeHomeTab(config.home_tab_updates)}>
                   <div className='w3s_button_icon text-indigo-400'>
                       <img src={icon_news} alt="" />
                   </div>
                   <div className='w3s_button_text'>
                       <span>Torogram updates</span>
                   </div>
               </div> */}

           </div>);

       }

        return toRender;

    }

    openSettings() {
        constants.showModal(this, <ModalContent_Settings parent={this} loading={false} options={{  }} />, 'sm')
    }

    scrollToID(id){
        
        var element = document.getElementById(id);

        if (constants.isEmpty(element) === true) {
            this.props.navigate('/', { replace: true, preventScrollReset: false, state: {} });

            setTimeout(() => {

                var element2 = document.getElementById(id);
                var headerOffset2 = 0;
                var elementPosition2 = element2.getBoundingClientRect().top;
                var offsetPosition2 = elementPosition2 + window.scrollY - headerOffset2;

                setTimeout(() => {
                    this.closeMenu();
                }, 100);

                window.scrollTo({
                    top: offsetPosition2,
                    behavior: "smooth"
                });
                
            }, 500);

            return;
        }

        var headerOffset = 0;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.scrollY - headerOffset;

        setTimeout(() => {
            this.closeMenu();
        }, 100);

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });

    }

    render() {

        return (
            <>

                {/* IMAGE VIEWER */}
                <ImageViewer parent={this} content={config.contentToShow} nft={config.contentToShow_nft} data={config.contentToShow_data} />

                {/* MODAL */}
                <Modal parent={this} content={config.modalContent} size={config.modalSize} />

                {/* MODAL2 */}
                <Modal2 parent={this} content={config.modalContent2} size={config.modalSize2} />

                {/* MODAL3 */}
                <Modal3 parent={this} content={config.modalContent3} size={config.modalSize3} />

                {/* ALERT */}
                <Alert parent={this} content={{ title: config.alertTitle, text: config.alertText, buttonText: config.alertButtonText, action: config.alertButtonAction }} />

                {/* MOBILE DROPDOWN */}
                <DropdownMobile parent={this} content={config.mobileDropdownContent} />
                
                <div id="w3s_top_nav_landing" className="w3s_navbar_landing">

                    <div className='hero_background_overlay2' />

                    <div className="w3s_container">

                        <div className="w3s_left-column_landing">

                            {this.state.tab === config.tab_post || this.state.tab === config.tab_reply ?

                                <div className='w3s_icon_button' onClick={() => this.props.navigate(-1)}>
                                    <i className='fas fa-chevron-left cursor-pointer' />
                                </div>

                            :

                                <Link className="logo" to="/" onClick={(e) => this.props.location.pathname === '/' ? window.scrollTo(0, 0) : null}>
                                    <img src={logo_s} className="block dark:hidden" alt="" />
                                    <img src={logo_s} className="hidden dark:block" alt="" />
                                </Link>

                            }

                        </div>

                        <div className="w3s_middle-column_landing">


                                <Link className={`w3s_link_nav font-semibold text-lg ${this.props.tab === 'home' ? 'active' : ""}`} to={"/"}>Home</Link>
                                <Link className={`w3s_link_nav font-semibold text-lg ${this.props.tab === 'play' ? 'active' : ""}`} to={"/play"}>Play</Link>
                                <div className={`w3s_link_nav font-semibold text-lg`}>Features</div>
                                <a className={`w3s_link_nav font-semibold text-lg`}>Guides</a>
                                <div className={`w3s_link_nav font-semibold text-lg`}>Highscores</div>
                                {/* <a className={`w3s_link_nav font-semibold text-lg`}>Vote</a> */}
                                {/* <a className='w3s_link_inherit font-semibold' href='https://docs.torogram.com/roadmap' target='_blank'>Roadmap</a> */}

                            
                        </div>

                        <div className="w3s_right-column_landing">

                            {/* <div className='w3s_button_navLanding'>Web3 dApp</div> */}
                            <div className='w3s_button_navLanding'>Account Panel</div>

                            {/* {constants.isEmpty(this.accountData) === false ?
                                <div className='w3s_profileButton' onClick={() => this.toggleProfile()}>

                                    {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                        <img src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                    :
                                        <i className='far fa-user' />
                                    }

                                </div>
                            :
                                <Link className='w3s_profileButton' to="/login/">
                                    <i className='far fa-right-to-bracket' />
                                </Link>
                            } */}

                        </div>

                        <div className="w3s_right-column_mobile_landing">

                            <div className='w3s_menuButton' onClick={() => this.toggleMenu()}>
                                <i className='far fa-bars' />
                            </div>

                            {/* {constants.isEmpty(this.accountData) === false ?
                                // <div className='w3s_profileButton me-1' onClick={() => this.toggleProfile()}>

                                //     {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                //         <img src={constants.getFileLoc(this.accountData.account_username, this.accountData.info_profilepicture)} alt="" />
                                //     :
                                //         <i className='far fa-user' />
                                //     }

                                // </div>
                                null
                            :
                                // me-1
                                <Link className='w3s_profileButton' to="/login/">
                                    <i className='far fa-right-to-bracket' />
                                </Link>
                            } */}

                            {/* <div className='w3s_menuButton' onClick={() => this.toggleMenu()}>
                                <i className='far fa-bars' />
                            </div> */}

                        </div>

                    </div>

                </div>

                {/* MOBILE NAV BOTTOM */}
                {/* <div id='w3s_bottom_nav' className='w3s_mobile_navbar bg-white dark:bg-slate-900'>

                    <div className='w3s_mobile_navbar_container'>

                        {this.state.tab === config.tab_home ?
                            <div className='w3s_navButton mobile active top' onClick={() => this.changeTab(config.tab_home)}>
                                <i className='fas fa-house-blank' />
                            </div>
                        :
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_home)}>
                                <i className='far fa-house-blank' />
                            </div>
                        }

                        {this.state.tab === config.tab_explore ?
                            <div className='w3s_navButton mobile active top'>
                                <i className='fas fa-magnifying-glass' />
                            </div>
                        :
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_explore)}>
                                <i className='far fa-magnifying-glass' />
                            </div>
                        }

                        {this.state.tab === config.tab_portfolio ?
                            <div className='w3s_navButton mobile active top'>
                                <i className='fas fa-wallet' />
                            </div>
                        :
                            <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_portfolio)}>
                                <i className='far fa-wallet' />
                            </div>
                        }

                        {constants.isEmpty(config.accountData) === false ?
                            this.state.tab === config.tab_videos ?
                                <div className='w3s_navButton mobile active top'>
                                    <i className='fas fa-bell' />
                                </div>
                            :
                                <div className='w3s_navButton mobile' onClick={() => this.changeTab(config.tab_notifications)}>
                                    <i className='far fa-bell' />
                                </div>
                        : null}

                        {this.state.tab === config.tab_games ?
                            <div className='w3s_navButton mobile active top'>
                                <i className='fas fa-comments' />
                            </div>
                        :
                            <div className='w3s_navButton mobile w3s_disabled'>
                                <i className='far fa-comments' />
                            </div>
                        }

                    </div>

                </div> */}

                <div className='w3s_mobile-menu_container' id="mobileMenuContainer" onClick={() => this.closeMenu()}>

                    <div className="w3s_mobile-menu bg-gray-50 dark:bg-slate-950" id="mobileMenu">

                        <div className='w-full flex justify-center mb-5'>
                            <img src={logo} width={300} />
                        </div>

                        <Link to='/' className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Home</span>
                            </div>
                        </Link>

                        <Link to='/play' className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Play Now</span>
                            </div>
                        </Link>

                        <div className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Features</span>
                            </div>
                        </div>

                        <div className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Guides</span>
                            </div>
                        </div>

                        <div className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Highscores</span>
                            </div>
                        </div>

                        <a className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Vote</span>
                            </div>
                        </a>

                        <div className={`w3s_button`}>
                            <div className='w3s_button_text'>
                                <span>Community</span>
                            </div>
                        </div>

                        <div className='dropdown_li w3s_divider' style={{ marginTop: 10, marginBottom: 10 }} />

                        <div className='w3s_button_navLanding w-full'><span>Account Panel</span></div>

                        <div className='w-full flex justify-center mt-5'>
                            <img src={logo_s} width={100} />
                        </div>

                        {/* <div className='w3s_subtitle text-muted-500 text-lg' style={{ marginTop: 14 }}>
                            <span>Menu</span>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_info ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_info)}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-circle-info' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>About Toro</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_home ? 'active' : ''}`} onClick={() => this.changeTab(config.tab_home)}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-house-blank' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Timeline</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_images ? 'active' : ''} w3s_disabled`}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-image' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Photos</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_videos ? 'active' : ''} w3s_disabled`}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-video' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Videos</span>
                            </div>
                        </div>

                        <div className={`w3s_button ${this.state.tab === config.tab_games ? 'active' : ''} w3s_disabled`}>
                            <div className='w3s_button_icon text-gray-600 dark:text-slate-300'>
                                <i className='far fa-gamepad' />
                            </div>
                            <div className='w3s_button_text'>
                                <span>Gaming</span>
                            </div>
                        </div> */}

                    </div>

                </div>

                {constants.isEmpty(this.accountData) === false ?
                    <div className='w3s_nav_dropdown bg-white dark:bg-slate-900' id="dropdown_navDropdown">
                        <ul>
                            <li className='dropdown_li nav_profile_button' onClick={() => this.props.navigate(`/${this.accountData.account_username}`, { replace: false, preventScrollReset: false, state: {} })}>
                                <div className='dropdown_li nav_profile_button_1'>
                                    <div className='dropdown_li nav_profile_button_profile'>

                                        {constants.isEmpty(this.accountData.info_profilepicture) === false ?
                                            <img className='dropdown_li' src={constants.getFileLoc(this.accountData.info_id, this.accountData.info_profilepicture)} alt="" />
                                        :
                                            <i className='dropdown_li far fa-user' />
                                        }

                                    </div>
                                </div>
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li font-bold'>{constants.getUserFullName(this.accountData)}</span>
                                    <span className='dropdown_li text-sm text-muted-500 font-normal'>@{this.accountData.account_username}</span>
                                </div>
                                <div className='dropdown_li nav_profile_button_3'>
                                    <i className='dropdown_li fas fa-chevron-right' />
                                </div>
                            </li>

                            <div className='dropdown_li w3s_divider' style={{ marginTop: 10, marginBottom: 10 }} />

                            <li className='dropdown_li nav_profile_button' onClick={() => this.openSettings()}>
                                {/* <div className='dropdown_li nav_profile_button_1'>
                                    <div className='dropdown_li nav_profile_button_icon'>
                                        <i className='dropdown_li fas fa-right-from-bracket' />
                                    </div>
                                </div> */}
                                <div className='dropdown_li nav_profile_button_2'>
                                    <span className='dropdown_li'>Settings</span>
                                </div>
                            </li>

                            {constants.isTrue(this.accountData.account_web3) ?
                                null
                            :
                                <li className='dropdown_li nav_profile_button red' onClick={() => this.logoutAlert()}>
                                    {/* <div className='dropdown_li nav_profile_button_1'>
                                        <div className='dropdown_li nav_profile_button_icon'>
                                            <i className='dropdown_li fas fa-right-from-bracket' />
                                        </div>
                                    </div> */}
                                    <div className='dropdown_li nav_profile_button_2'>
                                        <span className='dropdown_li'>Log out</span>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                : null}

            </>
        );
    }
  
}

export default withRouter(NavLanding);
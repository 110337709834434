


import CryptoJS from 'crypto-js';
import config from '../../data/config';

class APIPOSTPN {

    async Request(token, follow, mention, chat, likep, commentp, repostp, liker, commentr, repostr) {

        const accountid = config.accountData?.account_id || -1;
        const username = config.accountData?.account_username || '_';
        const password = config.accountData?.account_password || '_';
                
        const hash = CryptoJS.MD5(username + config.secretKey).toString();
        
        let response = null;

        const payload = {

            token: token,
            follow: follow,
            mention: mention,
            chat: chat,
            likep: likep,
            commentp: commentp,
            repostp: repostp,
            liker: liker,
            commentr: commentr,
            repostr: repostr,

            accountid: accountid,
            user: username,
            password: password,
            hash: hash,

        };

        try {
            response = await fetch(`https://server-t01.torogram.com/php/pushnotification/POSTPN.php`, {
                method: 'POST',
                body: JSON.stringify(payload),
                // body: data,
                // body: new URLSearchParams(`id=${id}&user=${username}&password=${password}&rights=${rights}&hash=${hash}`),
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch(err) {
            console.log(err)
            return [];
        }

        let sqlData = null;


        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
            console.log(err)
        }

        if (parseInt(sqlData) > 0) {

            return sqlData;

        } else if (sqlData === "0") {

            return null;

        } else {

            return null; //data

        }

    }
  
}
  
// singleton
export default (new APIPOSTPN());



import CryptoJS from 'crypto-js';
import config from '../data/config';

class APIDELETEFILE {

    async Request(loc) {

        const accountid = config.accountData?.account_id || -1;
        const username = config.accountData?.account_username || '_';
        const password = config.accountData?.account_password || '_';
                
        const hash = CryptoJS.MD5(username + config.secretKey).toString();
        
        let response = null;

        let formData = new FormData();
        formData.append('file', loc);
        formData.append('accountid', accountid);
        formData.append('user', username);
        formData.append('password', password);
        formData.append('hash', hash);

        try {
            response = await fetch(`https://server-t01.torogram.com/php/DELETEFILE.php`, {
                method: 'POST',
                // body: JSON.stringify(payload),
                body: formData,
                // body: new URLSearchParams(`user=${username}&password=${password}&rights=${rights}&hash=${hash}`)
                // headers: {
                //     "Content-Type": "application/json",
                // },
            });
        } catch(err) {
            console.log(err)
            return [];
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch (e) {
            console.log(e);
        }

        return sqlData;

    }
  
}
  
// singleton
export default (new APIDELETEFILE());
import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';

class DropdownMobile extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.content = this.props.content; // content.buttons | content.title

        this.state = {

        };

    }

    async componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.content) && constants.isEmpty(this.props.content) === false) {
            this.content = this.props.content;
            this.forceUpdate();
        } else if (constants.isEmpty(this.content) === false && constants.isEmpty(this.props.content) === false && this.content !== this.props.content) {
            this.content = this.props.content;
            this.forceUpdate();
        }

    }

    closeOnClick(e) {

        if (e.target.id !== 'w3s_dropdown_mobile') {
            return;
        }

        this.content = { title: '', buttons: [], };

        const modalBG = document.getElementById('w3s_dropdown_mobile');
        const modal = document.getElementById('w3s_dropdown_mobile_container');

        modalBG.classList.remove('show');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        this.forceUpdate();

    }

    close() {

        this.content = { title: '', buttons: [], };

        const modalBG = document.getElementById('w3s_dropdown_mobile');
        const modal = document.getElementById('w3s_dropdown_mobile_container');

        modalBG.classList.remove('show');
        modal.classList.remove('show');
        document.body.classList.remove('modal-open');

        this.forceUpdate();

    }

    doAction(action) {

        action();

        // for good animation
        setTimeout(() => {
            this.close();
        }, 10);

    }

    renderButtons() {

        let toRender = [];

        for (const button of this.content.buttons) {

            if (constants.isTrue(button.show) === false) {
                continue;
            }

            if (button.type === config.dropdownButton_danger) {

                toRender.push(
                    <div className={`dropdown_mobile_button red ${constants.isTrue(button.selected) ? 'active' : ''} ${constants.isTrue(button.disabled) ? 'w3s_disabled' : ''}`} onClick={() => this.doAction(button.action)}>
                        <div className='dropdown_mobile_button_icon'>
                            {button.icon}
                        </div>
                        <div className='dropdown_mobile_button_text'>
                            {button.text}
                        </div>
                        {constants.isTrue(button.selected) ?
                            <div className='dropdown_mobile_button_selected'>
                                <i className='fas fa-check-circle' />
                            </div>
                        : null}
                    </div>
                );

            } else if (button.type === config.dropdownButton_upload) {

                toRender.push(
                    <label className={`dropdown_mobile_button ${constants.isTrue(button.selected) ? 'active' : ''} ${constants.isTrue(button.disabled) ? 'w3s_disabled' : ''}`} htmlFor={button.htmlFor}>
                        <div className='dropdown_mobile_button_icon'>
                            {button.icon}
                        </div>
                        <div className='dropdown_mobile_button_text'>
                            {button.text}
                        </div>
                        <input className='' id={button.htmlFor} type="file" multiple={button.multiple} onChange={(event) => this.doAction(button.action.bind(this, event))} accept={button.accept} />
                        {/* {constants.isTrue(button.selected) ?
                            <div className='dropdown_mobile_button_selected'>
                                <i className='fas fa-check-circle' />
                            </div>
                        : null} */}
                    </label>
                );

            } else {

                toRender.push(
                    <div className={`dropdown_mobile_button ${constants.isTrue(button.selected) ? 'active' : ''} ${constants.isTrue(button.disabled) ? 'w3s_disabled' : ''}`} onClick={() => this.doAction(button.action)}>
                        <div className='dropdown_mobile_button_icon'>
                            {button.icon}
                        </div>
                        <div className='dropdown_mobile_button_text'>
                            {button.text}
                        </div>
                        {constants.isTrue(button.selected) ?
                            <div className='dropdown_mobile_button_selected'>
                                <i className='fas fa-check-circle' />
                            </div>
                        : null}
                    </div>
                );

            }

        }

        return toRender;

    }

    render() {

        return (
            <div id='w3s_dropdown_mobile' className='dropdown_mobile' onMouseDown={(e) => this.closeOnClick(e)}>

                <div id='w3s_dropdown_mobile_container' className='dropdown_mobile_container bg-white dark:bg-slate-800'>

                    <div className='dropdown_mobile_header'>
                        <h2 className='text-xl'>{this.content.title}</h2>
                        <div className='dropdown_mobile_close' onClick={() => this.close()}><i className='fas fa-xmark' /></div>
                    </div>

                    <div className='w3s_divider' />

                    <div className='dropdown_mobile_buttons mt-2'>
                        {this.renderButtons()}
                    </div>
                </div>

            </div>
        );
    }
  
}

export default withRouter(DropdownMobile);
import APIGETPN from '../api/pushnotification/APIGETPN';
import constants from './constants';

class TorogramNotifications {

    async sendPushNotification(userid, title, text, type, data = {}) {

        const notifications = await APIGETPN.Request(userid);
        const pushnotifications = constants.isEmpty(notifications) ? null : notifications[0];

        if (constants.isEmpty(pushnotifications) === true || constants.isEmpty(pushnotifications.n_token)) {
            return;
        }

        // type
        if (type === 'follow' && constants.isTrue(pushnotifications.n_follow) === false) {
            return;
        } else if (type === 'mention' && constants.isTrue(pushnotifications.n_mention) === false) {
            return;
        } else if (type === 'chat' && constants.isTrue(pushnotifications.n_chat) === false) {
            return;
        } else if (type === 'likep' && constants.isTrue(pushnotifications.n_like_post) === false) {
            return;
        } else if (type === 'commentp' && constants.isTrue(pushnotifications.n_comment_post) === false) {
            return;
        } else if (type === 'repostp' && constants.isTrue(pushnotifications.n_repost_post) === false) {
            return;
        } else if (type === 'liker' && constants.isTrue(pushnotifications.n_like_reply) === false) {
            return;
        } else if (type === 'commentr' && constants.isTrue(pushnotifications.n_comment_reply) === false) {
            return;
        } else if (type === 'repostr' && constants.isTrue(pushnotifications.n_repost_reply) === false) {
            return;
        }

        // set message
        const message = {
            to: pushnotifications.n_token,
            sound: 'default',
            title: title,
            body: text,
            data: data, // { data1: 'hey', data2: object }
        };

        // send message
        await fetch('https://exp.host/--/api/v2/push/send', {
            // method: 'POST',
            // headers: {
            //     Accept: 'application/json',
            //     'Accept-encoding': 'gzip, deflate',
            //     'Content-Type': 'application/json',
            // },
            // body: JSON.stringify(message),
            mode: 'no-cors',
            method: 'POST',
            headers: {
              'host': 'exp.host',
              'accept': 'application/json',
              'accept-encoding': 'gzip, deflate',
              'content-type': 'application/json',
            },
            body: JSON.stringify(message),
        });

    }

}

export default (new TorogramNotifications());
import APILOGIN from "../api/APILOGIN";
import config from "./config";

import icon_global from '../assets/images/torogram/icons/global.png';
import icon_users from '../assets/images/torogram/icons/users.png';
import icon_news from '../assets/images/torogram/icons/news.png';
import toast from "react-hot-toast";
import APIPOSTNOTIFICATION from "../api/Notification/APIPOSTNOTIFICATION";

class Constants {

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    getOS() {
        let OSName = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
        if (navigator.userAgent.indexOf("Mac") != -1) OSName = "MacOS";
        if (navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
        if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

        return OSName;
	
    }

    async switchNetwork() {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],    // chainId must be in HEX with 0x in front
        });
    }

    async autoLogin() {

        // check if username and password are stored
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (this.isEmpty(username) === false && this.isEmpty(password) === false) {
            //  start authentication
            const loggedIn = await this.authentication(username, password);
            return loggedIn;
        }

        const session_account = sessionStorage.getItem('account_data');

        if (this.isEmpty(session_account) === false) {
            const session_data = JSON.parse(session_account);

            //  start authentication
            const loggedIn = await this.authentication(session_data.account_username, session_data.account_password);
            return loggedIn;
        }

        return false;

    }

    async authentication(username, password) {

        //  set username defaults
        username = username.toLowerCase();

        //  request login
        const accountData = await APILOGIN.Request(username, password);

        //  save account data in session
        if (accountData.length > 0) {

            // set account data
            let account_data = accountData[0];
            account_data.account_password = password;

            // set account data in session
            sessionStorage.setItem('account_data', JSON.stringify(account_data));

            // set account in config local
            config.accountData = account_data;

            try {
                if (this.isWebview()) {
                    window.postMessage('login:' + JSON.stringify(config.accountData));
                    window.ReactNativeWebView.postMessage('login:' + JSON.stringify(config.accountData));
                }
            } catch (error) {
                return true;
            }

            return true;

        } else {

            return false;

        }

    }

    async authenticate(username, password) {

        //  set username defaults
        username = username.toLowerCase();

        //  request login
        const accountData = await APILOGIN.Request(username, password);

        //  save account data in session
        if (accountData.length > 0) {
            return true;
        } else {
            return false;
        }

    }

    logout() {

        localStorage.removeItem('username');
        localStorage.removeItem('password');
        sessionStorage.removeItem('account_data');
        config.accountData = null;

        window.location.reload();

    }

    isDeadWallet(address) {

        if (address.toLowerCase() === '0x0000000000000000000000000000000000000000' || address.toLowerCase() === '0x000000000000000000000000000000000000dead') {
            return true;
        }

        return false;

    }

    goTo(navigate, destination, props) {
        navigate(destination, { replace: false, preventScrollReset: false, state: {}, ...props });
    }

    isWebview() {
        const navigator = window.navigator
        const userAgent = navigator.userAgent
        const normalizedUserAgent = userAgent.toLowerCase()
        const standalone = navigator.standalone
  
        const isIos =
          /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        const isAndroid = /android/.test(normalizedUserAgent)
        const isSafari = /safari/.test(normalizedUserAgent)
        const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)
  
        return isWebview;
    }

    getUserFullName(accountData = config.accountData, preferDisplay = true) {

        let userFullName = ``;
        userFullName = accountData.info_display;

        if (this.isEmpty(accountData.info_display) === true) {
            userFullName = accountData.account_username;
        } 

        // if(this.isEmpty(accountData) === false) {
        //     userFullName = `${this.firstUppercase(accountData.info_firstname)} ${this.isEmpty(accountData.info_middlename) === false ? accountData.info_middlename.toLowerCase() + " " + this.firstUppercase(accountData.info_lastname) : this.firstUppercase(accountData.info_lastname)}`;
        // }

        // if(this.isEmpty(accountData) === false && ((this.isEmpty(accountData.info_firstname) === true || this.isEmpty(accountData.info_lastname) === true) || preferDisplay === true)) {
        //     userFullName = accountData.info_display;
        // }

        // if (this.isEmpty(accountData) === false && preferDisplay === false && this.isEmpty(accountData.info_firstname) === false && this.isEmpty(accountData.info_lastname) === false) {
        //     userFullName = `${this.firstUppercase(accountData.info_firstname)} ${this.isEmpty(accountData.info_middlename) === false ? accountData.info_middlename.toLowerCase() + " " + this.firstUppercase(accountData.info_lastname) : this.firstUppercase(accountData.info_lastname)}`;
        // } else if(this.isEmpty(accountData) === false && this.isEmpty(accountData.info_firstname) === true && this.isEmpty(accountData.info_lastname) === true) {
        //     userFullName = accountData.info_display;
        // }

        return userFullName;

    }

    copyText(text) {
        navigator.clipboard.writeText(text);
        toast.success(`Text copied`, {
            position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
            duration: 5000,
        });
    }

    reloadAll() {
        window.location.reload();
    }

    hasRank(data, rank) {
        if (this.isEmpty(data)) {
            data = config.accountData;
        }
        if (this.isEmpty(data) || this.isEmpty(data.account_rights) || parseInt(data.account_rights) < rank) {
            return false;
        }
        return true;
    }

    getFileLoc(userid, loc) {

        // set link as profile picture already
        if (this.isEmpty(loc) === false && (loc.startsWith('https://') || loc.startsWith('http://'))) {
            return loc;
        }

        return `https://server-t01.torogram.com/u/${userid}/${loc}`;
    }

    showImageViewer(parent, content, nftData, data) {

        config.contentToShow = content;
        config.contentToShow_nft = nftData;
        config.contentToShow_data = data;
        parent.forceUpdate();

        // parent.setState({ contentToShow: content }, () => {

        setTimeout(() => {

            const imageViewerContainer = document.getElementById('imageViewerContainer');
            imageViewerContainer.classList.add('show');
            document.body.classList.add('modal-open');
            
        }, 100);

        // });

    }

    showModal(parent, content, size = 'normal') {

        config.modalContent = content;
        config.modalSize = size;
        parent.forceUpdate();

        // parent.setState({ modalContent: content }, () => {

        setTimeout(() => {

            const modal = document.getElementById('w3s_modal');
            modal.classList.add('show');
            document.body.classList.add('modal-open');
            
        }, 100);

        // });

    }

    showSubModal(parent, content, size = 'normal') {

        config.modalContent2 = content;
        config.modalSize2 = size;
        parent.forceUpdate();

        // parent.setState({ modalContent: content }, () => {

        setTimeout(() => {

            const modal = document.getElementById('w3s_modal2');
            modal.classList.add('show');
            document.body.classList.add('modal-open');
            
        }, 100);

        // });

    }

    showSubModal2(parent, content, size = 'normal') {

        config.modalContent3 = content;
        config.modalSize3 = size;
        parent.forceUpdate();

        // parent.setState({ modalContent: content }, () => {

        setTimeout(() => {

            const modal = document.getElementById('w3s_modal3');
            modal.classList.add('show');
            document.body.classList.add('modal-open');
            
        }, 100);

        // });

    }

    showDropdown(parent, mobile, mobileContent, id) {

        if (mobile) {

            this.showMobileDropdown(parent, mobileContent);

        } else {

            const navDropdown = document.getElementById(id);
            navDropdown.classList.add('show');

        }

    }

    showMobileDropdown(parent, content) {

        config.mobileDropdownContent = content;
        parent.forceUpdate();

        // parent.setState({ modalContent: content }, () => {

        setTimeout(() => {

            const modalBG = document.getElementById('w3s_dropdown_mobile');
            const modal = document.getElementById('w3s_dropdown_mobile_container');

            modalBG.classList.add('show');
            modal.classList.add('show');
            document.body.classList.add('modal-open');
            
        }, 100);

        // });

    }

    showAlert(parent, options) {

        config.alertTitle = options.alertTitle;
        config.alertText = options.alertText;
        config.alertButtonText = options.alertButtonText;
        config.alertButtonAction = options.alertButtonAction;
        config.alertOptions = options.alertOptions;
        parent.forceUpdate();

        // parent.setState({ alertTitle: options.alertTitle, alertText: options.alertText, alertButtonText: options.alertButtonText, alertButtonAction: options.alertButtonAction }, () => {

        setTimeout(() => {

            const _alert = document.getElementById('w3s_alert');
            _alert.classList.add('show');
            document.body.classList.add('modal-open');
            
        }, 100);

        // });

    }

    closeAllModals(e, force = false) {

        if (this.isEmpty(e) === true || force === true || !e.target.id.includes('w3s_modal') || !e.target.id.includes('w3s_modal2') || !e.target.id.includes('w3s_modal3')) {

            // var dropdowns = document.getElementsByClassName("dropdown-content");
            // var i;
            // for (i = 0; i < dropdowns.length; i++) {
            //     var openDropdown = dropdowns[i];
            //     if (openDropdown.classList.contains('show')) {
            //         openDropdown.classList.remove('show');
            //     }
            // }

            const modals = document.querySelectorAll('[id^="w3s_modal"]');
            const modals2 = document.querySelectorAll('[id^="w3s_modal2"]');
            const modals3 = document.querySelectorAll('[id^="w3s_modal3"]');
            for (const modal of modals) {
                if (modal.classList.contains('show') === true) {
                    modal.classList.remove('show');
                }
            }
            for (const modal of modals2) {
                if (modal.classList.contains('show') === true) {
                    modal.classList.remove('show');
                }
            }
            for (const modal of modals3) {
                if (modal.classList.contains('show') === true) {
                    modal.classList.remove('show');
                }
            }
            document.body.classList.remove('modal-open');

        }

    }

    closeAllDropdowns(e, force = false) {

        if (this.isEmpty(e) === true || force === true || (!e.target?.id?.includes?.('dropdown_') && !e.target?.className?.includes?.('dropdown_') && !e.target?.id?.includes?.('mentionBox') && !e.target?.className?.includes?.('preventMention'))) {

            // var dropdowns = document.getElementsByClassName("dropdown-content");
            // var i;
            // for (i = 0; i < dropdowns.length; i++) {
            //     var openDropdown = dropdowns[i];
            //     if (openDropdown.classList.contains('show')) {
            //         openDropdown.classList.remove('show');
            //     }
            // }

            const dropdowns = document.querySelectorAll('[id^="dropdown_"]');
            for (const dropdown of dropdowns) {
                if (dropdown.classList.contains('show') === true) {
                    dropdown.classList.remove('show');
                }
            }
            
            const mentionBox = document.getElementById('mentionBox');
            if (this.isEmpty(mentionBox) === false) {
                mentionBox.style.display = 'none';
            }

        }

    }

    async sendNotification(to, postid, replyid, type, liketype = null) {

        APIPOSTNOTIFICATION.Request(to, postid, replyid, type, liketype);

    }

    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    distance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // metres
        //const φ1 = lat1.toRadians();
        //const φ2 = lat2.toRadians();
        //const Δφ = (lat2-lat1).toRadians();
        //const Δλ = (lon2-lon1).toRadians();
        const φ1 = lat1 * Math.PI / 180; 
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2-lat1) * Math.PI / 180;
        const Δλ = (lon2-lon1) * Math.PI / 180;

        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        const d = R * c;

        return d;
    }

    decodeEntities = (function() {
        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');
  
        function decodeHTMLEntities (str) {
          if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
          }
  
          return str;
        }
  
        return decodeHTMLEntities;
    })();

    nFormatter(num, digits, hideZero = true) {
        if (hideZero === true && (num === 0 || num === '')) {
            return '';
        }
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    dollarFormatter(num) {
        const dollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return dollar.format(num);
    }

    useQueryParams() {
        const params = new URLSearchParams(
          window ? window.location.search : {}
        );

        return new Proxy(params, {
            get(target, prop) {
                return target.get(prop)
            },
        });
    }

    isMobile() {
        return window.innerWidth <= 576;
    }

    isMobile2() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    fixString(str, fix = { lowercase: false, spaces: false, fixUsername: false, onlyLettersNumbers: false, onlyLetters: false, onlyDecimals: false, onlyInts: false, onlySpacesLettersNumbers: false, onlySpacesLetters: false, fixEmail: false, fixURL: false, fixPhone: false, }) { // to remove what <-

        if (typeof str !== 'string' && str instanceof String === false) {
            return '';
        }

        let newStr = str;

        // DEFAULT
        // MULTIPLE SPACES
        newStr = newStr.replace(/\s{2,}/g, " ");
        newStr = newStr.replace(/[^0-9A-Za-z_@./()+:\s-]/g, "");

        // SPACES
        if (fix.spaces) {
            newStr = newStr.replace(/\s+/g, "");
        }

        // // SPACES
        // if (fix.multipleSpaces) {
        //     newStr = newStr.replace(/\s{2,}/g, " ");
        // }

        // ACCEPT ONLY USERNAME CHARS
        if (fix.fixUsername) {
            newStr = newStr.replace(/[^0-9A-Za-z_.-]/g, "");
        }

        // ACCEPT ONLY EMAIL CHARS
        if (fix.fixEmail) {
            newStr = newStr.replace(/[^0-9A-Za-z_.@!-]/g, "");
        }

        // ACCEPT ONLY PHONE CHARS
        if (fix.fixPhone) {
            newStr = newStr.replace(/[^0-9\s+]/g, "");
        }

        // ACCEPT ONLY URL CHARS
        if (fix.fixURL) {
            newStr = newStr.replace(/[^0-9A-Za-z_.@:/-]/g, "");
        }

        // // NO WEIRD CHARS
        // if (fix.noWeirdChars) {
        //     newStr = newStr.replace(/[^0-9A-Za-z_-.@!()$#/,=[{}+|\s]/g, "");
        // }

        // ACCEPT ONLY LETTERS AND NUMBERS
        if (fix.onlyLettersNumbers) {
            newStr = newStr.replace(/[^0-9A-Za-z]/g, "");
        }

        // ACCEPT ONLY LETTERS
        if (fix.onlyLetters) {
            newStr = newStr.replace(/[^A-Za-z]/g, "");
        }

        // ACCEPT ONLY INTEGERS
        if (fix.onlyInts) {
            newStr = newStr.replace(/[^0-9-]/g, "");
        }

        // ACCEPT ONLY DECIMALS
        if (fix.onlyDecimals) {
            newStr = newStr.replace(/[^0-9,.-]/g, "");
        }

        // ACCEPT ONLY LETTERS, NUMBERS AND SPACES
        if (fix.onlySpacesLettersNumbers) {
            newStr = newStr.replace(/[^0-9A-Za-z\s]/g, "");
        }

        // ACCEPT ONLY LETTERS AND SPACES
        if (fix.onlySpacesLetters) {
            newStr = newStr.replace(/[^A-Za-z\s]/g, "");
        }

        // LOWERCASE
        if (fix.lowercase) {
            newStr = newStr.toLowerCase();
        }

        return newStr;

    }

    getInitials(name) {
        return name.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase();
    }

    arrayEquals(array, array2) {
        // if the other array is a falsy value, return
        if (!array2) {
            return false;
        }

        // compare lengths - can save a lot of time 
        if (array.length !== array2.length) {
            return false;
        }

        for (var i = 0, l=array.length; i < l; i++) {
            // Check if we have nested arrays
            if (array[i] instanceof Array && array2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!this.arrayEquals(array[i], array2[i])) {
                    return false;      
                } 
            }           
            else if (array[i] !== array2[i]) { 
                // Warning - two different object instances will never be equal: {x:20} !== {x:20}
                return false;   
            }           
        }       
        return true;
    }

    arrayContains(array, item) {

        let i;

        for (i = 0; i < array.length; i++) {

            if (array[i] === item) {

                return true;

            }

        }
    
        return false;
    }

    stringArrayContains(array, item, caseSensitive = true) {

        let i;

        if (this.isEmpty(array)) {
            return false;
        }

        for (i = 0; i < array.length; i++) {

            if (caseSensitive === false && array[i].toLowerCase() === item.toLowerCase()) {
                return true;
            } else if (array[i] === item) {
                return true;
            }

        }
    
        return false;
    }

    objectArrayContains(array, item) {

        if(typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            for (let key in array[i]) {

                if (array[i][key] === item) {
                    return true;
                }

            }

        }
    
        return false;
    }

    objectArrayContainsKey(array, key, item) {

        if(typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return false;
            }

            if (array[i][key] === item) {
                return true;
            }

        }
    
        return false;
    }

    objectArrayContainsKey2(array, key, item, key2, item2) {

        if(typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return false;
            }

            if (array[i][key] === item && array[i][key2] === item2) {
                return true;
            }

        }
    
        return false;
    }

    getArrayItem(array, key, item) {

        if(typeof array === 'undefined') {
            return null;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return null;
            }

            if (array[i][key] === item) {
                return array[i];
            }

        }
    
        return null;
    }

    getArrayItem2(array, key, item, key2, item2) {

        if(typeof array === 'undefined') {
            return null;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return null;
            }

            if (array[i][key] === item && array[i][key2] === item2) {
                return array[i];
            }

        }
    
        return null;
    }

    defaultAlert(parent, title, body) {

        parent.setState({
            alertTitle: title,
            alertBody: body,
            alertButtons: 1,
            alertButtonText: ['OK'],
            alertButtonAction: null,
            showAlert: true,
        });

    }

    removeFromArray(array, item) {

        let index = array.indexOf(item);

        if (index > -1) {

          array.splice(index, 1);

        }

    }

    removeFromArray2(array, key, item) {

        if(typeof array === 'undefined') {
            return;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return;
            }

            if (array[i][key] === item) {

                const index = array.indexOf(array[i]);

                if (index > -1) {
                    array.splice(index, 1);
                }

            }

        }

    }

    sortNumber(a, b) {

        return a - b;
  
    }

    formatDate(date) {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    stringToDate(dateString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        const [yyyy, mm, dd] = dateString.split("-");
        return new Date(yyyy, parseInt(mm) - 1, dd);
    };

    stringToDateTime(dateString, timeString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        if (typeof timeString !== 'string') {
            return new Date();
        }
        const [yyyy, mm, dd] = dateString.split("-");
        const [hh, MM, ss = 0] = timeString.split(":");
        return new Date(yyyy, parseInt(mm) - 1, dd, hh, MM, ss, 0);
    };

    stringToDateTimeUTC_LOCAL(dateString, timeString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        if (typeof timeString !== 'string') {
            return new Date();
        }
        const [yyyy, mm, dd] = dateString.split("-");
        const [hh, MM, ss = 0] = timeString.split(":");
        return new Date(`${yyyy}-${mm}-${dd}T${hh}:${MM}:${ss}.000Z`);
    }

    dateToString(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    dateToTimeString(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let hours = '' + date.getHours();
        let mins = '' + date.getMinutes();
        let secs = '' + date.getSeconds();

        if (hours.length < 2) {
          hours = '0' + hours;
        }
        if (mins.length < 2) {
          mins = '0' + mins;
        }
        if (secs.length < 2) {
          secs = '0' + secs;
        }

        return [hours, mins, secs].join(':');
      }

    dateToStringUTC(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let month = '' + (date.getUTCMonth() + 1);
        let day = '' + date.getUTCDate();
        let year = date.getUTCFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    getAge(now, birthday) { // birthday is a date
        const ageDifMs = now.getTime() - birthday.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getDaysInMonth(month, year) {

        return new Date(year, month, 0).getDate();

    }

    getDaysInMonth2(date) {

        const date2 = new Date(date);
        date2.setDate(0);

        return date2.getDate();

    }

    convertDateToUTC(date) { 
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
    }

    roundUp(num, X) {    
        return +(Math.round(num + "e+"+X)  + "e-"+X);
    }

    hourAmPm(dateInput) {

        let d = new Date(dateInput);
        const ampm = (d.getHours() >= 12) ? "PM" : "AM";
        const hours = (d.getHours() >= 12) ? d.getHours()-12 : d.getHours();

        return (hours < 10 ? '0' + hours : hours)+':'+(d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes())+' '+ampm;

    }

    // msToTime2(ms) {
    //     const milliseconds = ms % 1000
    //     const seconds = Math.floor((ms / 1000) % 60)
    //     const minutes = Math.floor((ms / (60 * 1000)) % 60)
    //     const hours = Math.floor((ms / (3600 * 1000)) % 3600)
    //     return `${hours < 10 ? '0' + hours.toString() : hours.toString()}:${minutes < 10 ? '0' + minutes.toString() : minutes.toString()}:${
    //       seconds < 10 ? '0' + seconds.toString() : seconds.toString()
    //     }.${milliseconds}`
    // }

    msToTime(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }
      
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
      
        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
    }

    msToTime2(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }

        let hours = parseInt(s / 1000 / 60 / 60);
        let minutes = this.roundUp(((s / 1000 / 60 / 60) - hours), 2);
        minutes = this.roundUp(((minutes / 100) * 60), 2);
        minutes = minutes === 0 ? parseInt(minutes * 100) : parseInt((minutes + Number.EPSILON) * 100);

        // if (hours < 0) {
        //     hours = 0;
        // }

        // if (minutes < 0) {
        //     minutes = 0;
        // }

        if (s > -60000 && s < 60000) {
            hours = 0;
            minutes = 0;
        }

        if (minutes === 60) {
            hours += 1;
            minutes = 0;
        }

        if (isNaN(hours)) {
            hours = 0;
        }
        if (isNaN(minutes)) {
            minutes = 0;
        }
      
        // var ms = s % 1000;
        // s = (s - ms) / 1000;
        // var secs = s % 60;
        // s = (s - secs) / 60;
        // var mins = s % 60;
        // var hrs = (s - mins) / 60;

        return (hours < 10 ? pad(hours) : hours) + ':' + pad(minutes);
    }

    msToTimeClock(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }
      
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
      
        return (hrs < 10 ? pad(hrs) : hrs) + ':' + pad(mins) + ':' + pad(secs);
    }

    daysToMs(days) {
        return days * 86400000;
    }

    timeStringToFloat(time, r = true) {
        if (time === null) {
            return NaN;
        }
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0]);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
        return r ? Math.round((hoursMins + Number.EPSILON) * 100) / 100 : hoursMins;
    }

    timeStringToMS(time) {
        if (time === null) {
            return NaN;
        }
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0]);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
        return hoursMins * 1000 * 60 * 60;
    }

    firstUppercase(string) {
        if (this.isEmpty(string)) {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    isTablet() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return isTablet;
    }

    round(num, fixed = false, digits = 2) {
        if (fixed === true) {
            return (Math.round((num + Number.EPSILON) * 100) / 100).toLocaleString(this.getLang(), { minimumFractionDigits: digits, maximumFractionDigits: digits });
        } else {
            return Math.round((num + Number.EPSILON) * 100) / 100;
        }
    }

    digitsToLocale(num, digits = 2) {
        return num.toLocaleString(this.getLang(), { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    roundBig(value, decimals) {
        return Number(Math.round(value + Number.EPSILON+'e'+decimals)+'e-'+decimals);
    }

    generateString(length = 6) {
        var result           = '';
        var characters       = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getLang() {
        try {
            return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
        } catch (error) {
            return 'en';
        }
    }

    getContrast(hexcolor) {

        if (hexcolor === null) {
            return 'black';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 140) ? 'black' : 'white';
    
    };

    getContrastPlan(hexcolor, dark) {

        if (hexcolor === null) {
            return 'black';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 50) ? this.getColor('_textColor', dark) : this.getColor('_textColor_reverse', dark);
    
    };

    getContrastHex(hexcolor) {

        if (hexcolor === null) {
            return '000000';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 140) ? '000000' : 'FFFFFF';
    
    };

    LightenDarkenColor(col, amt) {
  
        var usePound = false;
  
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }
 
        var num = parseInt(col,16);
 
        var r = (num >> 16) + amt;
 
        if (r > 255) r = 255;
        else if  (r < 0) r = 0;
 
        var b = ((num >> 8) && 0x00FF) + amt;
 
        if (b > 255) b = 255;
        else if  (b < 0) b = 0;
 
        var g = (num && 0x0000FF) + amt;
 
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
 
        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
    }

    LightenDarkenColor2(color, percent) {

        let usePound = false;
  
        if (color[0] === "#") {
            color = color.slice(1);
            usePound = true;
        }

        const num = parseInt(color, 16);
        const amt = Math.round(2.55 * percent);
        const R = (num >> 16) + amt;
        const B = (num >> 8 && 0x00FF) + amt;
        const G = (num && 0x0000FF) + amt;

        return (usePound?"#":"") + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);

    }

    hexToRgbA(hex, opacity){
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
        }
        throw new Error('Bad Hex');
    }

    convertToObject(array, key) {

        let obj = {};
    
        for (const i in array) {
    
          obj[array[i][key]] = array[i];
    
        }
    
        return obj;
    
      }

      convertToObjectPrefix(array, key, prefix) {

        let obj = {};
    
        for (const i in array) {
    
          obj[prefix + array[i][key]] = array[i];
    
        }
    
        return obj;
    
      }

      convertToObjectWithArrays(array, key) {

        let obj = {};
    
        for (const i in array) {

          if (typeof obj[array[i][key]] === 'undefined') {
              obj[array[i][key]] = [];
          }

          obj[array[i][key]].push(array[i]);
    
        }
    
        return obj;
    
      }

      convertToObjectWithArrays_fromObjectKeys(objectArray, keyName) {

        let obj = {};

        for (const key in objectArray) {
            if (this.isEmpty(objectArray[key][0]) === false && typeof obj[objectArray[key][0][keyName]] === 'undefined') {
                obj[objectArray[key][0][keyName]] = [];
            }
            if (this.isEmpty(objectArray[key][0]) === false) {
                obj[objectArray[key][0][keyName]].push(...objectArray[key]);
            }
        }
    
        return obj;
    
      }

      convertToObjectWithArraysPrefix(array, key, prefix) {

        let obj = {};
    
        for (const i in array) {

          if (typeof obj[prefix + array[i][key]] === 'undefined') {
              obj[prefix + array[i][key]] = [];
          }

          obj[prefix + array[i][key]].push(array[i]);
    
        }
    
        return obj;
    
      }

    charCount(string, word) {
        var length = typeof string === "string" && typeof word === "string" && word.length,
            loop = length,
            index = 0,
            count = 0;
    
        while (loop) {
            index = string.indexOf(word, index);
            if (index !== -1) {
                count += 1;
                index += length;
            } else {
                loop = false;
            }
        }
    
        return count;
    }

    stringDiffer(a, b)
    {
        var i = 0;
        var j = 0;
        var result = "";
        
        while (j < b.length)
        {
            if (a[i] !== b[j] || i === a.length)
                result += b[j];
            else
                i++;
            j++;
        }
        return result.toString();
    }

    isTrue(data) {
        return this.isEmpty(data) ? false : (data === true || data === '1' || data === 1);
    }

    isEmpty(data) {
        return typeof data === 'undefined' || data === undefined || data === null || data === '' || data === 'NULL' || data === 'null' || data === 'undefined' || data === '0000-00-00' || (typeof data === 'string' && data === 'error') || (Array.isArray(data) === true && data.length === 0); // || (typeof data === 'object' && Object.keys(data).length === 0)
    }
    getRoundedDate(minutes, d=this.getDateNowStatic()) {

        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
      
        return roundedDate;

    }

    endFirstWeek(firstDate, firstDay) {
        if (! firstDay) {
            return 7 - firstDate.getDay();
        }
        if (firstDate.getDay() < firstDay) {
            return firstDay - firstDate.getDay();
        } else {
            return 7 - firstDate.getDay() + firstDay;
        }
      }

    getWeeksStartAndEndInMonth(month, year) {
        let weeks = [],
            firstDate = new Date(year, month, 1),
            // lastDate = new Date(year, month + 1, 0),
            numDays = this.getDaysInMonth(month + 1, year);

        let start = 1;
        let end = this.endFirstWeek(firstDate, 2);
        while (start <= numDays) {
          if (start === 1 && end === 1) {
            start = end + 1;
            end = end + 7;
            end = start === 1 && end === 8 ? 1 : end;
            if (end > numDays) {
                end = numDays;
            }
            continue;
          }
          if (start - 1 !== end) {
            const week = this.getWeekNumber2(new Date(year, month, start));
            weeks.push({week: week, month: month, year: year, year1: this.getBeginOfWeek(new Date(year, month, start)).getFullYear(), start: start === 1 ? start : start - 1, end: end === this.getDaysInMonth(month + 1, year) ? end : end - 1});
          }
          start = end + 1;
          end = end + 7;
          end = start === 1 && end === 8 ? 1 : end;
          if (end > numDays) {
              end = numDays;
          }
        }
        return weeks;
      } 

    getBeginOfWeek(date = new Date(), startOfWeek = 1) {
        const dayDate = date.getDay() === 0 ? 7 : date.getDay();
        const dayStartOfWeek = startOfWeek === 0 ? 7 : startOfWeek;
        const result = new Date(date);
        while (result.getDay() !== startOfWeek) {
            if (dayDate > dayStartOfWeek) {
                result.setDate(result.getDate() - 1);
            } else {
                result.setDate(result.getDate() + 1);
            }
        }
        return result;
    }

    getWeekNumber() {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                              - 3 + (week1.getDay() + 6) % 7) / 7);
      }
    
      getWeekNumber2(today) {
        let date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                              - 3 + (week1.getDay() + 6) % 7) / 7);
      }

      getWeekAndYear(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return {year: d.getUTCFullYear(), week: weekNo};
    }
    
      getWeekYear() {
        let date = new Date();
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
      }
    
      getWeekYear2(today) {
        let date = today;
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
      }
    
      weeksInYear(year) {
        let month = 11, day = 31, week = 0;
      
        // Find week that 31 Dec is in. If is first week, reduce date until
        // get previous week.
        do {
          const d = new Date(year, month, day--);
          week = this.alt(d)[1];
        } while (week === 1);
      
        return week;
      }
    
      alt(d) {
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0,0,0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay()||7));
        // Get first day of year
        var yearStart = new Date(d.getFullYear(),0,1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
        // Return array of year and week number
        return [d.getFullYear(), weekNo];
      }
    
    //   getMonday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').startOf('isoWeek').utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getTuesday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 1);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(2).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getWednesday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 2);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(3).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getThursday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 3);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(4).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getFriday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 4);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(5).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getSaturday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 5);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(6).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getSunday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 6);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').endOf('isoWeek').utc(Data.data.utcOffset).toDate();
    //   }

    getMonday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        return ISOweekStart;
      }

      getTuesday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 1);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 1);
        return ISOweekStart;
      }

      getWednesday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 2);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 2);
        return ISOweekStart;
      }

      getThursday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 3);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 3);
        return ISOweekStart;
      }

      getFriday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 4);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 4);
        return ISOweekStart;
      }

      getSaturday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 5);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 5);
        return ISOweekStart;
      }

      getSunday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 6);
        return ISOweekStart;
      }

      getDateWeekday(day, w, y) {

        let weekDate = new Date();

        if (day === 1) {
            weekDate = this.getMonday(w, y);
        } else if (day === 2) {
            weekDate = this.getTuesday(w, y);
        } else if (day === 3) {
            weekDate = this.getWednesday(w, y);
        } else if (day === 4) {
            weekDate = this.getThursday(w, y);
        } else if (day === 5) {
            weekDate = this.getFriday(w, y);
        } else if (day === 6) {
            weekDate = this.getSaturday(w, y);
        } else if (day === 0) {
            weekDate = this.getSunday(w, y);
        }

        return weekDate;

    }

      dateInWeek(date, w, y) {
        const dateObj = this.stringToDate(date);

        const monday = this.getMonday(w, y);
        const tuesday = this.getTuesday(w, y);
        const wednesday = this.getWednesday(w, y);
        const thursday = this.getThursday(w, y);
        const friday = this.getFriday(w, y);
        const saturday = this.getSaturday(w, y);
        const sunday = this.getSunday(w, y);

        if ((dateObj.getDate() === monday.getDate() && dateObj.getMonth() === monday.getMonth() && dateObj.getFullYear() === monday.getFullYear())
          || (dateObj.getDate() === tuesday.getDate() && dateObj.getMonth() === tuesday.getMonth() && dateObj.getFullYear() === tuesday.getFullYear())
          || (dateObj.getDate() === wednesday.getDate() && dateObj.getMonth() === wednesday.getMonth() && dateObj.getFullYear() === wednesday.getFullYear())
          || (dateObj.getDate() === thursday.getDate() && dateObj.getMonth() === thursday.getMonth() && dateObj.getFullYear() === thursday.getFullYear())
          || (dateObj.getDate() === friday.getDate() && dateObj.getMonth() === friday.getMonth() && dateObj.getFullYear() === friday.getFullYear())
          || (dateObj.getDate() === saturday.getDate() && dateObj.getMonth() === saturday.getMonth() && dateObj.getFullYear() === saturday.getFullYear())
          || (dateObj.getDate() === sunday.getDate() && dateObj.getMonth() === sunday.getMonth() && dateObj.getFullYear() === sunday.getFullYear())) {
              return true;
      }

      return false;
    }

    toISOString(date) {

        if (date === null || date === undefined || typeof date === 'undefined') {
          return null;
        }

        return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;

    }

    getDaysInYear(year) {
    
        return this.isLeapYear(year) ? 366 : 365;

    }

    isLeapYear(year) {
        return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    }

    isValidDateString(dateString) {

        return typeof dateString !== 'undefined' && dateString !== null && dateString !== 'null' && dateString !== 'NULL' && dateString !== '' && dateString !== '0000-00-00';

    }

    chunkArray(arr, size, out) {
        out = out || [];
        if (!arr.length) return out;
        out.push(arr.slice(0, size));
        return this.chunkArray(arr.slice(size), size, out);
    }

    checkDuplicatesArray(arr, key, remove){

        let hasDupes = false;
    
        for(let i=0;i<arr.length-1;i++){
        
            for(let j=i+1;j<arr.length;j++){
            
                if(arr[i][key]===arr[j][key]){

                    if (remove === true) {
                        this.removeFromArray(arr, arr[i]);
                    }   

                    hasDupes = true;
                
                }
            
            }
        
        }
    
        return hasDupes;
    
    }

    daysUntill(date, today = new Date()) {

        //Set current year or the next year if you already had birthday this year
        date.setFullYear(today.getFullYear());
        if (today > date) {
          date.setFullYear(today.getFullYear() + 1);
        }

        //Calculate difference between days
        return Math.floor((date - today) / (1000*60*60*24));

    }

    sortArray(a, b, key, DESC) {
        return (a[key] > b[key]) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray2(a, b, key, key2, DESC) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && (a[key2] > b[key2])) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray3(a, b, key, key2, key3, DESC) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray3nameASC(a, b, key, key2, key3) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1;
    }

    sortArray3nameDESC(a, b, key, key2, key3) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] < b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? -1 : 1;
    }

    calcPercentInt(first, second) {

        return this.round((first / second) * 100) === Infinity ? 0 : this.round((first / second) * 100);

    }

    addSlashes(string) {
        return string.replace(/\\/g, '\\\\').replace(/\u0008/g, '\\b').replace(/\t/g, '\\t').replace(/\n/g, '\\n').replace(/\f/g, '\\f').replace(/\r/g, '\\r').replace(/'/g, '\\\'').replace(/"/g, '\\"');
    }

    async urltoFile(url, filename, mimeType) {
        return (await fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }
    
    async blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }

    weeksBetween(d1, d2) {
        return Math.floor(Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000)));
    }

    /*
        blend two colors to create the color that is at the percentage away from the first color
        this is a 5 step process
            1: validate input
            2: convert input to 6 char hex
            3: convert hex to rgb
            4: take the percentage to create a ratio between the two colors
            5: convert blend to hex
        @param: color1      => the first color, hex (ie: #000000)
        @param: color2      => the second color, hex (ie: #ffffff)
        @param: percentage  => the distance from the first color, as a decimal between 0 and 1 (ie: 0.5)
        @returns: string    => the third color, hex, represenatation of the blend between color1 and color2 at the given percentage
    */
        blend_colors(color1, color2, percentage)
        {
            // check input
            color1 = color1 || '#000000';
            color2 = color2 || '#ffffff';
            percentage = percentage || 0.5;
    
            // 1: validate input, make sure we have provided a valid hex
            if (color1.length !== 4 && color1.length !== 7)
                throw new Error('colors must be provided as hexes');
    
            if (color2.length !== 4 && color2.length !== 7)
                throw new Error('colors must be provided as hexes');    
    
            if (percentage > 1 || percentage < 0)
                throw new Error('percentage must be between 0 and 1');
    
            // 2: check to see if we need to convert 3 char hex to 6 char hex, else slice off hash
            //      the three character hex is just a representation of the 6 hex where each character is repeated
            //      ie: #060 => #006600 (green)
            if (color1.length === 4)
                color1 = color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
            else
                color1 = color1.substring(1);
            if (color2.length === 4)
                color2 = color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
            else
                color2 = color2.substring(1);   
        
            // 3: we have valid input, convert colors to rgb
            color1 = [parseInt(color1[0] + color1[1], 16), parseInt(color1[2] + color1[3], 16), parseInt(color1[4] + color1[5], 16)];
            color2 = [parseInt(color2[0] + color2[1], 16), parseInt(color2[2] + color2[3], 16), parseInt(color2[4] + color2[5], 16)];
        
            // 4: blend
            var color3 = [ 
                (1 - percentage) * color1[0] + percentage * color2[0], 
                (1 - percentage) * color1[1] + percentage * color2[1], 
                (1 - percentage) * color1[2] + percentage * color2[2]
            ];
        
            // 5: convert to hex
            color3 = '#' + this.int_to_hex(color3[0]) + this.int_to_hex(color3[1]) + this.int_to_hex(color3[2]);
        
            // return hex
            return color3;
        }
    
        /*
            convert a Number to a two character hex string
            must round, or we will end up with more digits than expected (2)
            note: can also result in single digit, which will need to be padded with a 0 to the left
            @param: num         => the number to conver to hex
            @returns: string    => the hex representation of the provided number
        */
        int_to_hex(num)
        {
            var hex = Math.round(num).toString(16);
            if (hex.length === 1)
                hex = '0' + hex;
            return hex;
        }

    swapInArray(arr, from, to) {
        arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
    }

}

// singleton
export default (new Constants());
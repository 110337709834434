import React from 'react';

import withRouter from '../../data/withRouter';
import constants from '../../data/constants';

class Loader extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.loading = this.props.loading;

        this.state = {

        };

    }

    async componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.loading) && constants.isEmpty(this.props.loading) === false) {
            this.loading = this.props.loading;
            this.forceUpdate();
        } else if (constants.isEmpty(this.loading) === false && constants.isEmpty(this.props.loading) === false && this.loading !== this.props.loading) {
            this.loading = this.props.loading;
            this.forceUpdate();
        }

    }

    render() {

        if (this.loading === true) {

            return (
                <div className='w3s_loader_full bg-gray-50 dark:bg-slate-950'>
                    <div className="w3s_spinner">
                        <div className="w3s_double-bounce1"></div>
                        <div className="w3s_double-bounce2"></div>
                    </div>
                </div>
            );

        } else {

            return (null);

        }

    }
  
}

export default withRouter(Loader);
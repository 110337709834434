import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import { Cropper, FixedCropper, ImageRestriction, Priority } from 'react-advanced-cropper';
import APIUPLOADFILE from '../../api/APIUPLOADFILE';
import APIDELETEFILE from '../../api/APIDELETEFILE';
import APIPUTCOVERPHOTO from '../../api/Profile/APIPUTCOVERPHOTO';
import toast from 'react-hot-toast';
import config from '../../data/config';
import { Grid } from '@giphy/react-components';

import giphy_dark from '../../assets/images/torogram/giphy/giphy_dark.png';
import giphy_light from '../../assets/images/torogram/giphy/giphy_light.png';

class ModalContent_GIF extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.parent = this.props.parent;
        this.options = this.props.options;

        this.state = {

            loading: constants.isEmpty(this.props.loading) === false ? this.props.loading : true,

            gif: null,
            gifGridWidth: 0,

            category: config.giphy_trending,
            searchKey: '',

        };

    }

    async componentDidMount() {

        this.setState({ loading: false }, () => {

            if (this.contentRef) {
                this.setState({ gifGridWidth: this.contentRef.offsetWidth });
            }

        });

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.options) && constants.isEmpty(this.props.options) === false) {
            this.options = this.props.options;
            this.forceUpdate();
        } else if (constants.isEmpty(this.options) === false && constants.isEmpty(this.props.options) === false && this.options !== this.props.options) {
            this.options = this.props.options;
            this.forceUpdate();
        }

        if (this.contentRef && this.state.gifGridWidth !== this.contentRef.offsetWidth) {
            this.setState({ gifGridWidth: this.contentRef.offsetWidth });
        }

        if (this.state.loading !== this.props.loading && prevState.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

    }

    changeCategory(cat) {

        if (cat === this.state.category) {
            return;
        }

        if (this.searchGIF) {
            this.searchGIF.value = '';
        }

        this.setState({ category: cat, searchKey: '' });

    }

    onChangeSearchKey(e) {

        const search = e.target.value;

        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.setState({ searchKey: search });
        }, 1000);

    }

    setGif(GIF, e) {

        e.preventDefault();

        this.setState({ gif: GIF });
        
    }

    select() {
        this.options.selectGIF(this.state.gif);
        this.close();
    }

    back() {

        this.setState({ gif: null }, () => {

            if (this.searchGIF) {
                this.searchGIF.value = this.state.searchKey;
            }

        });

    }

    close() {

        if (this.searchGIF) {
            this.searchGIF.value = '';
        }

        const modal = document.getElementById('w3s_modal2');
        modal.classList.remove('show');
        // document.body.classList.remove('modal-open');

        config.modalContent2 = null;
        config.modalSize2 = 'normal';

        this.setState({ gif: null, category: config.giphy_trending, searchKey: '' });

        // this.forceUpdate();

    }

    render() {

        return (
            <div style={{ width:'100%', height:'100%' }}>

                {this.state.loading === true ?

                    // LOADER
                    <div className='w3s_loader_modal bg-transparent'>
                        <div className="w3s_spinner">
                            <div className="w3s_double-bounce1"></div>
                            <div className="w3s_double-bounce2"></div>
                        </div>
                    </div>

                :

                    <div style={{ width:'100%', height:'100%' }}>

                        <div className='modal_header'>
                            {constants.isEmpty(this.state.gif) === false ?
                                <div className='modal_back' onClick={() => this.back()}><i className='fas fa-arrow-left' /></div>
                            : null}
                            <h2 className='text-xl'>Choose GIF</h2>
                            <div className='modal_close' onClick={() => this.close()}><i className='fas fa-xmark' /></div>
                        </div>

                        <div className='w3s_divider' />

                        {constants.isEmpty(this.state.gif) === true ?

                            <div className='modal_content'>
                                <div ref={(ref) => this.contentRef = ref} className='modal_content_gif'>

                                    <div className='w3s_sticky_top_GIF'>
                                        <div className='gif_category_container bg-gray-100 dark:bg-slate-700'>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_trending ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_trending)}>
                                                <i className='far fa-fire-flame-curved' />
                                            </div>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_happy ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_happy)}>
                                                <i className='far fa-face-laugh-beam' />
                                            </div>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_love ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_love)}>
                                                <i className='far fa-heart' />
                                            </div>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_party ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_party)}>
                                                <i className='far fa-party-horn' />
                                            </div>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_nice ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_nice)}>
                                                <i className='far fa-thumbs-up' />
                                            </div>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_sad ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_sad)}>
                                                <i className='far fa-face-frown-slight' />
                                            </div>
                                            <div className={`gif_category_button ${this.state.category === config.giphy_angry ? 'active' : ''}`} onClick={() => this.changeCategory(config.giphy_angry)}>
                                                <i className='far fa-face-angry' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='gif_category_container bg-gray-100 dark:bg-slate-700 mt-5' style={{ width: 'calc(100%)' }}>
                                        <span className='font-medium text-sm me-2'>Search</span>
                                        <div className='gif_category_container bg-white dark:bg-slate-800' style={{ width: '100%', padding: '2px 12px' }}>
                                            <input ref={(ref) => this.searchGIF = ref} id='searchGIF' className='giphy_search_input' type='text' onChange={(e) => this.onChangeSearchKey(e)} />
                                        </div>
                                    </div>

                                    {/* {constants.isEmpty(this.state.searchKey) === false ?
                                        <Giphy_Search search={this.state.searchKey} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_trending ?
                                        <Giphy_Trending width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_happy ?
                                        <Giphy_Search search={'happy'} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_love ?
                                        <Giphy_Search search={'love'} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_party ?
                                        <Giphy_Search search={'party'} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_nice ?
                                        <Giphy_Search search={'nice'} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_sad ?
                                        <Giphy_Search search={'sad'} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : this.state.category === config.giphy_angry ?
                                        <Giphy_Search search={'angry'} width={this.state.gifGridWidth - 32} setGif={this.setGif.bind(this)} />
                                    : null} */}

                                </div>

                                {document.querySelector('html').classList.contains('dark') ?
                                    <div className='mt-5' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                                        <a href='https://giphy.com' target='_blank'><img src={giphy_light} className='giphy_attr' /></a>
                                    </div>
                                :
                                    <div className='mt-5' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                                        <a href='https://giphy.com' target='_blank'><img src={giphy_dark} className='giphy_attr' /></a>
                                    </div>
                                }

                            </div>

                        : 

                            <div className='modal_content'>
                                <div ref={(ref) => this.contentRef = ref} className='modal_content_gif'>
                                    <img id='gifSelected' src={`https://i.giphy.com/media/${this.state.gif.id}/giphy.gif`} />
                                </div>

                                {document.querySelector('html').classList.contains('dark') ?
                                    <div className='mt-5' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                                        <a href={this.state.gif.url} target='_blank'><img src={giphy_light} className='giphy_attr' /></a>
                                    </div>
                                :
                                    <div className='mt-5' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                                        <a href={this.state.gif.url} target='_blank'><img src={giphy_dark} className='giphy_attr' /></a>
                                    </div>
                                }

                                <div className={`w3s_info_button_full mt-5`} onClick={() => this.select()}><i className='fas fa-gif me-2' />Select GIF</div>
                            </div>

                        }

                    </div>

                }

            </div>
        );
    }
  
}

export default withRouter(ModalContent_GIF);




class APIGetWalletsUser {

    async Request(jwt) {
        
        let response = null;

        // let data = new FormData();
        // data.append("username", username);
        // data.append("password", password);

        // let data = { "username": username, "password": password };

        try {
            response = await fetch(`https://backend.x-hunt.com/api/wallet/user`, {
                method: 'GET',
                // mode: 'no-cors',
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`,
                },
                // body: JSON.stringify(data),
            });
        } catch(err) {
            console.log(err)
            return [];
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return [];

        }

    }
  
}
  
// singleton
export default (new APIGetWalletsUser());
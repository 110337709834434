import React from 'react';

import constants from '../../data/constants';

import withRouter from '../../data/withRouter';
import config from '../../data/config';

class ModalContent_Settings_Theme extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        this.parent = this.props.parent;
        this.options = this.props.options; // reloadProfile(stateReload), 

        this.state = {

            loading: constants.isEmpty(this.props.loading) === false ? this.props.loading : true,

        };

    }

    async componentDidMount() {

        this.setState({ loading: false }, () => {

            // get data
            const htmlTag = document.getElementsByTagName("html")[0];
            const theme = localStorage.getItem('theme');

            if (theme === 'dark') {

                // set theme
                htmlTag.className = 'dark';

                try {
                    let chk = document.getElementById('chk');
                    chk.checked = false; // checkbox parameters
                } catch (error) { }

            } else {

                // set theme
                htmlTag.className = 'light';

                try {
                    let chk = document.getElementById('chk');
                    chk.checked = true; // checkbox parameters
                } catch (error) { }

            }

        });

    }

    componentDidUpdate(prevProps, prevState) {

        if (constants.isEmpty(this.options) && constants.isEmpty(this.props.options) === false) {

            this.options = this.props.options;
            this.forceUpdate();

        } else if (constants.isEmpty(this.options) === false && constants.isEmpty(this.props.options) === false && this.options !== this.props.options) {
            
            this.options = this.props.options;
            this.forceUpdate();

        }

        // if (this.state.loading !== this.props.loading && prevState.loading !== this.props.loading) {
        //     this.setState({ loading: this.props.loading });
        // }

    }

    changeMode(mode, event) {
        switch (mode) {
            case 'mode':
                if (document.documentElement.className.includes("dark")) {
                    document.documentElement.className = 'light'
                    localStorage.setItem('theme', 'light');
                } else {
                    document.documentElement.className = 'dark'
                    localStorage.setItem('theme', 'dark');
                }
                break;
            case 'layout':
                if (event.target?.innerText === "LTR") {
                    document.documentElement.dir = "ltr"
                }
                else {
                    document.documentElement.dir = "rtl"
                }
                break;
            default:
                break;
        }
    }

    close() {

        const modal = document.getElementById('w3s_modal2');
        modal.classList.remove('show');
        // document.body.classList.remove('modal-open');

        config.modalContent2 = null;
        config.modalSize2 = 'normal';

        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);

    }

    render() {

        return (
            <div style={{ width:'100%', height:'100%' }}>

                {this.state.loading === true ?

                    // LOADER
                    <div className='w3s_loader_modal bg-transparent'>
                        <div className="w3s_spinner">
                            <div className="w3s_double-bounce1"></div>
                            <div className="w3s_double-bounce2"></div>
                        </div>
                    </div>

                :

                    <div style={{ width:'100%', height:'100%' }}>

                        {/* PAGES */}
                        {/* <ModalPage_Profile parent={this.parent} options={this.options} /> */}

                        <div className='modal_header'>
                            <div className='modal_back' onClick={() => this.close()}><i className='fas fa-arrow-left' /></div>
                            <h2 className='text-xl'>Theme</h2>
                        </div>

                        <div className='w3s_divider' />

                        <div className='modal_content'>

                            {/* SCROLLABLE AREA */}
                            <div className='modal_content_post notPost'>

                                {/* <label className="switch">
                                    <input type="checkbox" />
                                    <div className="slider">
                                        <div className="circle">
                                            <svg className="cross" xmlSpace="preserve" viewBox="0 0 365.696 365.696" y="0" x="0" height="6" width="6" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path data-original="#000000" fill="currentColor" d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"></path>
                                                </g>
                                            </svg>
                                            <svg className="checkmark" xmlSpace="preserve" viewBox="0 0 24 24" y="0" x="0" height="10" width="10" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path class="" data-original="#000000" fill="currentColor" d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </label> */}

                                <div className='flex items-center justify-between w3s_break_word'>
                                    <span className='font-semibold'>Theme mode</span>
                                    <label className="switchTheme">
                                        <input type="checkbox" id="chk" onClick={(event) => this.changeMode('mode', event)} />
                                        <span className="sliderTheme"></span>
                                    </label>
                                </div>

                            </div>

                            {/* BUTTON */}
                            {/* <div className={`w3s_info_button_full mt-5`} onClick={() => this.saveProfile()}><i className='fas fa-check me-2' />Save</div> */}
                         
                        </div>

                    </div>

                }

            </div>
        );
    }
  
}

export default withRouter(ModalContent_Settings_Theme);